{
    "_documentation": "This JSON file is a database of the ethical and unethical practices of different companies. Each item in the \"companies\" object represents a company ethics record. Each of these items has a \"names\" areay containing names that can be used for the company, of which the first entry is the most commonly used name. They also have a \"comment\" string which is a comment on the ethics of the company, with sources denoted by numbers in square brackets eg. [1][2]. The URLs for these sources are in the \"sources\" object which is a mapping from the source numbers to URLs. Each company also has tags in the \"tags\" array, which are strings that describe the company. Finally each company has a \"logoUrl\" and \"siteUrl\" which are URLs for the company's logo image and website. There is an \"updatedAt\" timestamp on each item to track when it was last updated. https://boikot.xyz © boikot 2023",
    "companies": {
        "barclays": {
            "names": [
                "Barclays",
                "Barclays Bank",
                "Barclays Bank plc",
                "Barclays plc",
                "Barclays Group",
                "Barclays UK",
                "Barclays International",
                "Barclay's",
                "Barclay Bank",
                "BCLYS",
                "BARC",
                "BCS"
            ],
            "comment": "Barclays is a UK-based bank which is Europe's largest funder of fracking and coal [1], having invested $85 billion into fossil fuels and $24 billion into expansion. Among other controversies, Barclays has been fined £500 million for tax avoidance by the Treasury [2], illegally manipulated the price of gold [3], attempted to manipulate the US energy market [4], and conducted workplace surveillance on its staff [5].",
            "sources": {
                "1": "https://web.archive.org/web/20231210171959/https://www.banktrack.org/article/banking_on_climate_change_fossil_fuel_finance_report_card_2019",
                "2": "https://web.archive.org/web/20231122051820/https://www.bbc.co.uk/news/business-17181213",
                "3": "https://web.archive.org/web/20140525200522/http://www.thelondonnews.net/index.php/sid/222273039/scat/3a8a80d6f705f8cc/ht/FCA-fines-Barclays-26mn-pounds-over-gold-price-manipulation",
                "4": "https://web.archive.org/web/20231031090011/https://www.theguardian.com/business/2013/jul/17/us-energy-regulator-barclays-fine",
                "5": "https://web.archive.org/web/20231202225949/https://www.theguardian.com/business/2020/feb/20/barlays-using-dytopian-big-brother-tactics-to-spy-on-staff-says-tuc"
            },
            "tags": [
                "bank",
                "unsustainable",
                "anticompetetive",
                "tax avoidance",
                "bad working conditions"
            ],
            "score": 20,
            "ownedBy": [],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/en/7/7e/Barclays_logo.svg",
            "siteUrl": "https://www.barclays.co.uk/",
            "updatedAt": "2023-12-15T22:09:30.315Z"
        },
        "hsbc": {
            "names": [
                "HSBC",
                "HSBC Bank",
                "HSBC Holdings",
                "Hongkong and Shanghai Banking Corporation",
                "the Hongkong and Shanghai Banking Corporation",
                "HSBC Group",
                "Hong Kong Bank",
                "HongKongBank",
                "Hong Kong Shanghai Bank",
                "HSBC Holdings plc",
                "HSBA"
            ],
            "comment": "HSBC is a British bank founded in colonial Hong Kong, which has been fined over $2 billion by various governments for providing services to money laundering groups [1][2][3], has assisted Iran and North Korea in circumventing US nuclear-weapons sanctions [4], and has been fined over $600 million for tax avoidance [5]. HSBC has made sustainability pledges such as to end financing of coal power [6][7], but has also invested billions in oil and gas and been accused of greenwashing [8].",
            "sources": {
                "1": "https://web.archive.org/web/20231127195037/https://www.reuters.com/business/hsbc-fined-85-mln-uk-anti-money-laundering-failings-2021-12-17/",
                "2": "https://web.archive.org/web/20231024090948/https://www.theguardian.com/business/2015/jun/04/hsbc-fined-278m-over-money-laundering-claims",
                "3": "https://www.independent.co.uk/news/business/news/terrorists-and-drug-lords-targeted-hsbc-bosses-admit-8483303.html",
                "4": "https://web.archive.org/web/20220331180747/https://www.bbc.co.uk/news/business-20673466",
                "5": "https://web.archive.org/web/20221221080911/https://www.reuters.com/article/us-hsbc-france-swiss-idUKKBN1DE1Z1/",
                "6": "https://web.archive.org/web/20231108184928/https://www.reuters.com/article/hsbc-hldg-climatechange/exclusive-hsbc-targets-net-zero-emissions-by-2050-earmarks-1-trln-green-financing-idINL8N2GZ31Q/",
                "7": "https://web.archive.org/web/20231117144315/https://shareaction.org/news/hsbc-sends-shockwaves-as-it-commits-to-end-coal-financing",
                "8": "https://web.archive.org/web/20231118013509/https://www.thebureauinvestigates.com/stories/2022-10-31/mines-pipelines-and-oil-rigs-what-hsbcs-sustainable-finance-really-pays-for"
            },
            "tags": [
                "bank",
                "corruption",
                "greenwashing",
                "nuclear"
            ],
            "score": 35,
            "ownedBy": [],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/a/aa/HSBC_logo_%282018%29.svg",
            "siteUrl": "https://www.hsbc.co.uk/",
            "updatedAt": "2023-12-15T22:09:30.315Z"
        },
        "samsung": {
            "names": [
                "Samsung",
                "Samsung Group"
            ],
            "comment": "Samsung is a South Korean conglomerate which is the world's largest mobile phone and microchip manufacturer [1][2]. Samsung accounts for over 20% of South Korean exports [3] and has an outsized influence in the nation [4], and has been involved in bribery [5], anti-union activities [6], and price fixing [7].",
            "sources": {
                "1": "https://web.archive.org/web/20120428062632/http://www.isuppli.com/Mobile-and-Wireless-Communications/News/Pages/Samsung-Overtakes-Nokia-for-Cellphone-Lead.aspx",
                "2": "https://web.archive.org/web/20180525234650/https://techcrunch.com/2018/01/30/samsung-intel-worlds-largest-chipmaker/",
                "3": "https://web.archive.org/web/20110503224019/http://business.timesonline.co.uk/tol/business/industry_sectors/technology/article3764352.ece",
                "4": "https://web.archive.org/web/20130921061502/http://articles.washingtonpost.com/2012-12-09/world/35721716_1_samsung-chairman-smartphone-market-samsung-credit-card",
                "5": "https://web.archive.org/web/20170701085942/http://www.nytimes.com/2007/11/06/business/worldbusiness/06iht-samsung.1.8210181.html?pagewanted=all&_r=0",
                "6": "https://web.archive.org/web/20190627140032/http://english.hani.co.kr/arti/english_edition/e_international/899427.html",
                "7": "https://web.archive.org/web/20100707041505/http://europa.eu/rapid/pressReleasesAction.do?reference=IP/10/586"
            },
            "tags": [
                "electronics",
                "phones",
                "computers",
                "corruption",
                "anticompetetive",
                "antiunion"
            ],
            "score": 45,
            "ownedBy": [],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/b/b4/Samsung_wordmark.svg",
            "siteUrl": "https://www.samsung.com",
            "updatedAt": "2023-12-15T22:09:30.315Z"
        },
        "apple": {
            "names": [
                "Apple",
                "Apple Inc.",
                "Apple Computer Company",
                "Apple Computer Inc.",
                "AAPL"
            ],
            "comment": "Apple is an American technology company credited with innovation in personal computing [1] which has a generally pro-consumer stance on privacy [2][3][4] and a proactive climate policy [5][6][7]. Apple has engaged in anti-competetive practices [8], its factories have seen abusive working conditions [9][10], and its climate claims have been called misleading [11].",
            "sources": {
                "1": "https://www.forbes.com/sites/susanadams/2013/09/27/is-apple-the-worlds-most-innovative-company-still",
                "2": "https://www.wired.com/2015/06/apples-latest-selling-point-little-knows",
                "3": "https://www.gizbot.com/mobile/news/apple-app-tracking-transparency-becomes-facebook-nightmare-only-4-percent-allow-tracking-074217.html",
                "4": "https://money.cnn.com/2016/02/22/technology/apple-privacy-icloud/index.html",
                "5": "https://9to5mac.com/2016/06/09/apple-energy-company",
                "6": "https://web.archive.org/web/20131202223855/http://www.businessweek.com/news/2013-03-21/apple-says-data-centers-now-use-100-percent-renewable-energy",
                "7": "https://www.apple.com/newsroom/2016/06/global-apps-for-earth-campaign-with-wwf-raises-more-than--8m.html",
                "8": "https://ghostarchive.org/archive/20221210/https://www.ft.com/content/e9bb4da1-867a-40ba-abc0-4a63d3421e31",
                "9": "https://www.reuters.com/article/idUSTRE6A41M920101105",
                "10": "https://www.huffpost.com/entry/foxconn-suffers-10th-deat_n_588524",
                "11": "https://www.seattletimes.com/business/report-corporate-climate-pledges-are-weaker-than-they-seem/"
            },
            "tags": [
                "tech",
                "electronics",
                "phones",
                "computers",
                "proprivacy",
                "anticompetetive",
                "greenwashing",
                "bad working conditions"
            ],
            "score": 55,
            "ownedBy": [],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/f/fa/Apple_logo_black.svg",
            "siteUrl": "https://www.apple.com/",
            "updatedAt": "2023-12-15T22:09:30.315Z"
        },
        "tesla": {
            "names": [
                "Tesla",
                "Tesla Inc.",
                "Tesla Automation",
                "Tesla Energy",
                "TSLA"
            ],
            "comment": "Tesla is an American automotive and sustainable energy company which is the world's biggest manufacturer of electric vehicles [1] and is a major installer of solar power systems [2] and battery storage [3]. Tesla has engaged in anti-union practices [4], received claims of workplace sexual harassment [5], committed environmental waste violations [6], and been accused of anticompetitive repair policies in several lawsuits [7].",
            "sources": {
                "1": "https://web.archive.org/web/20210904115030/https://cleantechnica.com/2021/08/26/tesla-model-3-has-passed-1-million-sales/",
                "2": "https://web.archive.org/web/20230420155738/https://www.pv-tech.org/tesla-2022-solar-installs-inched-up-to-348mw/",
                "3": "https://web.archive.org/web/20230820094909/https://finance.yahoo.com/news/tesla-battery-backup-systems-manage-142235080.html",
                "4": "https://www.nytimes.com/2023/03/31/business/tesla-union-musk-twitter.html",
                "5": "https://www.forbes.com/sites/alanohnsman/2021/12/14/tesla-hit-by-6-more-sexual-harassment-claims/",
                "6": "https://www.thedrive.com/tech/28413/tesla-in-settlement-proceedings-over-19-air-quality-violations-as-investigation-continues",
                "7": "https://www.theregister.com/2023/03/16/dual_tesla_lawsuits_brings_musk/"
            },
            "tags": [
                "electric vehicles",
                "cars",
                "tech",
                "ai",
                "solarpower",
                "batteries",
                "cleanenergy",
                "antiunion",
                "anticompetetive",
                "sexual harassment",
                "bad working conditions",
                "unsustainable"
            ],
            "score": 65,
            "ownedBy": [],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/b/bd/Tesla_Motors.svg",
            "siteUrl": "https://tesla.com/",
            "updatedAt": "2023-12-15T22:09:30.315Z"
        },
        "github": {
            "names": [
                "Github",
                "Github Inc.",
                "Logical Volcano"
            ],
            "comment": "Github is an American software and version control platform owned by Microsoft which offers free services to software developers [1] and students [2][3]. A past Github CEO resigned over claims of workplace harassment [4].",
            "sources": {
                "1": "https://web.archive.org/web/20200414193839/https://github.blog/2020-04-14-github-is-now-free-for-teams/",
                "2": "https://web.archive.org/web/20210322042209/https://techcrunch.com/2014/10/07/github-partners-with-digital-ocean-unreal-engine-and-others-to-give-students-free-access-to-developer-tools/",
                "3": "https://web.archive.org/web/20190123010650/https://github.blog/2016-06-25-github-campus-experts-technology-leadership-at-your-school/",
                "4": "https://web.archive.org/web/20210322042246/https://www.wired.com/2014/04/tom-pw/"
            },
            "tags": [
                "tech",
                "software",
                "ai",
                "open source",
                "sexual harassment"
            ],
            "score": 35,
            "ownedBy": [
                "microsoft"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/c/c2/GitHub_Invertocat_Logo.svg",
            "siteUrl": "https://github.com/",
            "updatedAt": "2023-12-15T22:56:44.080Z"
        },
        "microsoft": {
            "names": [
                "Microsoft",
                "MicroSoft",
                "Microsoft Corporation",
                "Microsoft Inc.",
                "Azure",
                "Bing",
                "MSFT"
            ],
            "comment": "Microsoft is an American technology company that has received recognition for sustainable practices [1], workplace gender inclusivity [2] and provided cyber security services to Ukraine [3]. Microsoft has avoided billions in tax [4] while replacing journalists with AI [5], abused its monopolistic position [6][7], participated in government surveillance of the public [8][9] and construction of a Microsoft data center destroyed a historic black cemetery [10].",
            "sources": {
                "1": "https://www.epa.gov/greenpower/green-power-leadership-awardees",
                "2": "https://web.archive.org/web/20100703003315/http://www.hrc.org/about_us/7115.htm",
                "3": "https://blogs.microsoft.com/on-the-issues/2022/06/22/defending-ukraine-early-lessons-from-the-cyber-war/",
                "4": "https://www.theguardian.com/world/2021/jun/03/microsoft-irish-subsidiary-paid-zero-corporate-tax-on-220bn-profit-last-year",
                "5": "https://www.seattletimes.com/business/local-business/microsoft-is-cutting-dozens-of-msn-news-production-workers-and-replacing-them-with-artificial-intelligence/",
                "6": "https://www.usdoj.gov/atr/cases/f2600/2613.htm",
                "7": "https://web.archive.org/web/20190831090402/https://www.reuters.com/article/microsoft-eu/eu-fines-microsoft-record-899-million-euros-idUSBRU00634320080227",
                "8": "https://www.webcitation.org/6HEwkERtq?url=http://www.washingtonpost.com/investigations/us-intelligence-mining-data-from-nine-us-internet-companies-in-broad-secret-program/2013/06/06/3a0c0da8-cebf-11e2-8845-d970ccb04497_story.html?hpid=z1",
                "9": "https://web.archive.org/web/20151119014627/http://www.theguardian.com/world/2013/jul/11/microsoft-nsa-collaboration-user-data",
                "10": "https://www.propublica.org/article/how-authorities-erased-historic-black-cemetery-virginia"
            },
            "tags": [
                "tech",
                "software",
                "ai",
                "cybersecurity",
                "gaming",
                "electronics",
                "anticompetetive",
                "antiprivacy",
                "tax avoidance"
            ],
            "score": 35,
            "ownedBy": [],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/9/96/Microsoft_logo_%282012%29.svg",
            "siteUrl": "https://www.microsoft.com/",
            "updatedAt": "2023-12-16T16:14:19.614Z"
        },
        "hugging-face": {
            "names": [
                "Hugging Face",
                "Hugging Face Inc."
            ],
            "comment": "HuggingFace is a French-American software and artificial intelligence company which provides free access to machine learning models [1]. HuggingFace has campaigned for legislation to support open-source AI development [2].",
            "sources": {
                "1": "https://www.pcguide.com/ai/faq/hugging-face/",
                "2": "https://venturebeat.com/ai/hugging-face-github-and-more-unite-to-defend-open-source-in-eu-ai-legislation/"
            },
            "tags": [
                "tech",
                "ai",
                "open source"
            ],
            "score": 70,
            "ownedBy": [],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/en/4/45/Hugging_Face_logo.svg",
            "siteUrl": "https://huggingface.co/",
            "updatedAt": "2023-12-17T12:55:21.833Z"
        },
        "youtube": {
            "names": [
                "YouTube",
                "Youtube",
                "You Tube"
            ],
            "comment": "YouTube is an American video streaming platform owned by Google, which is a leading source of free entertainment and educational content online [1][2], and has empowered independent creators to build their audience [3]. Youtube has been fined $170 million for collecting the personal information of minors [4], blocked users' content due to copyright even when fair use applies [5], applied an algorithm that propagates radical content [6][7][8] and showed videos containing violent and disturbing content to children [9].",
            "sources": {
                "1": "https://web.archive.org/web/20230614094637/http://www.forbes.com/advisor/business/social-media-statistics/",
                "2": "https://web.archive.org/web/20121105215846/http://www.forbes.com/sites/michaelnoer/2012/11/02/one-man-one-computer-10-million-students-how-khan-academy-is-reinventing-education/",
                "3": "https://web.archive.org/web/20140106071553/https://www.reuters.com/article/2007/02/26/us-youtube-idUSN2518918320070226",
                "4": "https://web.archive.org/web/20190906200850/https://www.techdirt.com/articles/20190905/17343942934/ftcs-latest-fine-youtube-over-coppa-violations-shows-that-coppa-section-230-are-collision-course.shtml",
                "5": "https://web.archive.org/web/20210422152639/https://www.theverge.com/2019/5/24/18635904/copyright-youtube-creators-dmca-takedown-fair-use-music-cover",
                "6": "https://www.wsj.com/articles/how-youtube-drives-viewers-to-the-internets-darkest-corners-1518020478",
                "7": "https://www.cjr.org/the_media_today/youtube-conspiracy-radicalization.php",
                "8": "https://web.archive.org/web/20190122035903/https://www.nytimes.com/2018/03/10/opinion/sunday/youtube-politics-radical.html",
                "9": "https://news10.com/2017/03/31/report-thousands-of-videos-mimicking-popular-cartoons-on-youtube-kids-contain-inappropriate-content/"
            },
            "tags": [
                "tech",
                "video",
                "media",
                "radicalisation",
                "antiprivacy"
            ],
            "score": 20,
            "ownedBy": [
                "google"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/b/b8/YouTube_Logo_2017.svg",
            "siteUrl": "https://www.youtube.com/",
            "updatedAt": "2023-12-17T15:24:11.664Z"
        },
        "ford": {
            "names": [
                "Ford",
                "Ford Motor Company",
                "F"
            ],
            "comment": "Ford is an American car company that has committed environmental waste violations [1], sold unsafe vehicles [2][3] and discriminated against minorities in its financing policies [4]. Ford's philanthropic arm contributes millions annually to education and community building [5][6].",
            "sources": {
                "1": "https://www.nytimes.com/2007/07/29/business/yourmoney/29spill.html",
                "2": "https://articles.latimes.com/2013/aug/01/autos/la-fi-hy-autos-nhtsa-ford-with-fine-recall-20130801",
                "3": "https://www.nytimes.com/2014/12/19/business/ford-recall-of-takata-airbags-to-extend-nationwide.html",
                "4": "https://www.nytimes.com/2005/03/01/business/01ford.html",
                "5": "https://web.archive.org/web/20190123223816/https://www.insidephilanthropy.com/grants-for-k-12-education/ford-motor-company-fund-community-services-grants-for-k-12-e.html",
                "6": "https://web.archive.org/web/20190123224600/https://shareholder.ford.com/investors/sustainability/default.aspx"
            },
            "tags": [
                "cars",
                "discrimination",
                "unsustainable",
                "endangerment"
            ],
            "score": 45,
            "ownedBy": [],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/3/3e/Ford_logo_flat.svg",
            "siteUrl": "https://www.ford.com/",
            "updatedAt": "2023-12-17T19:20:12.403Z"
        },
        "coca-cola": {
            "names": [
                "Coca-Cola",
                "Coca Cola",
                "CocaCola",
                "Coke",
                "The Coca-Cola Company",
                "KO"
            ],
            "comment": "The Coca-Cola Company is an American beverage corporation which has been branded by Forbes as the world's most polluting brand [1], producing 200,000 plastic bottles each minute [2], while depleting water resources in areas that already suffer from drought [3]. The company has made some pledges to reduce its use of plastic [4][5].",
            "sources": {
                "1": "https://www.forbes.com/sites/trevornace/2019/10/29/coca-cola-named-the-worlds-most-polluting-brand-in-plastic-waste-audit/",
                "2": "https://www.indiatimes.com/news/india/pepsico-was-the-biggest-plastic-polluter-in-india-in-2022-coca-cola-globally-584817.html",
                "3": "https://waronwant.org/news-analysis/coca-cola-drinking-world-dry",
                "4": "https://www.bloomberg.com/news/articles/2019-11-19/coca-cola-races-ahead-in-sweden-shifting-to-recycled-plastic?leadSource=uverify%20wall#xj4y7vzkg",
                "5": "https://www.beveragedaily.com/Article/2022/02/15/Coca-Cola-s-25-reusable-packaging-goal-How-will-this-be-achieved"
            },
            "tags": [
                "beverages",
                "plastic",
                "water depletion",
                "unsustainable"
            ],
            "score": 10,
            "ownedBy": [],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/0/09/The_Coca-Cola_Company_%282020%29.svg",
            "siteUrl": "https://coca-colacompany.com/",
            "updatedAt": "2023-12-17T22:18:51.032Z"
        },
        "appletiser": {
            "names": [
                "Appletiser"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "beverages"
            ],
            "score": 10,
            "ownedBy": [
                "coca-cola"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/en/8/8b/Appletiser.png",
            "siteUrl": "http://appletiser.com/",
            "updatedAt": "2024-02-24T11:12:32.673Z"
        },
        "sprite": {
            "names": [
                "Sprite"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "beverages"
            ],
            "score": 10,
            "ownedBy": [
                "coca-cola"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/a/ae/Sprite_2022.svg",
            "siteUrl": "https://www.sprite.com/",
            "updatedAt": "2024-02-24T11:12:32.673Z"
        },
        "smartwater": {
            "names": [
                "smartwater"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "beverages"
            ],
            "score": 10,
            "ownedBy": [
                "coca-cola"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/en/7/7a/Glaceau_Smartwater.jpg",
            "siteUrl": "http://drinksmartwater.com/",
            "updatedAt": "2024-02-24T11:12:32.673Z"
        },
        "powerade": {
            "names": [
                "Powerade"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "beverages"
            ],
            "score": 10,
            "ownedBy": [
                "coca-cola"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/e/e6/Powerade_logo.png",
            "siteUrl": "http://www.us.powerade.com/",
            "updatedAt": "2024-02-24T11:12:32.673Z"
        },
        "innocent": {
            "names": [
                "Innocent",
                "Innocent Smoothies",
                "Innocent Drinks"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "beverages"
            ],
            "score": 10,
            "ownedBy": [
                "coca-cola"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/en/5/53/Innocent_Drinks.png",
            "siteUrl": "https://www.innocentdrinks.co.uk/",
            "updatedAt": "2024-02-24T11:12:32.673Z"
        },
        "capri-sun": {
            "names": [
                "Capri-Sun"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "beverages"
            ],
            "score": 10,
            "ownedBy": [
                "coca-cola",
                "kraft-heinz"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/en/7/72/Capri_Sun_EMEA_logo.png",
            "siteUrl": "https://capri-sun.com/group/en/",
            "updatedAt": "2024-02-24T11:12:32.673Z"
        },
        "dasani": {
            "names": [
                "Dasani"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "beverages"
            ],
            "score": 10,
            "ownedBy": [
                "coca-cola"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/8/87/Dasani_Logo.svg",
            "siteUrl": "https://www.dasani.com/",
            "updatedAt": "2024-02-24T11:12:32.673Z"
        },
        "fuze": {
            "names": [
                "Fuze"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "beverages"
            ],
            "score": 10,
            "ownedBy": [
                "coca-cola"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/en/f/f0/Fuze_Beverage_logo.png",
            "siteUrl": "https://www.fuzebev.com/",
            "updatedAt": "2024-02-24T11:12:32.673Z"
        },
        "minute-maid": {
            "names": [
                "Minute Maid"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "beverages"
            ],
            "score": 10,
            "ownedBy": [
                "coca-cola"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/b/b3/Minute_Maid_2023.svg",
            "siteUrl": "http://minutemaid.com/",
            "updatedAt": "2024-02-24T11:12:32.673Z"
        },
        "oasis": {
            "names": [
                "Oasis"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "beverages"
            ],
            "score": 10,
            "ownedBy": [
                "coca-cola"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/en/0/04/Oasis_Drinks_logo.png",
            "siteUrl": "https://www.coca-cola.co.uk/drinks/oasis",
            "updatedAt": "2024-02-24T11:12:32.673Z"
        },
        "relentless": {
            "names": [
                "Relentless"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "beverages"
            ],
            "score": 10,
            "ownedBy": [
                "coca-cola"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/en/e/e1/Relentlesscan.png",
            "siteUrl": "http://www.relentlessenergy.com/",
            "updatedAt": "2024-02-24T11:12:32.673Z"
        },
        "rockstar": {
            "names": [
                "Rockstar",
                "Rockstar North",
                "Rockstar Games UK",
                "Rockstar Games"
            ],
            "comment": "Rockstar, a video game company, faced controversy over its poor working conditions and has acknowledged needing improvement while defending against allegations of deceptive practices in the Hot Coffee scandal investigation [1]. The House of Representatives voted to support a Federal Trade Commission probe into Grand Theft Auto: San Andreas amidst accusations of deceiving the Entertainment Software Ratings Board to avoid an \"Adults-Only\" rating [2].",
            "sources": {
                "1": "https://www.theguardian.com/games/2018/oct/18/rockstar-games-working-conditions-red-dead-redemption-2-rob-nelson",
                "2": "https://www.gamespot.com/articles/house-backs-federal-investigation-of-rockstar-games/1100-6129723/"
            },
            "tags": [
                "video games",
                "bad working conditions"
            ],
            "score": 50,
            "ownedBy": [
                "take-two"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/5/53/Rockstar_Games_Logo.svg",
            "siteUrl": "https://www.rockstargames.com/",
            "updatedAt": "2024-03-01T22:17:52.078Z"
        },
        "pepsico": {
            "names": [
                "PepsiCo",
                "PepsiCo Inc.",
                "Pepsi",
                "PEP"
            ],
            "comment": "PepsiCo is an American food and beverage conglomerate which is one of the biggest plastic polluters worldwide [1], has contributed to water shortages in drought-affected areas [2], and been complicit in illegal rainforest destruction [3]. The company has aimed to reduce its environmental impact, achieving positive water balance in India [4].",
            "sources": {
                "1": "https://www.fastcompany.com/90425011/coca-cola-nestle-and-pepsico-are-the-worlds-biggest-plastic-polluters-again",
                "2": "https://web.archive.org/web/20070602161948/http://www.businessweek.com/globalbiz/content/may2007/gb20070531_868198.htm",
                "3": "https://www.theguardian.com/environment/2017/jul/21/pepsico-unilever-and-nestle-accused-of-complicity-in-illegal-rainforest-destruction",
                "4": "https://web.archive.org/web/20100821142748/http://www.pepsico.com/Download/PepsiCo_2009_Sustainability_Report_Overview.pdf"
            },
            "tags": [
                "beverages",
                "plastic",
                "water depletion",
                "deforestation"
            ],
            "score": 20,
            "ownedBy": [],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/6/68/Pepsi_2023.svg",
            "siteUrl": "pepsico.com",
            "updatedAt": "2023-12-22T12:14:02.971Z"
        },
        "nestle": {
            "names": [
                "Nestlé",
                "Nestlé S.A.",
                "Nestle"
            ],
            "comment": "Nestlé is the world's largest food and beverage company which has been named by Ukraine as an International Sponsor of War [1], sourced cocoa produced by child labour in West Africa [2][3], and questioned whether access to drinking water should be a human right [4]. The company has made some positive efforts like improving agriculture practices and building schools for the children of their farmers [5].",
            "sources": {
                "1": "https://zmina.info/en/news-en/nestle-listed-as-international-war-sponsor-by-ukraines-anti-corruption-agency/",
                "2": "https://web.archive.org/web/20081226141935/http://news.bbc.co.uk/1/hi/world/africa/1272522.stm",
                "3": "https://web.archive.org/web/20090114115945/http://news.bbc.co.uk/2/hi/africa/1311982.stm",
                "4": "https://web.archive.org/web/20170629043128/http://www.thenational.ae/arts-culture/the-human-rights-and-wrongs-of-nestl-and-water-for-all",
                "5": "https://web.archive.org/web/20160103032603/http://www.mnn.com/food/healthy-eating/stories/what-does-the-cocoa-plan-label-on-chocolate-mean"
            },
            "tags": [
                "food",
                "beverages",
                "child labour",
                "russia"
            ],
            "score": 10,
            "ownedBy": [],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/en/d/d8/Nestl%C3%A9.svg",
            "siteUrl": "https://nestle.com/",
            "updatedAt": "2023-12-23T00:34:27.776Z"
        },
        "polo": {
            "names": [
                "Polo"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "confectionery"
            ],
            "score": 10,
            "ownedBy": [
                "nestle"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/en/3/33/Polo_Mints_logo.png",
            "siteUrl": "https://www.nestleprofessional.co.uk/our-solutions/impulse-confectionery/our-brands/polo",
            "updatedAt": "2024-03-18T17:06:41.962Z"
        },
        "carnation": {
            "names": [
                "Carnation"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "food"
            ],
            "score": 10,
            "ownedBy": [
                "nestle"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/en/2/20/Carnationlogo.png",
            "siteUrl": "http://www.nestle.com/",
            "updatedAt": "2024-03-18T17:06:36.629Z"
        },
        "nesquik": {
            "names": [
                "Nesquik"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "food"
            ],
            "score": 10,
            "ownedBy": [
                "nestle"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/f/f5/Nesquik_logo_2023.svg",
            "siteUrl": "https://www.goodnes.com/nesquik/",
            "updatedAt": "2024-03-18T17:06:35.041Z"
        },
        "san-pellegrino": {
            "names": [
                "San Pellegrino"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "beverages"
            ],
            "score": 10,
            "ownedBy": [
                "nestle"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/5/52/Sanpelegrino.svg",
            "siteUrl": "http://www.sanpellegrino.com/",
            "updatedAt": "2024-03-18T17:06:37.166Z"
        },
        "nescafe": {
            "names": [
                "Nescafe"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "coffee",
                "beverages"
            ],
            "score": 10,
            "ownedBy": [
                "nestle"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/e/e0/Nescafe_logo_2014.png",
            "siteUrl": "http://nescafe.com/",
            "updatedAt": "2024-03-18T17:06:38.475Z"
        },
        "nespresso": {
            "names": [
                "Nespresso"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "coffee",
                "beverages"
            ],
            "score": 10,
            "ownedBy": [
                "nestle"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/4/4e/Nespresso_logo_%28monogram_%2B_wordmark%29.svg",
            "siteUrl": "https://www.nespresso.com/",
            "updatedAt": "2024-03-18T17:06:34.788Z"
        },
        "perrier": {
            "names": [
                "Perrier"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "beverages"
            ],
            "score": 10,
            "ownedBy": [
                "nestle"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/7/7e/Perrier_logo.svg",
            "siteUrl": "http://perrier.com/",
            "updatedAt": "2024-03-18T17:06:38.213Z"
        },
        "princes-gate": {
            "names": [
                "Princes Gate"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "beverages"
            ],
            "score": 10,
            "ownedBy": [
                "nestle"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/en/6/68/Princes_Gate_Spring_Water_logo.png",
            "siteUrl": "https://nestle.com/",
            "updatedAt": "2024-03-18T17:06:35.261Z"
        },
        "curiously-cinnamon": {
            "names": [
                "Curiously Cinnamon"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "food"
            ],
            "score": 10,
            "ownedBy": [
                "nestle"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/1/1d/Cinnamon_toastcrunch_logo.png",
            "siteUrl": "https://www.cinnamontoastcrunch.com/",
            "updatedAt": "2024-03-18T17:06:34.473Z"
        },
        "cookie-crisp": {
            "names": [
                "Cookie Crisp"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "food"
            ],
            "score": 10,
            "ownedBy": [
                "nestle"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/en/e/eb/Cookiecrisp_brand_logo.png",
            "siteUrl": "https://cereals.generalmills.com/products/cookie-crisp/",
            "updatedAt": "2024-03-18T17:06:36.844Z"
        },
        "cheerios": {
            "names": [
                "Cheerios"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "food"
            ],
            "score": 10,
            "ownedBy": [
                "nestle"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/0/07/Cheerios_brand_logo.svg",
            "siteUrl": "https://www.cheerios.com/",
            "updatedAt": "2024-03-18T17:06:39.319Z"
        },
        "golden-nuggets": {
            "names": [
                "Golden Nuggets"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "food"
            ],
            "score": 10,
            "ownedBy": [
                "nestle"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/en/1/17/Goldennuggets_brand_logo.png",
            "siteUrl": "http://nestle-cereals.com/uk/brands/golden-nuggets",
            "updatedAt": "2024-03-18T17:06:39.014Z"
        },
        "shreddies": {
            "names": [
                "Shreddies"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "food"
            ],
            "score": 10,
            "ownedBy": [
                "nestle"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/4/4a/Shreddies_postbrands_logo.png",
            "siteUrl": "https://www.postconsumerbrands.ca/brand/shreddies/products/original-shreddies/",
            "updatedAt": "2024-03-18T17:06:36.179Z"
        },
        "aero": {
            "names": [
                "Aero"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "confectionery"
            ],
            "score": 10,
            "ownedBy": [
                "nestle"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/en/b/b9/Aero_chocolate_logo.png",
            "siteUrl": "https://www.aerochocolate.co.uk/",
            "updatedAt": "2024-03-18T17:06:42.606Z"
        },
        "caramac": {
            "names": [
                "Caramac"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "confectionery"
            ],
            "score": 10,
            "ownedBy": [
                "nestle"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/en/b/bc/Caramac_brand_logo.png",
            "siteUrl": "https://www.nestle.co.uk/en-gb/brands/chocolate_and_confectionery/chocolate/caramac",
            "updatedAt": "2024-03-18T17:06:37.958Z"
        },
        "chips-ahoy!": {
            "names": [
                "Chips Ahoy!"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "food"
            ],
            "score": 10,
            "ownedBy": [
                "nestle"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/0/05/Chips_ahoy_brandlogo.png",
            "siteUrl": "https://www.snackworks.com/brands/chips-ahoy",
            "updatedAt": "2024-03-18T17:06:36.351Z"
        },
        "milkybar": {
            "names": [
                "Milkybar"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "confectionery"
            ],
            "score": 10,
            "ownedBy": [
                "nestle"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/8/84/Milkybar_logo.png",
            "siteUrl": "https://www.milkybar.co.uk/",
            "updatedAt": "2024-03-18T17:06:35.380Z"
        },
        "kit-kat": {
            "names": [
                "Kit Kat"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "confectionery"
            ],
            "score": 10,
            "ownedBy": [
                "nestle"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/en/b/b6/Kit_kat_logo17.png",
            "siteUrl": "https://www.kitkat.com/",
            "updatedAt": "2024-03-18T17:06:40.806Z"
        },
        "munchies": {
            "names": [
                "Munchies"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "confectionery"
            ],
            "score": 10,
            "ownedBy": [
                "nestle"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/a/a6/Munchies_brand_logo.png",
            "siteUrl": "https://www.nestle.co.uk/en-gb/brands/chocolate_and_confectionery/chocolate/munchies",
            "updatedAt": "2024-03-18T17:06:35.689Z"
        },
        "quality-street": {
            "names": [
                "Quality Street"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "confectionery"
            ],
            "score": 10,
            "ownedBy": [
                "nestle"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/c/c9/Qualitystreet_brand_logo.png",
            "siteUrl": "https://www.qualitystreet.co.uk/",
            "updatedAt": "2024-03-18T17:06:37.764Z"
        },
        "rolo": {
            "names": [
                "Rolo"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "confectionery"
            ],
            "score": 10,
            "ownedBy": [
                "nestle"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/d/db/Rolo_brand_logo.png",
            "siteUrl": "https://www.nestle.co.uk/en-gb/brands/chocolate_and_confectionery/chocolate/rolo",
            "updatedAt": "2024-03-18T17:06:35.523Z"
        },
        "rowntrees": {
            "names": [
                "Rowntrees"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "confectionery"
            ],
            "score": 10,
            "ownedBy": [
                "nestle"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/en/a/a8/Rowntree%27s_logo.png",
            "siteUrl": "https://www.rowntrees.co.uk/",
            "updatedAt": "2024-03-18T17:06:37.562Z"
        },
        "smarties": {
            "names": [
                "Smarties"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "confectionery"
            ],
            "score": 10,
            "ownedBy": [
                "nestle"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/en/5/51/Nestle_smarties_logo.png",
            "siteUrl": "https://www.nestle.com/brands/allbrands/smarties",
            "updatedAt": "2024-03-18T17:06:38.736Z"
        },
        "sma": {
            "names": [
                "SMA"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "food"
            ],
            "score": 10,
            "ownedBy": [
                "nestle"
            ],
            "logoUrl": "https://www.smababy.co.uk/sites/default/files/site_logo.png",
            "siteUrl": "https://www.smababy.co.uk/",
            "updatedAt": "2024-03-18T17:06:44.373Z"
        },
        "yorkie": {
            "names": [
                "Yorkie"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "confectionery"
            ],
            "score": 10,
            "ownedBy": [
                "nestle"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/en/a/aa/Yorkie_brand_logo.png",
            "siteUrl": "https://www.nestle.co.uk/en-gb/brands/chocolate_and_confectionery/chocolate/yorkie",
            "updatedAt": "2024-03-18T17:06:44.373Z"
        },
        "haagen-dazs": {
            "names": [
                "Häagen-Dazs"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "confectionery"
            ],
            "score": 10,
            "ownedBy": [
                "nestle"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/3/3c/H%C3%A4agen-Dazs_Logo.svg",
            "siteUrl": "https://www.icecream.com/us/en/brands/haagen-dazs",
            "updatedAt": "2024-03-18T17:06:39.652Z"
        },
        "hot-pockets": {
            "names": [
                "Hot Pockets"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "food"
            ],
            "score": 10,
            "ownedBy": [
                "nestle"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/f/fc/Hot_Pockets_2021_logo.png",
            "siteUrl": "http://www.hotpockets.com/",
            "updatedAt": "2024-03-18T17:06:37.368Z"
        },
        "felix": {
            "names": [
                "Felix"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "pets"
            ],
            "score": 10,
            "ownedBy": [
                "nestle"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/en/d/d1/Purina_Felix_logo.png",
            "siteUrl": "https://www.purina.co.uk/cat/felix/",
            "updatedAt": "2024-03-18T17:06:40.000Z"
        },
        "purina": {
            "names": [
                "Purina"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "pets"
            ],
            "score": 10,
            "ownedBy": [
                "nestle"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/9/90/Lg_checker.svg",
            "siteUrl": "http://purina.com/",
            "updatedAt": "2024-03-18T17:06:41.248Z"
        },
        "lily's-kitchen": {
            "names": [
                "Lily's Kitchen"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "food"
            ],
            "score": 10,
            "ownedBy": [
                "nestle"
            ],
            "logoUrl": "https://www.lilyskitchen.co.uk/on/demandware.static/-/Library-Sites-lilsrp-content-global/default/dwb65fdf38/header/logo.svg",
            "siteUrl": "https://www.lilyskitchen.co.uk/",
            "updatedAt": "2024-03-18T17:06:33.810Z"
        },
        "l'oreal": {
            "names": [
                "L'Oréal"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "consumer goods"
            ],
            "score": 10,
            "ownedBy": [
                "nestle"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/9/9d/L%27Or%C3%A9al_logo.svg",
            "siteUrl": "https://www.loreal.com/",
            "updatedAt": "2024-03-18T17:06:43.352Z"
        },
        "britvic": {
            "names": [
                "Britvic",
                "Britvic plc",
                "BVIC"
            ],
            "comment": "Britvic is a British beverage company. Britvic produces thousands of tonnes of single-use plastic per year [1] but has invested in sustainable energy sources and increased recycling rates [1].",
            "sources": {
                "1": "https://www.britvic.com/media/krpdotmg/britvic-annual-report-and-accounts-2023.pdf"
            },
            "tags": [
                "beverages",
                "plastic"
            ],
            "score": 50,
            "ownedBy": [],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/en/f/ff/Britvic_logo.png",
            "siteUrl": "https://www.britvic.co.uk/",
            "updatedAt": "2023-12-23T09:41:38.711Z"
        },
        "fruit-shoot": {
            "names": [
                "Fruit Shoot",
                "Fruitshoot"
            ],
            "comment": "",
            "sources": {
                "1": "https://www.britvic.com/media/krpdotmg/britvic-annual-report-and-accounts-2023.pdf#page=5"
            },
            "tags": [
                "beverages"
            ],
            "score": 50,
            "ownedBy": [
                "britvic"
            ],
            "logoUrl": "https://fruitshoot.com/media/pshnbkx4/master-nav-logo.svg",
            "siteUrl": "https://fruitshoot.com/",
            "updatedAt": "2023-12-23T20:26:43.695Z"
        },
        "ballygowan": {
            "names": [
                "Ballygowan"
            ],
            "comment": "",
            "sources": {
                "1": "https://www.britvic.com/media/krpdotmg/britvic-annual-report-and-accounts-2023.pdf#page=5"
            },
            "tags": [
                "beverages"
            ],
            "score": 50,
            "ownedBy": [
                "britvic"
            ],
            "logoUrl": "https://www.ballygowan.ie/media/ttybvmzh/ballygowan_logo_all.svg",
            "siteUrl": "https://www.ballygowan.ie/",
            "updatedAt": "2023-12-23T20:26:43.695Z"
        },
        "j2o": {
            "names": [
                "J2O"
            ],
            "comment": "",
            "sources": {
                "1": "https://www.britvic.com/media/krpdotmg/britvic-annual-report-and-accounts-2023.pdf#page=5"
            },
            "tags": [
                "beverages"
            ],
            "score": 50,
            "ownedBy": [
                "britvic"
            ],
            "logoUrl": "https://www.j2o.co.uk/media/gs3hmorz/j2o_logo_2018.png",
            "siteUrl": "https://www.j2o.co.uk/",
            "updatedAt": "2023-12-23T20:26:43.695Z"
        },
        "r.-white's": {
            "names": [
                "R. White's",
                "R Whites"
            ],
            "comment": "",
            "sources": {
                "1": "https://www.britvic.com/media/krpdotmg/britvic-annual-report-and-accounts-2023.pdf#page=5"
            },
            "tags": [
                "beverages"
            ],
            "score": 50,
            "ownedBy": [
                "britvic"
            ],
            "logoUrl": "https://www.rwhites.co.uk/media/xn3ehtcu/logo.png",
            "siteUrl": "https://www.rwhites.co.uk/",
            "updatedAt": "2023-12-24T21:19:13.747Z"
        },
        "jimmy's-iced-coffee": {
            "names": [
                "Jimmy's Iced Coffee",
                "Jimmy's",
                "Jimmys Iced Coffee",
                "Jimmys"
            ],
            "comment": "",
            "sources": {
                "1": "https://web.archive.org/web/20231223224400/https://www.britvic.com/our-news/performance/britvic-acquires-jimmy-s-iced-coffee/"
            },
            "tags": [
                "coffee",
                "beverages"
            ],
            "score": 50,
            "ownedBy": [
                "britvic"
            ],
            "logoUrl": "https://www.jimmysicedcoffee.com/cdn/shop/files/logo-large_300x@3x.png?v=1646849230",
            "siteUrl": "https://www.jimmysicedcoffee.com/",
            "updatedAt": "2023-12-23T20:26:43.695Z"
        },
        "the-london-essence-co": {
            "names": [
                "The London Essence Co",
                "The London Essence Co.",
                "London Essence"
            ],
            "comment": "",
            "sources": {
                "1": "https://web.archive.org/web/20230324214813/https://www.londonessenceco.com/en/privacy-policy/"
            },
            "tags": [
                "beverages"
            ],
            "score": 50,
            "ownedBy": [
                "britvic"
            ],
            "logoUrl": "https://www.londonessenceco.com/media/3mpllmke/londonessence_logo.svg",
            "siteUrl": "https://www.londonessenceco.com/en",
            "updatedAt": "2023-12-23T20:26:43.695Z"
        },
        "teisseire": {
            "names": [
                "Teisseire"
            ],
            "comment": "",
            "sources": {
                "1": "https://www.en.teisseire.com/our-history"
            },
            "tags": [
                "beverages"
            ],
            "score": 50,
            "ownedBy": [
                "britvic"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/en/4/40/Teisseire_logo.png",
            "siteUrl": "https://teisseire.com/",
            "updatedAt": "2023-12-23T20:26:43.695Z"
        },
        "robinsons": {
            "names": [
                "Robinsons",
                "Robinson's"
            ],
            "comment": "",
            "sources": {
                "1": "https://www.britvic.com/media/krpdotmg/britvic-annual-report-and-accounts-2023.pdf#page=5"
            },
            "tags": [
                "beverages"
            ],
            "score": 50,
            "ownedBy": [
                "britvic"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/en/thumb/9/96/Logo_of_Robinsons.png/400px-Logo_of_Robinsons.png",
            "siteUrl": "https://www.robinsonssquash.co.uk/",
            "updatedAt": "2023-12-23T20:26:43.695Z"
        },
        "aqua-libra": {
            "names": [
                "Aqua Libra",
                "AquaLibra"
            ],
            "comment": "",
            "sources": {
                "1": "https://www.britvic.com/media/krpdotmg/britvic-annual-report-and-accounts-2023.pdf#page=5"
            },
            "tags": [
                "beverages"
            ],
            "score": 50,
            "ownedBy": [
                "britvic"
            ],
            "logoUrl": "https://aqualibradrinks.com/media/ad5fob5y/logo-fan_on_white-back.png",
            "siteUrl": "https://aqualibradrinks.com/",
            "updatedAt": "2023-12-23T21:49:32.761Z"
        },
        "tango": {
            "names": [
                "Tango"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "beverages"
            ],
            "score": 50,
            "ownedBy": [
                "britvic"
            ],
            "logoUrl": "https://tango.co.uk/media/jkkjey2n/tango_logo.png",
            "siteUrl": "https://tango.co.uk/",
            "updatedAt": "2024-01-01T20:44:53.157Z"
        },
        "diet-coke": {
            "names": [
                "Diet Coke",
                "Coca-Cola Light"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "beverages"
            ],
            "score": 10,
            "ownedBy": [
                "coca-cola"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/3/3c/Diet_Coke_logo.svg",
            "siteUrl": "",
            "updatedAt": "2024-01-01T20:11:10.557Z"
        },
        "fanta": {
            "names": [
                "Fanta"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "beverages"
            ],
            "score": 10,
            "ownedBy": [
                "coca-cola"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/c/c2/Fanta_2023.svg",
            "siteUrl": "https://www.fanta.com/",
            "updatedAt": "2024-01-02T18:49:28.296Z"
        },
        "dr-pepper": {
            "names": [
                "Dr Pepper",
                "Dr. Pepper",
                "Keurig Dr Pepper",
                "KDP"
            ],
            "comment": "Dr Pepper is an American beverage company that produces hundreds of thousands of tonnes of plastic packaging per year [1] and is a major consumer of water [1]. Dr Pepper is distributed by the Coca-Cola company in the UK [2]. ",
            "sources": {
                "1": "https://www.keurigdrpepper.com/content/dam/keurig-brand-sites/kdp/files/KDP-CR-Report-2022.pdf.html",
                "2": "https://www.coca-cola.com/gb/en/brands/dr-pepper"
            },
            "tags": [
                "beverages",
                "waterusage",
                "plastic"
            ],
            "score": 30,
            "ownedBy": [],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/en/1/19/Dr_Pepper_modern.svg",
            "siteUrl": "https://drpepper.com/en",
            "updatedAt": "2024-01-01T20:19:02.959Z"
        },
        "snapple": {
            "names": [
                "Snapple"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "beverages"
            ],
            "score": 30,
            "ownedBy": [
                "dr-pepper"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/4/43/Snapple_logo_%282020%29.svg",
            "siteUrl": "",
            "updatedAt": "2024-01-01T20:41:16.271Z"
        },
        "hellman's": {
            "names": [
                "Hellman's",
                "Best Foods"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "food"
            ],
            "score": 5,
            "ownedBy": [
                "unilever"
            ],
            "logoUrl": "https://1000logos.net/wp-content/uploads/2020/09/Hellmanns-Logo-2004-1024x585.jpg",
            "siteUrl": "https://www.hellmanns.com/",
            "updatedAt": "2024-01-27T01:40:56.316Z"
        },
        "marmite": {
            "names": [
                "Marmite"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "food"
            ],
            "score": 5,
            "ownedBy": [
                "unilever"
            ],
            "logoUrl": "https://i.pinimg.com/originals/aa/05/e1/aa05e1c98241eefd076b4f996ef87482.jpg",
            "siteUrl": "https://www.marmite.co.uk/",
            "updatedAt": "2024-01-27T01:40:56.316Z"
        },
        "ben-and-jerry's": {
            "names": [
                "Ben & Jerry's",
                "Ben and Jerry's",
                "Ben & Jerrys",
                "Ben and Jerrys"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "confectionery"
            ],
            "score": 5,
            "ownedBy": [
                "unilever"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/3/3c/Ben_%26_Jerry%27s.svg",
            "siteUrl": "https://www.benjerry.com/",
            "updatedAt": "2024-01-27T01:40:56.316Z"
        },
        "wall's": {
            "names": [
                "Wall's"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "confectionery"
            ],
            "score": 5,
            "ownedBy": [
                "unilever"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/2/23/Wall%27s_Logo.svg",
            "siteUrl": "https://www.wallsicecream.com/",
            "updatedAt": "2024-01-27T01:40:56.316Z"
        },
        "pot-noodle": {
            "names": [
                "Pot Noodle"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "food"
            ],
            "score": 5,
            "ownedBy": [
                "unilever"
            ],
            "logoUrl": "https://www.potnoodle.com/sk-eu/content/dam/brands/all_brand_positions/global_use/1724553-pot-noodle-logo.png.rendition.380.380.png",
            "siteUrl": "https://www.potnoodle.com/",
            "updatedAt": "2024-01-27T01:40:56.316Z"
        },
        "cif": {
            "names": [
                "Cif"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "consumer goods"
            ],
            "score": 5,
            "ownedBy": [
                "unilever"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/en/9/91/Cif_logo.png",
            "siteUrl": "https://www.cifclean.co.uk/",
            "updatedAt": "2024-01-27T01:40:56.316Z"
        },
        "persil": {
            "names": [
                "Persil"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "consumer goods"
            ],
            "score": 5,
            "ownedBy": [
                "unilever"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/0/07/Persil-Logo.svg",
            "siteUrl": "https://www.persil.com/",
            "updatedAt": "2024-01-27T01:40:56.316Z"
        },
        "surf": {
            "names": [
                "Surf"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "consumer goods"
            ],
            "score": 5,
            "ownedBy": [
                "unilever"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/en/3/36/Surf-logo.png",
            "siteUrl": "https://www.unilever.co.uk/brands/home-care/surf/",
            "updatedAt": "2024-01-27T01:40:56.316Z"
        },
        "lynx": {
            "names": [
                "LYNX",
                "AXE"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "consumer goods"
            ],
            "score": 5,
            "ownedBy": [
                "unilever"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/0/0b/Lynx_logo_2021.svg",
            "siteUrl": "https://www.axe.com/",
            "updatedAt": "2024-01-27T01:40:56.316Z"
        },
        "radox": {
            "names": [
                "Radox"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "consumer goods"
            ],
            "score": 5,
            "ownedBy": [
                "unilever"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/en/1/1f/Radox_logo.png",
            "siteUrl": "https://radox.co.uk/",
            "updatedAt": "2024-01-27T01:40:56.316Z"
        },
        "dove": {
            "names": [
                "Dove"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "consumer goods"
            ],
            "score": 5,
            "ownedBy": [
                "unilever"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/4/4b/Dove_logo.png",
            "siteUrl": "https://dove.com/",
            "updatedAt": "2024-01-27T01:40:56.316Z"
        },
        "toni-and-guy": {
            "names": [
                "Toni & Guy",
                "Toni and Guy"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "consumer goods"
            ],
            "score": 5,
            "ownedBy": [
                "unilever"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/en/8/83/Toni_and_Guy_logo.png",
            "siteUrl": "Toni & Guy",
            "updatedAt": "2024-01-27T12:29:37.086Z"
        },
        "tresemme": {
            "names": [
                "TRESemmé",
                "TRESemme"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "consumer goods"
            ],
            "score": 5,
            "ownedBy": [
                "unilever"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/7/7b/Tresemme_new_logo.png",
            "siteUrl": "https://www.tresemme.com/",
            "updatedAt": "2024-01-27T12:29:37.086Z"
        },
        "glow-and-lovely": {
            "names": [
                "Glow & Lovely",
                "Glow and Lovely",
                "Fair & Lovely",
                "Fair and Lovely"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "consumer goods"
            ],
            "score": 5,
            "ownedBy": [
                "unilever"
            ],
            "logoUrl": "https://www.hul.co.in/content-images/8fedf5de81dce8eb469cda95ef2cc960e6bbc5ce-1080x1080.jpg",
            "siteUrl": "https://www.hul.co.in/brands/beauty-wellbeing/glow-lovely/",
            "updatedAt": "2024-01-27T01:40:56.316Z"
        },
        "calippo": {
            "names": [
                "Calippo"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "confectionery"
            ],
            "score": 5,
            "ownedBy": [
                "unilever"
            ],
            "logoUrl": "https://img4.wikia.nocookie.net/__cb20091229190706/logopedia/images/e/ed/Calippo_logo.png",
            "siteUrl": "",
            "updatedAt": "2024-01-27T01:40:56.316Z"
        },
        "klondike": {
            "names": [
                "Klondike"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "confectionery"
            ],
            "score": 5,
            "ownedBy": [
                "unilever"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/en/d/d5/Klondike_logo.svg",
            "siteUrl": "https://klondikebar.com/",
            "updatedAt": "2024-01-27T01:40:56.316Z"
        },
        "magnum": {
            "names": [
                "Magnum"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "confectionery"
            ],
            "score": 5,
            "ownedBy": [
                "unilever"
            ],
            "logoUrl": "https://assets.unileversolutions.com/v1/85851330.png?im=AspectCrop=(400,140);Resize=(400,140)",
            "siteUrl": "https://www.magnumicecream.com/",
            "updatedAt": "2024-01-27T01:40:56.316Z"
        },
        "red-bull": {
            "names": [
                "Red Bull",
                "Red Bull GmbH"
            ],
            "comment": "Red Bull is an Austrian energy drink brand, which can be harmful if consumed in excess [1][2]. Red Bull has created opportunities and platforms for artists and creatives [3][4], and has supported sustainability initiatives despite being a major sponsor of Formula 1 [5].",
            "sources": {
                "1": "https://doi.org/10.7759%2Fcureus.1322",
                "2": "https://www.frontiersin.org/articles/10.3389/fpubh.2014.00134/full",
                "3": "https://www.forbes.com/sites/afdhelaziz/2019/05/01/the-power-of-purpose-how-red-bull-is-giving-social-entrepreneurs-wings/",
                "4": "https://web.archive.org/web/20210920212838/https://www.metrotimes.com/detroit/red-bull-house-of-art/Content?oid=2145533",
                "5": "https://www.redbullracing.com/int-en/tackling-global-sustainability-with-global-standard"
            },
            "tags": [
                "beverages"
            ],
            "score": 50,
            "ownedBy": [],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/en/f/f5/RedBullEnergyDrink.svg",
            "siteUrl": "https://www.redbull.com/int-en/energydrink/red-bull-energy-drink",
            "updatedAt": "2024-01-01T20:44:59.786Z"
        },
        "lipton": {
            "names": [
                "Lipton",
                "Lipton Ice Tea",
                "Pepsi Lipton International"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "beverages"
            ],
            "score": 20,
            "ownedBy": [
                "pepsico",
                "unilever"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/en/thumb/f/fc/LIPTON_PRIMARY_RGB_BMT.png/300px-LIPTON_PRIMARY_RGB_BMT.png",
            "siteUrl": "https://www.lipton.com/us/en/",
            "updatedAt": "2024-01-02T19:08:02.820Z"
        },
        "tfl": {
            "names": [
                "TfL",
                "Transport for London"
            ],
            "comment": "Transport for London is London's public transport provider, which offers low-emissions transportation within greater london. TfL illegally accessed EU citizen's personal data to issue fruadulent ULEZ fines [1] and continues to pay hundreds of executives 6-figure salaries while proposing to close bus routes to save money [2].",
            "sources": {
                "1": "https://www.theguardian.com/uk-news/2024/jan/26/eu-citizens-ulez-fines-data-breach-tfl",
                "2": "https://www.standard.co.uk/news/london/tfl-executive-pay-government-bailout-tube-strike-sadiq-khan-b1009359.html"
            },
            "tags": [
                "transport",
                "metro",
                "bus",
                "public transport",
                "unfair pay"
            ],
            "score": 65,
            "ownedBy": [],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/3/3e/TfL_roundel_%28no_text%29.svg",
            "siteUrl": "https://tfl.gov.uk/",
            "updatedAt": "2024-02-10T08:09:59.878Z"
        },
        "etsy": {
            "names": [
                "Etsy",
                "Etsy, Inc.",
                "ETSY"
            ],
            "comment": "Etsy is an American ecommerce website that has lost its B Corp status [1] and faced backlash from its sellers over fees [2] and witholding of funds [3]. Etsy carbon-offsets deliveries [4] and provides a plaform to small independent businesses [5].",
            "sources": {
                "1": "https://www.ecommercebytes.com/2017/11/30/etsy-gives-b-corp-status-maintain-corporate-structure/",
                "2": "https://www.bbc.co.uk/news/business-63984098",
                "3": "https://www.bbc.co.uk/news/business-66201042",
                "4": "https://www.etsy.com/uk/blog/etsy-launches-carbon-offset-shipping",
                "5": "https://retail-insider.com/retail-insider/2021/05/etsy-provides-small-businesses-with-a-booming-e-commerce-platform-but-who-reaps-the-benefits/"
            },
            "tags": [
                "ecommerce",
                "retail"
            ],
            "score": 70,
            "ownedBy": [],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/8/89/Etsy_logo.svg",
            "siteUrl": "https://www.etsy.com/",
            "updatedAt": "2024-02-08T08:23:34.904Z"
        },
        "irn-bru": {
            "names": [
                "Irn-Bru",
                "IrnBru",
                "Irn Bru",
                "Iron Brew",
                "IRN-BRU",
                "IRN BRU"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "beverages"
            ],
            "score": null,
            "ownedBy": [
                "a.g.-barr"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/en/9/9d/Irn-Bru_logo.png",
            "siteUrl": "https://irn-bru.co.uk/",
            "updatedAt": "2024-01-02T19:17:01.423Z"
        },
        "mcdonald's": {
            "names": [
                "McDonald's",
                "McDonald's Corporation",
                "MCD"
            ],
            "comment": "McDonald's is the world's largest fast food brand, which produces more emissions than Norway [1] and is the leading producer of litter in the UK [2], whose restaurants have hosted sexual abuse [3][4], illegal employment of children [5], and racist discrimination [6]. McDonald's has avoided hundreds of millions in taxes [7], failed to meet its own pledges to reduce animal abuse in its supply chain [8], and offered free meals to the IDF as it commited war crimes in Gaza [9].",
            "sources": {
                "1": "https://www.bloomberg.com/news/articles/2021-12-01/the-carbon-footprint-of-mcdonald-s-menu-very-big",
                "2": "https://ghostarchive.org/archive/20220110/https://www.telegraph.co.uk/news/earth/earthnews/4223106/McDonalds-waste-makes-up-largest-proportion-of-fast-food-litter-on-streets.html",
                "3": "https://www.bbc.co.uk/news/business-65388445",
                "4": "https://news.sky.com/story/mcdonalds-sacks-18-workers-after-sexual-abuse-racism-and-bullying-claims-13008183",
                "5": "https://web.archive.org/web/20070418214623/https://www.abc.net.au/news/newsitems/200704/s1895071.htm",
                "6": "https://www.france24.com/en/20200414-mcdonald-s-apologises-after-china-store-bans-black-people",
                "7": "https://www.reuters.com/article/us-france-mcdonalds-tax-idUSKCN0XG2QS",
                "8": "https://plantbasednews.org/culture/ethics/mcdonalds-animal-welfare-commitments/",
                "9": "https://www.samaa.tv/208732607-mcdonald-s-faces-backlash-for-donating-free-meals-to-israeli-forces-amid-ongoing-conflict#tbl-em-lnp5au1hyagx03rjii"
            },
            "tags": [
                "fast food",
                "unsustainable",
                "sexual harassment",
                "child labour",
                "discrimination",
                "tax avoidance",
                "animal abuse",
                "proisrael"
            ],
            "score": 5,
            "ownedBy": [],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/3/36/McDonald%27s_Golden_Arches.svg",
            "siteUrl": "https://www.mcdonalds.com/us/en-us.html",
            "updatedAt": "2024-04-06T19:32:02.312Z"
        },
        "pret-a-manger": {
            "names": [
                "Pret a Manger",
                "Pret"
            ],
            "comment": "Pret a Manger is a British cafe chain that has fired unionising employees [1] and failed to label allergens on its packaging leading to hospitalisations and death of customers [2]. Inadequate health and safety protocols at Pret have led to an employee becoming trapped in a freezer and left thinking she would die [3]",
            "sources": {
                "1": "https://boingboing.net/2012/12/08/pret-fires-longstanding-employ.html",
                "2": "https://www.theguardian.com/uk-news/2018/sep/25/pret-a-manger-allergic-reactions-year-before-natasha-ednan-laperouse-died-inquest-told",
                "3": "https://www.westminster.gov.uk/news/pret-manger-fined-ps800000-after-cafe-worker-left-locked-walk-freezer"
            },
            "tags": [
                "restaurant",
                "coffee",
                "bad working conditions",
                "endangerment"
            ],
            "score": 15,
            "ownedBy": [
                "jab-holding-company"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/en/5/5d/PretLogo.svg",
            "siteUrl": "http://www.pret.co.uk/",
            "updatedAt": "2024-04-13T19:35:21.166Z"
        },
        "jde-peet's": {
            "names": [
                "JDE Peet's",
                "JDE Peet's N.V.",
                "Jacobs Douwe Egberts",
                "JDEP"
            ],
            "comment": "JDE Peet's is an American-Dutch coffee company that had refused to end its operations in Russia [1]. ",
            "sources": {
                "1": "https://www.msn.com/en-us/money/companies/why-this-coffee-giant-is-staying-put-in-russia/ar-AA1frXiL"
            },
            "tags": [
                "coffee",
                "russia"
            ],
            "score": 40,
            "ownedBy": [
                "jab-holding-company"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/2/21/JDE_Peet%27s_box_logo.svg",
            "siteUrl": "https://www.jdepeets.com/",
            "updatedAt": "2024-04-13T19:35:21.166Z"
        },
        "tassimo": {
            "names": [
                "Tassimo"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "coffee"
            ],
            "score": 40,
            "ownedBy": [
                "jde-peet's"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/8/8d/Tassimo_logo.svg",
            "siteUrl": "https://www.tassimo.com/",
            "updatedAt": "2024-04-13T20:12:12.650Z"
        },
        "kenco": {
            "names": [
                "Kenco"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "coffee"
            ],
            "score": 40,
            "ownedBy": [
                "jde-peet's"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/d/de/Kenco_logo.webp",
            "siteUrl": "https://www.kenco.co.uk/",
            "updatedAt": "2024-04-13T20:12:12.650Z"
        },
        "subway": {
            "names": [
                "Subway",
                "Subway IP LLC"
            ],
            "comment": "Subway, a fast-food chain, has been found to have underpaid workers at several franchises across Australia, resulting in over $81,000 in unpaid wages for 167 current and former employees [1]. Subway has also been listed as an International Sponsor of War by Ukraine for continuing operations in Russia [2].",
            "sources": {
                "1": "https://web.archive.org/web/20191224111400/https://www.abc.net.au/news/2019-10-01/subway-employees-recover-unpaid-wages/11563342",
                "2": "https://nazk.gov.ua/uk/novyny/fastfud-yakyy-diysno-vbyvae-nazk-vneslo-subway-do-pereliku-mizhnarodnyh-sponsoriv-viyny/"
            },
            "tags": [
                "fast food",
                "bad working conditions",
                "russia"
            ],
            "score": 25,
            "ownedBy": [],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/5/5c/Subway_2016_logo.svg",
            "siteUrl": "https://www.subway.com/",
            "updatedAt": "2024-04-06T20:09:26.447Z"
        },
        "nationwide": {
            "names": [
                "Nationwide",
                "Nationwide Building Society"
            ],
            "comment": "Nationwide is a British building society owned by its members [1], who voted to contribute 1% of profits to charity [4]. Nationwide does not invest in fossil fuels [2], although hundreds of its employees have faced redundancy [3].",
            "sources": {
                "1": "https://www.nationwide.co.uk/-/assets/nationwidecouk/documents/current-accounts/resources/p4055-current-account-important-information.pdf?rev=5035ecd6acdb468aa67fe5b7e473bbe5#:~:text=Nationwide%20Building%20Society%20is%20a,member%20of%20Nationwide%20Building%20Society",
                "2": "https://www.nationwide.co.uk/about-us/responsible-business/reducing-environmental-harm/",
                "3": "https://www.bbc.co.uk/news/uk-england-wiltshire-64567941",
                "4": "https://www.nationwide.co.uk/about-us/building-a-better-society/"
            },
            "tags": [
                "bank",
                "buildingsociety"
            ],
            "score": 80,
            "ownedBy": [],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/en/2/2f/Nationwide_Building_Society_logo_2023.svg",
            "siteUrl": "https://www.nationwide.co.uk/",
            "updatedAt": "2024-01-08T15:23:39.205Z"
        },
        "natwest": {
            "names": [
                "NatWest",
                "Natwest",
                "National Westminster Bank",
                "NatWest Holdings Limited",
                "NatWest Holdings",
                "NatWest Group",
                "NatWest Group plc"
            ],
            "comment": "NatWest is a British bank that has invested $2.03 billion in six companies that manufacture nuclear weapons [1] and billions more in fossil fuels [2], has been fined for anti-money laundering failures [3], and executives have expressed indifference to the cost of living crisis [4]. NatWest's energy investments comprise 85% sustainable energy [5] and the bank is halting financing for oil and gas ventures in 2026 [6].",
            "sources": {
                "1": "https://www.thenational.scot/news/19257311.revealed-billions-invested-big-business-nuclear-arms/",
                "2": "https://www.theguardian.com/global/2023/feb/09/natwest-to-end-new-business-loans-for-oil-and-gas-extraction",
                "3": "https://www.fca.org.uk/news/press-releases/natwest-fined-264.8million-anti-money-laundering-failures",
                "4": "https://www.dailymail.co.uk/news/article-12930165/NatWest-Howard-Davies-banker-difficult-house-homes-property-ladder.html",
                "5": "https://bank.green/banks/natwest",
                "6": "https://www.theguardian.com/global/2023/feb/09/natwest-to-end-new-business-loans-for-oil-and-gas-extraction"
            },
            "tags": [
                "bank",
                "unsustainable",
                "nuclear"
            ],
            "score": 35,
            "ownedBy": [],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/en/d/de/Natwest_logo.svg",
            "siteUrl": "https://www.natwest.com/",
            "updatedAt": "2024-01-08T15:23:39.205Z"
        },
        "netflix": {
            "names": [
                "Netflix",
                "Netflix, Inc.",
                "NFLX"
            ],
            "comment": "Netflix is an American tech and entertainment company. Netflix has been accused of illegally retaining and using consumer data [1].",
            "sources": {
                "1": "https://www.bizjournals.com/sanjose/news/2013/03/25/netflix-settles-9-million-privacy.html"
            },
            "tags": [
                "tech",
                "entertainment",
                "tv",
                "movies",
                "antiprivacy"
            ],
            "score": 50,
            "ownedBy": [],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/0/08/Netflix_2015_logo.svg",
            "siteUrl": "https://www.netflix.com/",
            "updatedAt": "2024-01-08T15:23:39.205Z"
        },
        "meta": {
            "names": [
                "Meta",
                "Facebook",
                "Meta Platforms, Inc.",
                "TheFacebook, Inc.",
                "META"
            ],
            "comment": "Meta is an American tech company that has fought aginst privacy legistion [1][2], shared user data without consent which was used to sway elections [3], designed software to addict children [4][5] and polarise users in idealogical echo chambers [6][7], avoided billions in tax [8][9] and conducted mass suveillance on the public [10].",
            "sources": {
                "1": "https://web.archive.org/web/20190302143109/https://www.theguardian.com/technology/2019/mar/02/facebook-global-lobbying-campaign-against-data-privacy-laws-investment",
                "2": "https://www.politico.eu/article/inside-story-facebook-fight-against-european-regulation",
                "3": "https://web.archive.org/web/20210129123154/https://www.businessinsider.com/cambridge-analytica-whistleblower-christopher-wylie-facebook-data-2019-10",
                "4": "https://web.archive.org/web/20231127192915/https://www.theguardian.com/technology/2023/nov/27/meta-instagram-facebook-kids-addicted-lawsuit",
                "5": "https://web.archive.org/web/20230629034801/https://www.theguardian.com/technology/2021/oct/05/facebook-harms-children-damaging-democracy-claims-whistleblower",
                "6": "https://doi.org/10.1111%2Fnpqu.11519",
                "7": "https://doi.org/10.1111%2Fnpqu.11519",
                "8": "https://www.theguardian.com/technology/2012/dec/23/facebook-tax-profits-outside-us",
                "9": "https://www.bbc.co.uk/news/business-34504474",
                "10": "https://www.theguardian.com/world/2013/jun/06/us-tech-giants-nsa-data"
            },
            "tags": [
                "tech",
                "socialmedia",
                "antiprivacy",
                "taxavoidance",
                "radicalisation"
            ],
            "score": 10,
            "ownedBy": [],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/7/7b/Meta_Platforms_Inc._logo.svg",
            "siteUrl": "https://about.meta.com/",
            "updatedAt": "2024-01-07T20:47:32.197Z"
        },
        "fairy": {
            "names": [
                "Fairy"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "consumer goods"
            ],
            "score": 35,
            "ownedBy": [
                "procter-and-gamble"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/en/5/59/Fairy_logo.png",
            "siteUrl": "https://www.supersavvyme.co.uk/brands/fairy",
            "updatedAt": "2024-01-31T19:01:06.524Z"
        },
        "old-spice": {
            "names": [
                "Old Spice"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "consumer goods"
            ],
            "score": 35,
            "ownedBy": [
                "procter-and-gamble"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/en/9/94/Current_Old_Spice_Logo_2016.svg",
            "siteUrl": "https://oldspice.com/",
            "updatedAt": "2024-01-31T19:01:06.524Z"
        },
        "ariel": {
            "names": [
                "Ariel"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "consumer goods"
            ],
            "score": 35,
            "ownedBy": [
                "procter-and-gamble"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/en/3/37/Ariel_Logo.png",
            "siteUrl": "https://www.ariel.co.uk/",
            "updatedAt": "2024-01-31T19:01:06.524Z"
        },
        "herbal-essences": {
            "names": [
                "Herbal Essences"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "consumer goods"
            ],
            "score": 35,
            "ownedBy": [
                "procter-and-gamble"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/en/1/14/Herbal_Essences_Hair_Care.png",
            "siteUrl": "https://herbalessences.com/",
            "updatedAt": "2024-01-31T19:01:06.524Z"
        },
        "braun": {
            "names": [
                "Braun",
                "Braun GmbH"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "consumer goods"
            ],
            "score": 35,
            "ownedBy": [
                "procter-and-gamble"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/1/16/Braun_Logo.svg",
            "siteUrl": "https://www.braun.com/",
            "updatedAt": "2024-01-31T19:01:06.524Z"
        },
        "aussie": {
            "names": [
                "Aussie"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "consumer goods"
            ],
            "score": 35,
            "ownedBy": [
                "procter-and-gamble"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/en/4/48/Aussie_logo.png",
            "siteUrl": "https://www.aussie.com/",
            "updatedAt": "2024-01-31T19:01:06.524Z"
        },
        "vicks": {
            "names": [
                "Vicks"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "consumer goods"
            ],
            "score": 35,
            "ownedBy": [
                "procter-and-gamble"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/6/65/VicksLogo.png",
            "siteUrl": "https://vicks.com/",
            "updatedAt": "2024-01-31T19:01:06.524Z"
        },
        "tide": {
            "names": [
                "Tide"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "consumer goods"
            ],
            "score": 35,
            "ownedBy": [
                "procter-and-gamble"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/1/1e/Tide_Logo_RGB_2014.png",
            "siteUrl": "https://www.tide.com/",
            "updatedAt": "2024-01-31T19:01:06.524Z"
        },
        "pantene": {
            "names": [
                "Pantene"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "consumer goods"
            ],
            "score": 35,
            "ownedBy": [
                "procter-and-gamble"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/1/19/Pantene_Header_logo.png",
            "siteUrl": "",
            "updatedAt": "2024-01-31T19:01:06.524Z"
        },
        "pampers": {
            "names": [
                "Pampers"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "consumer goods"
            ],
            "score": 35,
            "ownedBy": [
                "procter-and-gamble"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/en/1/17/Pampers_logo.svg",
            "siteUrl": "https://www.pampers.com/",
            "updatedAt": "2024-01-31T19:01:06.524Z"
        },
        "oral-b": {
            "names": [
                "Oral-B"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "consumer goods"
            ],
            "score": 35,
            "ownedBy": [
                "procter-and-gamble"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/8/84/Oral-B_logo.svg",
            "siteUrl": "https://oralb.com/",
            "updatedAt": "2024-01-31T19:01:06.524Z"
        },
        "olay": {
            "names": [
                "Olay"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "consumer goods"
            ],
            "score": 35,
            "ownedBy": [
                "procter-and-gamble"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/5/57/Olay.png",
            "siteUrl": "https://www.olay.com/",
            "updatedAt": "2024-01-31T19:01:06.524Z"
        },
        "head-and-shoulders": {
            "names": [
                "Head & Shoulders",
                "Head and Shoulders"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "consumer goods"
            ],
            "score": 35,
            "ownedBy": [
                "procter-and-gamble"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/7/74/Head%26shoulders-Logo.svg",
            "siteUrl": "https://www.headandshoulders.com/",
            "updatedAt": "2024-01-31T19:01:06.524Z"
        },
        "gillette": {
            "names": [
                "Gillette",
                "The Gillette Company"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "consumer goods"
            ],
            "score": 35,
            "ownedBy": [
                "procter-and-gamble"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/f/fa/Gillette.svg",
            "siteUrl": "https://gillette.com/",
            "updatedAt": "2024-01-31T19:01:06.524Z"
        },
        "febreeze": {
            "names": [
                "Febreeze"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "consumer goods"
            ],
            "score": 35,
            "ownedBy": [
                "procter-and-gamble"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/7/72/Febreze_Logo.svg",
            "siteUrl": "https://www.febreze.com/",
            "updatedAt": "2024-01-31T19:01:06.524Z"
        },
        "monster-energy": {
            "names": [
                "Monster Energy",
                "Monster Beverage",
                "Monster Beverage Corporation",
                "MNST",
                "Monster"
            ],
            "comment": "Monster Energy is an American energy drinks manufacturer that has been accused of cultivating an abusive and discriminatory work environment, involving allegations of sexual harassment, bullying, and retaliation [1][2]. Their drinks may pose health risks, potentially masking alcohol’s effects and contributing to cardiac and psychiatric issues [3][4][5].",
            "sources": {
                "1": "https://www.huffpost.com/entry/monster-energy-lawsuits_n_5a6280c1e4b002283002ca27",
                "2": "https://www.huffpost.com/entry/monster-energy-brent-hamilton-out_n_5cd9c3a9e4b0615b08178297",
                "3": "https://www.racgp.org.au/download/documents/AFP/2011/March/201103pennay.pdf",
                "4": "https://doi.org/10.1016%2Fj.cjca.2014.12.019",
                "5": "https://doi.org/10.1016%2Fj.lpm.2014.07.029"
            },
            "tags": [
                "beverages",
                "bad working conditions",
                "sexual harassment"
            ],
            "score": 35,
            "ownedBy": [],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/en/e/e2/Monster_Beverage_logo.png",
            "siteUrl": "https://www.monsterenergy.com/en-gb/",
            "updatedAt": "2024-01-03T19:32:45.718Z"
        },
        "lego": {
            "names": [
                "LEGO",
                "The LEGO Group",
                "Lego"
            ],
            "comment": "The LEGO Group is a Danish toy manufacturer which is a major producer of plastic derived from crude oil [1], and has created Shell-branded [2] and gender-stereotypical [3] toys. LEGO has since produced items that promote gender equality [4] and LGBT+ rights [5], while being recognised as a beneficial toy for children's development [6][7].",
            "sources": {
                "1": "https://www.wired.co.uk/article/lego-haunted-by-its-own-plastic",
                "2": "https://www.theguardian.com/commentisfree/2014/aug/08/lego-child-play-big-business-marketing-toys-shell",
                "3": "https://web.archive.org/web/20150925050912/http://www.independent.co.uk/life-style/health-and-families/lego-told-off-by-7yearold-girl-for-promoting-gender-stereotypes-9104571.html",
                "4": "https://web.archive.org/web/20141127130849/http://www.bbc.co.uk/news/magazine-28660069",
                "5": "https://web.archive.org/web/20210722043242/https://www.lego.com/en-au/page/why-i-designed-everyone-is-awesome?icmp=LP-SHCC-Standard-Project_Rainbow_CC_Block_1-PR-MT-QAXDTRW46D",
                "6": "https://www.verywellhealth.com/lego-therapy-for-children-with-autism-4169865",
                "7": "https://www.theguardian.com/commentisfree/2022/apr/20/why-building-playtime-is-crucial-soothing"
            },
            "tags": [
                "toys",
                "plastic",
                "unsustainable"
            ],
            "score": 60,
            "ownedBy": [],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/2/24/LEGO_logo.svg",
            "siteUrl": "https://www.lego.com/",
            "updatedAt": "2024-01-06T14:46:15.790Z"
        },
        "co-op": {
            "names": [
                "Co-op",
                "Co-op Food",
                "The Co-operative Group",
                "Co-operative Wholesale Society"
            ],
            "comment": "Co-op is a British supermarket and consumer co-operative which has championed the introduction of fairtrade [1], pushed for climate justice [2][3][4], innovated in accessible food labelling for disabled people [5], and invested millions of pounds of its profits back into the communities it serves [6]. Co-op boycotted exports from Israei settlements in Palestine since 2012 [7] and has a celebrated stance on avoiding pesticides [8], sustainable sourcing of palm oil [9], and animal welfare [6].",
            "sources": {
                "1": "http://web.archive.org/web/20190708142737/http://www.fairtrade.org.uk/en/buying-fairtrade/tea/cooperative",
                "2": "https://web.archive.org/web/20150622200646/http://www.co-operative.coop/our-ethics/our-plan/protecting-the-environment/clean-energy-revolution/",
                "3": "https://web.archive.org/web/20160303235606/http://www.co-operative.coop/upload/ToxicFuels/docs/businessandngo_openletter_mandatoryghgreporting.pdf",
                "4": "https://web.archive.org/web/20150521053740/http://www.co-operative.coop/upload/ToxicFuels/docs/toxic_fuels_report_web.pdf",
                "5": "http://www.marketingmagazine.co.uk/article/216426/co-op-extend-braille-labelling-food-range",
                "6": "https://web.archive.org/web/20160304060542/https://www.co-operative.coop/Corporate/PDFs/Shopping%20With%20Attitude%20Report.pdf",
                "7": "https://www.theguardian.com/world/2012/apr/29/co-op-israel-west-bank-boycott",
                "8": "http://www.foe.co.uk/resource/press_releases/0702coop",
                "9": "https://web.archive.org/web/20151211105906/http://news.co-operativefood.co.uk/latest-news/the-co-operative-voted-best-for-palm-oil-use.html"
            },
            "tags": [
                "supermarket",
                "retail",
                "propalestine"
            ],
            "score": 95,
            "ownedBy": [],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/c/c5/The_Co-Operative_clover_leaf_logo.svg",
            "siteUrl": "https://www.coop.co.uk/",
            "updatedAt": "2024-03-30T16:58:30.024Z"
        },
        "londis": {
            "names": [
                "Londis"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "supermarket",
                "retail"
            ],
            "score": 40,
            "ownedBy": [
                "tesco"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/en/c/cc/LondisLogo.svg",
            "siteUrl": "http://www.londis.co.uk/",
            "updatedAt": "2024-03-30T21:00:09.606Z"
        },
        "premier": {
            "names": [
                "Premier"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "supermarket",
                "retail"
            ],
            "score": 40,
            "ownedBy": [
                "tesco"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/en/6/65/PremierStoresLogo.svg",
            "siteUrl": "http://premier-stores.co.uk/",
            "updatedAt": "2024-03-30T21:00:09.606Z"
        },
        "sainsbury's": {
            "names": [
                "Sainsbury's",
                "J Sainsbury plc",
                "SBRY"
            ],
            "comment": "Sainsbury's has faced criticism for declining to pay contracted workers a living wage and for excessive executive compensation, with its CEO receiving £3.8m while rejecting calls to ensure all workers earn a living wage [1]. Investigations have uncovered allegations of forced labor, excessive overtime, abuse, and poor working conditions in factories producing clothing for Sainsbury's [2], and the company threatened legal action against a small business named Singhsbury's [3].",
            "sources": {
                "1": "https://www.theguardian.com/business/2022/jun/06/sainsburys-boss-pay-triples-to-38m-as-firm-rejects-living-wage-calls",
                "2": "https://www.dailymail.co.uk/news/article-8956691/Exploited-workers-Indian-factories-supplying-Tesco-Sainsburys-M-S-Ralph-Lauren.html",
                "3": "https://web.archive.org/web/20180109064054/https://www.theguardian.com/business/2017/jun/27/corner-shop-becomes-morrisinghs-after-sainsburys-legal-threat"
            },
            "tags": [
                "supermarket",
                "retail",
                "unfair pay",
                "bad working conditions",
                "anticompetitive"
            ],
            "score": 40,
            "ownedBy": [],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/d/d7/Sainsbury%27s_Logo.svg",
            "siteUrl": "http://www.about.sainsburys.co.uk/",
            "updatedAt": "2024-03-30T21:00:09.606Z"
        },
        "argos": {
            "names": [
                "Argos"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "retail"
            ],
            "score": 40,
            "ownedBy": [
                "sainsbury's"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/5/56/Argos_logo.svg",
            "siteUrl": "https://www.argos.co.uk/",
            "updatedAt": "2024-03-30T21:00:09.606Z"
        },
        "ikea": {
            "names": [
                "IKEA",
                "Inter IKEA Systems B.V.",
                "Ikea"
            ],
            "comment": "Ikea is a Swedish furniture manufacturer that has sourced timber from illegal logging [1][2], and whose furniture designs have led to the deaths of children [3]. Ikea France was found illegally spying on employees [4], Ikea stores have been built over historic buildings and tombs [5][6], and the founder of Ikea was involved with Nazi groups [7].",
            "sources": {
                "1": "https://www.theguardian.com/environment/2020/jun/23/timber-unsustainable-logging-allegedly-sold-eu-ethical",
                "2": "https://www.independent.co.uk/climate-change/news/ikea-children-furniture-illegal-wood-b1884276.html",
                "3": "https://www.nbcnews.com/business/consumer/ikea-reaches-50-million-wrongful-death-settlement-over-falling-dressers-n698876",
                "4": "https://web.archive.org/web/20210616033812/https://www.theguardian.com/business/2021/jun/15/ikea-fined-1m-by-french-court-for-spying-on-staff",
                "5": "https://web.archive.org/web/20070712175632/http://www.nydailynews.com/boroughs/2006/11/22/2006-11-22_suit_aims_to_make_an_ikea_lot_history.html",
                "6": "https://www.reuters.com/article/us-china-tombs/workers-destroy-ancient-chinese-tombs-media-idUSPEK34443620070703/",
                "7": "https://www.nytimes.com/1994/11/09/business/ikea-founder-tells-of-past.html"
            },
            "tags": [
                "furniture",
                "bad working conditions",
                "antiprivacy",
                "deforestation"
            ],
            "score": 15,
            "ownedBy": [],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/c/c5/Ikea_logo.svg",
            "siteUrl": "https://about.ikea.com/",
            "updatedAt": "2024-04-01T18:19:21.032Z"
        },
        "inditex": {
            "names": [
                "Inditex",
                "Industria de Diseño Textil, S.A."
            ],
            "comment": "Inditex is a Spanish clothing company that has used forced Uyghur labour in its suply chain [1], and slave labour in its Brazilian sweatshops [2], and has stolen designs from independent creators [3][4]. ",
            "sources": {
                "1": "https://www.shu.ac.uk/-/media/home/research/helena-kennedy-centre/projects/eu-apparel/eu-tailoring-responsibility-february-24.pdf",
                "2": "https://www.forbes.com/sites/andersonantunes/2011/08/17/zara-accused-of-alleged-slave-labor-in-brazil/",
                "3": "https://www.boredpanda.com/zara-stealing-designs-copying-independent-artists-tuesday-bassen/",
                "4": "https://web.archive.org/web/20200616103810/http://www.flanderstoday.eu/living/zara-home-co-opts-design-flemish-artisan"
            },
            "tags": [
                "clothing",
                "retail",
                "forced labour",
                "anticompetitive",
                "unsustainable"
            ],
            "score": 20,
            "ownedBy": [],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/3/35/Inditex_logo_black.svg",
            "siteUrl": "https://www.inditex.com/",
            "updatedAt": "2024-04-01T18:19:21.032Z"
        },
        "zara": {
            "names": [
                "ZARA",
                "Zara"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "clothing"
            ],
            "score": 20,
            "ownedBy": [
                "inditex"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/f/fd/Zara_Logo.svg",
            "siteUrl": "https://www.zara.com/",
            "updatedAt": "2024-04-01T18:19:21.032Z"
        },
        "bershka": {
            "names": [
                "Bershka"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "clothing",
                "retail"
            ],
            "score": 20,
            "ownedBy": [
                "inditex"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/7/75/Bershka_logo.svg",
            "siteUrl": "https://www.bershka.com/",
            "updatedAt": "2024-04-01T18:19:21.032Z"
        },
        "massimo-dutti": {
            "names": [
                "Massimo Dutti"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "clothing",
                "retail"
            ],
            "score": 20,
            "ownedBy": [
                "inditex"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/7/74/Massimo_Dutti.png",
            "siteUrl": "http://www.massimodutti.com/",
            "updatedAt": "2024-04-01T18:19:21.032Z"
        },
        "stradivarius": {
            "names": [
                "Stradivarius"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "clothing",
                "retail"
            ],
            "score": 20,
            "ownedBy": [
                "inditex"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/9/95/Stradivarius_logo.svg",
            "siteUrl": "http://www.stradivarius.com/",
            "updatedAt": "2024-04-01T18:19:21.032Z"
        },
        "pullandbear": {
            "names": [
                "Pull&Bear",
                "Pull and Bear",
                "Pull & Bear"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "clothing",
                "retail"
            ],
            "score": 20,
            "ownedBy": [
                "inditex"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/7/72/Pull%26Bear_logo.svg",
            "siteUrl": "http://www.pullandbear.com/",
            "updatedAt": "2024-04-01T18:19:21.032Z"
        },
        "handm": {
            "names": [
                "H&M",
                "H & M Hennes & Mauritz AB",
                "H&M Group"
            ],
            "comment": "H&M is a multinational fast fashion company that has been accused of greenwashing [1] and has faced criticism for its operations in Israel [2]. H&M has made racist tweets [3] and used imagery widely seen as racist [4], and is being investigated for racist practices in Sweden [5].",
            "sources": {
                "1": "https://www.just-style.com/news/hm-under-fire-in-us-over-sustainable-material-claims/",
                "2": "http://www.inminds.co.uk/article.php?id=10310",
                "3": "https://newsone.com/3247456/hm-apologizes-racist-tweets/",
                "4": "https://www.cbsnews.com/news/h-m-the-weeknd-coolest-monkey-in-the-jungle-racist-hoodie/",
                "5": "https://www.reuters.com/article/h-m-discrimination-idUSKBN28J2JF"
            },
            "tags": [
                "clothing",
                "retail",
                "fast fashion",
                "discrimination",
                "greenwashing",
                "unsustainable"
            ],
            "score": 30,
            "ownedBy": [],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/5/53/H%26M-Logo.svg",
            "siteUrl": "https://www.hm.com/",
            "updatedAt": "2024-04-03T19:57:12.627Z"
        },
        "cos": {
            "names": [
                "COS"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "clothing",
                "retail",
                "fast fashion"
            ],
            "score": 30,
            "ownedBy": [
                "handm"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/6/6e/COS_Logo.png",
            "siteUrl": "https://www.cos.com/",
            "updatedAt": "2024-04-03T21:14:45.611Z"
        },
        "arket": {
            "names": [
                "Arket"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "clothing",
                "retail",
                "fast fashion"
            ],
            "score": 30,
            "ownedBy": [
                "handm"
            ],
            "logoUrl": "https://image.hm.com/content/dam/hm-external-brands-brandpage-images/inspirational-page/logo/fashion/ARKET.svg",
            "siteUrl": "https://www.arket.com/",
            "updatedAt": "2024-04-03T21:14:45.611Z"
        },
        "mango": {
            "names": [
                "Mango",
                "Punto Fa, S.L."
            ],
            "comment": "Mango, a Spanish fashion brand, has been accused of copying a bag design from a small creator [1], and has been criticized for promoting unhealthy body image by labeling sizes 40-52 as a separate \"Violeta\" line for \"curvy\" women [2]. Mango has refused to join the compensation efforts for victims of the Rana Plaza factory collapse that killed over 1,100 workers in its supply chain [3][4].",
            "sources": {
                "1": "http://www.lexpress.fr/styles/minute-mode/mango-accuse-de-contrefacon-par-velvetine_1002694.html",
                "2": "https://www.abc.es/sociedad/20140117/abci-mango-firmas-retirada-violeta-201401162008.html",
                "3": "https://www.independent.co.uk/life-style/fashion/rana-plaza-factory-disaster-anniversary-what-happened-fashion-a9478126.html",
                "4": "https://web.archive.org/web/20160325222511/http://www.dhakatribune.com/law-amp-rights/2013/oct/24/buyers-make-no-compensation-pledge-yet-rana-plaza-victims"
            },
            "tags": [
                "clothing",
                "retail",
                "fast fashion",
                "bad working conditions",
                "endangerment"
            ],
            "score": 25,
            "ownedBy": [],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/1/1c/Logo_of_Mango_%28new%29.svg",
            "siteUrl": "http://shop.mango.com/",
            "updatedAt": "2024-04-03T20:02:08.965Z"
        },
        "marks-and-spencer": {
            "names": [
                "Marks & Spencer",
                "Marks and Spencer plc",
                "M and S",
                "M&S",
                "MKS"
            ],
            "comment": "Marks & Spencer's supply chain has been criticized for labor abuses like forced overtime, denial of breaks, and underage workers at factories in Cambodia and elsewhere [1], as well as underpaying workers on berry farms supplying its groceries [2]. The company has faced protests over stocking Israeli goods [3] and an Oxfam report found poverty wages, discrimination, and health issues in its food and footwear supply chains despite audits clearing the company [4].",
            "sources": {
                "1": "https://www.theguardian.com/world/2015/mar/12/marks-and-spencer-gap-h-and-m-adidas-cambodian-factories-workers-rights",
                "2": "https://www.grocerygazette.co.uk/2022/01/25/uk-supermarkets-linked-to-underpaid-farm-workers/",
                "3": "https://www.jewishnews.co.uk/sparks-fly-marks-protesters-clash-israel/",
                "4": "https://www.retailgazette.co.uk/blog/2021/01/ms-under-scrutiny-over-supply-chain-worker-concerns/"
            },
            "tags": [
                "supermarket",
                "retail",
                "child labour",
                "discrimination",
                "bad working conditions"
            ],
            "score": 40,
            "ownedBy": [],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/7/79/M%26S_logo.svg",
            "siteUrl": "https://marksandspencer.com/",
            "updatedAt": "2024-03-30T21:00:09.606Z"
        },
        "iceland": {
            "names": [
                "Iceland",
                "Iceland Foods Limited",
                "Iceland Frozen Foods plc"
            ],
            "comment": "Iceland is a British supermarket that lied about removing palm oil from its products [1], and discriminated against customer groups [2][3]. Iceland has a poor animal welfare record [4] and the worst sustainability rating amongst supermarkets [5].",
            "sources": {
                "1": "https://www.bbc.co.uk/news/uk-46984349",
                "2": "https://www.irishtimes.com/ireland/2022/10/12/iceland-ordered-to-pay-12000-to-group-of-travellers-ejected-from-supermarket/",
                "3": "https://www.bbc.co.uk/news/uk-northern-ireland-21508758",
                "4": "https://www.independent.co.uk/news/uk/home-news/pigs-pork-animal-cruelty-iceland-asda-b1722592.html",
                "5": "https://www.thegrocer.co.uk/sustainability-and-environment/in-graphs-why-iceland-ranked-bottom-for-supermarket-sustainability-by-which/664405.article"
            },
            "tags": [
                "supermarket",
                "retail",
                "discrimination",
                "unsustainable",
                "animal abuse"
            ],
            "score": 35,
            "ownedBy": [],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/f/fe/Iceland_with_the_The_Food_Warehouse_logo.png",
            "siteUrl": "https://iceland.co.uk/",
            "updatedAt": "2024-03-30T20:06:46.980Z"
        },
        "amazon": {
            "names": [
                "Amazon",
                "Amazon.com, Inc.",
                "AMZN"
            ],
            "comment": "Amazon is an American online retail and tech conglomerate which has funded climate change denial groups [1], issued propaganda to local news stations [2][3], sent thousands of items of unused stock to landfill [4][5], avoided billions in tax [6], violated privacy laws [7][8], discriminated against black and female employees [9][10], mistreated workers [11][12], aggressively opposed unionisation [13][14][15] and engaged in anticompetitive practices [16][17][18].",
            "sources": {
                "1": "https://web.archive.org/web/20190710185008/https://www.nytimes.com/2019/07/10/climate/nyt-climate-newsletter-cei.html",
                "2": "https://web.archive.org/web/20200604102522/https://news.avclub.com/oh-cool-amazon-is-dictating-the-local-news-now-1843705714",
                "3": "https://web.archive.org/web/20200528144830/https://www.vice.com/en_uk/article/4ayvwb/local-news-stations-run-propaganda-segments-scripted-and-produced-by-amazon",
                "4": "https://web.archive.org/web/20210621235131/https://www.itv.com/news/2021-06-21/amazon-destroying-millions-of-items-of-unsold-stock-in-one-of-its-uk-warehouses-every-year-itv-news-investigation-finds",
                "5": "https://web.archive.org/web/20210621204203/https://www.heraldscotland.com/business_hq/19387292.amazon-dunfermline-company-denies-denies-claim-thousands-items-sent-landfill/",
                "6": "https://www.theguardian.com/business/2019/dec/02/new-study-deems-amazon-worst-for-aggressive-tax-avoidance",
                "7": "https://web.archive.org/web/20210808141701/https://www.bloomberg.com/news/articles/2021-07-30/amazon-given-record-888-million-eu-fine-for-data-privacy-breach",
                "8": "https://www.bbc.com/news/technology-65772154",
                "9": "https://web.archive.org/web/20220217123550/https://www.king5.com/article/news/community/facing-race/current-and-former-black-amazon-employees-claim-racial-discrimination/281-8e91902f-c8e0-41c0-8809-e4413a58f859",
                "10": "https://web.archive.org/web/20220221113851/https://www.vox.com/recode/2021/2/26/22297554/amazon-race-black-diversity-inclusion",
                "11": "https://www.businessinsider.com/brutal-conditions-in-amazons-warehouses-2013-8",
                "12": "https://www.thedailybeast.com/amazon-the-shocking-911-calls-from-inside-its-warehouses",
                "13": "https://gizmodo.com/amazons-aggressive-anti-union-tactics-revealed-in-leake-1829305201",
                "14": "https://web.archive.org/web/20201031231943/https://www.theregister.com/2020/04/02/amazon_coronavirus_firing/",
                "15": "https://web.archive.org/web/20201216204639/https://www.businessinsider.com/whole-foods-tracks-unionization-risk-with-heat-map-2020-1?r=US&IR=T",
                "16": "https://web.archive.org/web/20080607093312/http://www.publishingnews.co.uk/pn/pno-news-display.asp?K=e2008040310393759&TAG=&CID=&PGE=&sg9t=cbd2a5be26893c7c10ff5b7ccc309f6f",
                "17": "https://archive.today/20191219195205/https://www.nytimes.com/2019/12/19/technology/amazon-sellers.html",
                "18": "https://www.npr.org/2020/10/06/920882893/how-are-apple-amazon-facebook-google-monopolies-house-report-counts-the-ways"
            },
            "tags": [
                "retail",
                "ecommerce",
                "tech",
                "unsustainable",
                "discrimination",
                "bad working conditions",
                "anticompetetive",
                "antiunion"
            ],
            "score": 5,
            "ownedBy": [],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/a/a9/Amazon_logo.svg",
            "siteUrl": "https://amazon.com",
            "updatedAt": "2024-01-07T15:59:06.010Z"
        },
        "fedex": {
            "names": [
                "FedEx",
                "FedEx Corporation",
                "Federal Express Corporation",
                "FDX Corporation",
                "Federal Express",
                "FDX"
            ],
            "comment": "Fedex is an American shipping and logistics company that spends tens of millions of dollars lobbying the federal government [1], benefitted from tax cuts without subsequently increasing investment as promised [2], distributed drugs without prescriptions [3], and pressured employees into working through the pandemic even if they were sick [4]. Fedex has made significant emissions savings through fuel conservation programs [5] but their 2021 annual report still states that they may struggle to reach their climate targets [6].",
            "sources": {
                "1": "https://web.archive.org/web/20210308191338/https://www.opensecrets.org/federal-lobbying/clients/summary?cycle=2018&id=D000000089",
                "2": "https://www.nytimes.com/2019/11/17/business/how-fedex-cut-its-tax-bill-to-0.html",
                "3": "https://web.archive.org/web/20141120015938/http://www.justice.gov/usao/can/news/2014/2014_07_17_fedex.indicted.press.html",
                "4": "https://www.nytimes.com/2020/03/21/business/coronavirus-ups-fedex-xpo-workers.html",
                "5": "https://web.archive.org/web/20221021092857/https://www.uschamberfoundation.org/blog/post/fedex-fuel-sense-every-drop-counts/31620",
                "6": "https://web.archive.org/web/20221018015119/https://investors.fedex.com/financial-information/sec-filings/default.aspx"
            },
            "tags": [
                "logistics",
                "shipping",
                "ecommerce",
                "bad working conditions",
                "endangerment"
            ],
            "score": 45,
            "ownedBy": [],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/b/b9/FedEx_Corporation_-_2016_Logo.svg",
            "siteUrl": "https://www.fedex.com/",
            "updatedAt": "2024-02-11T17:53:17.979Z"
        },
        "vodafone": {
            "names": [
                "Vodafone",
                "Vodafone Group plc",
                "VOD",
                "Racal Telecom",
                "Voda-Racal Telecom"
            ],
            "comment": "Vodafone, a telecommunications company, faces criticism for tax avoidance through Luxembourg subsidiaries [1] and fines for breaching consumer protection rules, including mishandling complaints [2]. Moreover, Vodafone's involvement in government surveillance through secret wires on its networks sparks privacy concerns [3], while allegations of overcharging customers after contract ends have emerged [4].",
            "sources": {
                "1": "https://web.archive.org/web/20101027221207/http://www.private-eye.co.uk/sections.php?section_link=in_the_back&issue=1273",
                "2": "https://www.bbc.co.uk/news/business-37772118",
                "3": "https://www.theguardian.com/business/2014/jun/06/vodafone-reveals-secret-wires-allowing-state-surveillance",
                "4": "https://web.archive.org/web/20190301074847/https://www.citizensadvice.org.uk/cymraeg/about-us/how-citizens-advice-works/media/press-releases/mobile-phone-networks-overcharging-loyal-customers-by-up-to-38-a-month/"
            },
            "tags": [
                "telecommunications",
                "broadband",
                "ISP",
                "antiprivacy",
                "tax avoidance"
            ],
            "score": 38,
            "ownedBy": [],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/en/c/cc/Vodafone_2017_logo.svg",
            "siteUrl": "https://vodafone.com/",
            "updatedAt": "2024-02-11T17:22:19.768Z"
        },
        "salesforce": {
            "names": [
                "Salesforce",
                "Salesforce, Inc.",
                "CRM",
                "Salesforce.com, Inc.",
                "Salesforce.com"
            ],
            "comment": "Salesforce, a tech company facing legal scrutiny for aiding Backpage in sex trafficking [1], has been highlighted for its tax avoidance strategies, with findings revealing it paid minimal federal taxes in 2018 [2]. Additionally, controversy surrounds its contracts with Customs and Border Protection, leading to the rejection of a donation by a legal aid group for immigrants and refugees [3], and allegations of inadequate accommodations for employees with developmental disorders in Japan [4].",
            "sources": {
                "1": "https://www.houstonchronicle.com/news/houston-texas/crime/article/Tech-giant-Salesforce-to-stand-trial-for-creating-16050424.php",
                "2": "https://www.cnbc.com/2019/12/16/these-91-fortune-500-companies-didnt-pay-federal-taxes-in-2018.html",
                "3": "https://www.theguardian.com/us-news/2018/jul/19/salesforce-raices-immigration-customs-border-protection",
                "4": "https://www.bengo4.com/c_5/n_13323"
            },
            "tags": [
                "tech",
                "tracking",
                "CRM",
                "tax avoidance"
            ],
            "score": 40,
            "ownedBy": [],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/f/f9/Salesforce.com_logo.svg",
            "siteUrl": "https://salesforce.com/",
            "updatedAt": "2024-02-11T16:20:10.322Z"
        },
        "tesco": {
            "names": [
                "Tesco",
                "Tesco plc",
                "TSCO",
                "Tesco Stores"
            ],
            "comment": "Tesco is a British retailer which appropriated a UNICEF slogan for advertising purposes [1] and engaged in price fixing [2], and its supply chain has included animal cruelty [3][4], slave labour [5], and deforestation of the Amazon [6]. Tesco has provided millions of pounds in educational equipment to schools [7], which is a fraction of the amount it has avoided in tax [8].",
            "sources": {
                "1": "https://web.archive.org/web/20110516024843/http://www.irishtimes.com/newspaper/ireland/2009/0725/1224251305112.html",
                "2": "https://web.archive.org/web/20160923155033/https://www.theguardian.com/business/2011/aug/10/tesco-attacks-oft-price-fixing-fine",
                "3": "https://web.archive.org/web/20221212130122/https://www.independent.co.uk/news/uk/home-news/rspca-farm-pigs-animal-cruelty-b2242436.html",
                "4": "https://web.archive.org/web/20221213163839/https://www.bbc.com/news/uk-england-lincolnshire-48745253",
                "5": "https://web.archive.org/web/20140611032723/http://www.theguardian.com/global-development/2014/jun/10/supermarket-prawns-thailand-produced-slave-labour",
                "6": "https://web.archive.org/web/20210828175312/https://leftfootforward.org/2021/08/greenpeace-activists-protest-tesco-as-meat-supplier-linked-to-amazon-forest-fires/",
                "7": "https://tescostrongerstarts.org.uk/5m-for-schools/",
                "8": "https://www.taxresearch.org.uk/Blog/2008/06/01/tescos-the-zug-deal-is-tax-avoidance/"
            },
            "tags": [
                "retail",
                "supermarket",
                "tax avoidance",
                "anticompetetive",
                "deforestation",
                "animal abuse",
                "forced labour"
            ],
            "score": 30,
            "ownedBy": [],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/en/b/b0/Tesco_Logo.svg",
            "siteUrl": "https://www.tescoplc.com/",
            "updatedAt": "2024-01-07T10:56:47.816Z"
        },
        "bmw": {
            "names": [
                "BMW",
                "Bayerische Motoren Werke",
                "Bayerische Flugzeugwerke"
            ],
            "comment": "BMW is a German car maker that lied about its vehicles' emissions [1] and sourced cobalt from environmentally harmful sources [2].",
            "sources": {
                "1": "https://www.theguardian.com/environment/2021/jul/08/eu-fines-vw-and-bmw-750m-for-colluding-with-daimler-on-fumes",
                "2": "https://www.msn.com/en-us/news/world/is-bmw-embroiled-in-controversy-due-to-a-cobalt-supplier-in-morocco-an-investigation-is-in-progress/ar-AA1jWjYs"
            },
            "tags": [
                "cars",
                "unsustainable",
                "emmissions cheating"
            ],
            "score": 36,
            "ownedBy": [],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/f/f4/BMW_logo_%28gray%29.svg",
            "siteUrl": "https://www.bmw.com/en/index.html",
            "updatedAt": "2024-03-10T18:56:53.382Z"
        },
        "wetherspoons": {
            "names": [
                "Wetherspoons",
                "J D Wetherspoon",
                "Wetherspoon",
                "J D Wetherspoon plc",
                "Spoons",
                "JDW"
            ],
            "comment": "Wetherspoons is a UK pub chain known for excellent value for money [1].",
            "sources": {
                "1": "http://web.archive.org/web/20240105044821/https://www.thesun.co.uk/fabulous/25250928/wetherspoons-sale-january-food-drinks/"
            },
            "tags": [
                "restaurant",
                "beverages",
                "food"
            ],
            "score": 70,
            "ownedBy": [],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/9/93/Wetherspoon_logo.svg",
            "siteUrl": "https://jdwetherspoon.com/",
            "updatedAt": "2024-04-05T20:00:17.127Z"
        },
        "rolls-royce": {
            "names": [
                "Rolls-Royce"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "cars"
            ],
            "score": 36,
            "ownedBy": [
                "bmw"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/a/a2/Rolls_royce_holdings_logo.svg",
            "siteUrl": "https://www.rolls-royce.com/",
            "updatedAt": "2024-03-10T18:56:53.382Z"
        },
        "mini": {
            "names": [
                "Mini"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "cars"
            ],
            "score": 36,
            "ownedBy": [
                "bmw"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/e/e9/MINI_logo.svg",
            "siteUrl": "http://mini.com/",
            "updatedAt": "2024-03-10T18:56:53.382Z"
        },
        "whole-foods": {
            "names": [
                "Whole Foods",
                "Whole Foods Market",
                "Whole Foods Market, Inc."
            ],
            "comment": "Whole Foods is an American supermarket that has engaged in aggressive anti-union practices [1][2] and overcharged customers [3].",
            "sources": {
                "1": "https://medium.com/@MatthewTHunt/whole-foods-hired-far-right-wing-activist-as-union-buster-dd46bee7c854",
                "2": "https://web.archive.org/web/20200707235819/https://www.businessinsider.com/whole-foods-tracks-unionization-risk-with-heat-map-2020-1",
                "3": "https://web.archive.org/web/20171207052136/http://www.care2.com/greenliving/whole-foods-caught-overcharging-in-ca.html"
            },
            "tags": [
                "food",
                "supermarket",
                "antiunion"
            ],
            "score": 5,
            "ownedBy": [
                "amazon"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/a/a2/Whole_Foods_Market_201x_logo.svg",
            "siteUrl": "https://www.wholefoodsmarket.com/",
            "updatedAt": "2024-03-10T18:56:53.382Z"
        },
        "google": {
            "names": [
                "Google",
                "Google LLC",
                "Alphabet"
            ],
            "comment": "Google is an American tech conglomerate that has conducted mass surveillance on the public [1][2][3], enacted anti-union schemes [4], engaged in monopolistic and anticompetitive practices [5][6][7], and avoided billions in tax [8][9][10].",
            "sources": {
                "1": "https://www.theverge.com/2022/2/5/22918487/fbi-geofence-seattle-blm-protest-police-guild-attack",
                "2": "https://web.archive.org/web/20170320171345/http://www.bbc.com/news/world-us-canada-23123964",
                "3": "https://web.archive.org/web/20060818114650/http://www.guardian.co.uk/world/2013/jun/06/us-tech-giants-nsa-data",
                "4": "https://www.vice.com/en/article/v7d7j9/google-had-secret-project-to-convince-employees-that-unions-suck",
                "5": "https://www.bbc.com/news/technology-40406542",
                "6": "https://www.justice.gov/opa/pr/justice-department-sues-google-monopolizing-digital-advertising-technologies",
                "7": "https://www.opensecrets.org/news/2023/10/google-ramped-up-federal-lobbying-ahead-of-doj-antitrust-showdown/",
                "8": "https://static.financieel-management.nl/documents/16690/EU-Tax-Revenue-Loss-from-Google-and-Facebook.pdf",
                "9": "https://www.theguardian.com/technology/2013/may/16/google-told-by-mp-you-do-do-evil",
                "10": "https://www.independent.co.uk/news/uk/home-news/google-boss-im-very-proud-of-our-tax-avoidance-scheme-8411974.html"
            },
            "tags": [
                "tech",
                "ai",
                "antiprivacy",
                "antiunion",
                "anticompetetive",
                "tax avoidance"
            ],
            "score": 20,
            "ownedBy": [],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/2/2f/Google_2015_logo.svg",
            "siteUrl": "https://about.google/",
            "updatedAt": "2024-01-07T10:56:47.816Z"
        },
        "sony": {
            "names": [
                "Sony",
                "Sony Group Corporation",
                "Sony Corporation",
                "SONY"
            ],
            "comment": "Sony Group Corporation is a Japanese technology conglomerate that has engaged in price fixing [1], conducted surveillance on environmental activists [2], and created invasive DRM software [3]. Sony's runs a recycling program that takes back and recycles any of their products at no cost to the consumer [4].",
            "sources": {
                "1": "https://web.archive.org/web/20221123183738/https://www.reuters.com/article/tech-eu-video-fines-dc-idUKBFA00056020071120",
                "2": "https://web.archive.org/web/20201024125232/https://www.motherjones.com/environment/2000/09/sonys-pr-war-activists/",
                "3": "https://blogs.technet.com/b/markrussinovich/archive/2005/10/31/sony-rootkits-and-digital-rights-management-gone-too-far.aspx",
                "4": "https://www.sony.com/en_us/SCA/social-responsibility/environment.html"
            },
            "tags": [
                "tech",
                "videogames",
                "movies",
                "electronics",
                "antiprivacy",
                "anticompetetive"
            ],
            "score": 50,
            "ownedBy": [],
            "siteUrl": "https://www.sony.com/",
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/c/ca/Sony_logo.svg",
            "updatedAt": "2024-01-07T10:56:47.816Z"
        },
        "john-lewis": {
            "names": [
                "John Lewis",
                "John Lewis & Partners",
                "John Lewis Partnership plc",
                "JLP"
            ],
            "comment": "John Lewis is a British retailer which violated minimum wage regulations, underpaying around 20,000 workers by approximately £941,356 between 2011 and 2018 [1].",
            "sources": {
                "1": "https://www.reuters.com/world/uk/uk-names-john-lewis-others-breaking-minimum-wage-law-2021-08-04/"
            },
            "tags": [
                "retail",
                "supermarket",
                "poorworkingconditions"
            ],
            "score": 45,
            "ownedBy": [],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/en/a/a4/John_Lewis_%26_Partners_logo.svg",
            "siteUrl": "https://www.johnlewispartnership.co.uk/",
            "updatedAt": "2024-01-06T15:15:10.705Z"
        },
        "dell": {
            "names": [
                "Dell",
                "Dell Inc.",
                "DELL",
                "Dell Computer Corporation",
                "Dell Technologies, Inc.",
                "Dell Technologies"
            ],
            "comment": "Dell offers convenient trade-in and recycling solutions to help protect the environment by reusing precious materials to make new products [1]. Journalists investigating Dell have uncovered evidence of technical flaws and unethical business practices, including the use of low-quality components and deceptive sales tactics [2].",
            "sources": {
                "1": "https://web.archive.org/web/20230425034626/https://www.dell.com/en-us/dt/corporate/social-impact/advancing-sustainability/how-to-recycle.htm#/",
                "2": "https://www.fastcompany.com/1665003/dell-lawsuit-highlights-broken-pcs-dirty-business-dealings"
            },
            "tags": [
                "tech"
            ],
            "score": 65,
            "ownedBy": [],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/1/18/Dell_logo_2016.svg",
            "siteUrl": "https://www.dell.com/",
            "updatedAt": "2024-01-17T23:57:50.055Z"
        },
        "bosch": {
            "names": [
                "Bosch",
                "Robert Bosch GmbH",
                "BOSCH"
            ],
            "comment": "Bosch was fined €90 million for its role in the Dieselgate scandal, delivering around 17 million devices with illegal software causing higher nitrogen oxide emissions [1]. The Robert Bosch Stiftung is a charitable foundation associated with the company focusing on health, education, global issues, and interdisciplinary exchange to promote a sustainable future [2].",
            "sources": {
                "1": "https://www.dw.com/en/bosch-pays-90-million-euro-fine-over-diesel-scandal/a-48843405",
                "2": "https://www.bosch-stiftung.de/en/about-robert-bosch-stiftung"
            },
            "tags": [
                "engineering",
                "tech",
                "unsustainable"
            ],
            "score": 40,
            "ownedBy": [],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/1/16/Bosch-logo.svg",
            "siteUrl": "https://www.bosch.com/",
            "updatedAt": "2024-01-17T19:39:48.430Z"
        },
        "nike": {
            "names": [
                "Nike",
                "Nike, Inc.",
                "NIKE",
                "NKE",
                "Blue Ribbon Sports"
            ],
            "comment": "Nike is an American sportswear company that has benefited from child labour [1] and forced Uyghur labour [2], has avoided tax [3] and failed to take responsibility for hazardous waste dumping in its supply chain [4]. Nike has invested in sustainable materials research [5] and clothing recycling programs [6].",
            "sources": {
                "1": "https://news.bbc.co.uk/1/hi/programmes/panorama/970385.stm",
                "2": "https://fashionunited.com/news/fashion/nike-patagonia-named-in-european-lawsuit-as-being-complicit-in-forced-labour-practices-in-xinjiang-china/2021120644306",
                "3": "https://www.icij.org/investigations/paradise-papers/explore-politicians-paradise-papers/",
                "4": "https://web.archive.org/web/20200401155311/http://p3-raw.greenpeace.org/international/en/campaigns/detox/fashion/detox-catwalk/",
                "5": "https://hypebeast.com/2021/9/newlight-technologies-aircarbon-negative-nike-sustainable-shoes-what-the-tech",
                "6": "https://theecobahn.com/ideas/nike-reuse-a-shoe-digital-upcycling-shift/"
            },
            "tags": [
                "clothing",
                "child labour",
                "tax evasion",
                "forced labour"
            ],
            "score": 40,
            "ownedBy": [],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/a/a6/Logo_NIKE.svg",
            "siteUrl": "https://www.nike.com/",
            "updatedAt": "2024-01-29T21:48:22.741Z"
        },
        "leon": {
            "names": [
                "LEON",
                "Leon Restaurants"
            ],
            "comment": "LEON is a British restaurant brand that started an initiative to deliver free meals to the NHS during the COVID-19 pandemic [1] and is a founding member of the Sustainable Restaurant Association [2].",
            "sources": {
                "1": "https://www.theguardian.com/business/2020/mar/27/leon-sets-up-initiative-to-deliver-free-hot-meals-to-nhs-staff-coronavirus",
                "2": "https://www.bbc.co.uk/news/uk-48781564"
            },
            "tags": [
                "restaurant",
                "fast food",
                "food"
            ],
            "score": 70,
            "ownedBy": [
                "eg-group"
            ],
            "logoUrl": "https://tse2.mm.bing.net/th?id=OIP.R6-CO58eUfG6oc05A_KJiQHaHa&pid=Api",
            "siteUrl": "https://leon.co/",
            "updatedAt": "2024-02-07T20:14:40.093Z"
        },
        "greggs": {
            "names": [
                "Greggs",
                "Greggs Bakeries Limited",
                "Greggs plc",
                "GRG"
            ],
            "comment": "Greggs is a British bakery whose executives received \"excessive\" bonuses of millions of pounds in 2022 [1] while paying some employees below minimum wage [2]. The Greggs foundation is a charitable organisation that gives schoolchildren access to free, nutritional food [3].",
            "sources": {
                "1": "https://www.theguardian.com/business/2022/may/11/greggs-feels-heat-from-investors-over-excessive-pay-for-bosses",
                "2": "https://www.thecaterer.com/news/mitchells-and-butlers-greggs-minimum-wage-list-2024",
                "3": "https://web.archive.org/web/20240118100640/https://www.greggsfoundation.org.uk/news/2023-in-review"
            },
            "tags": [
                "restaurant",
                "food",
                "fastfood",
                "takeaway",
                "unfair pay"
            ],
            "score": 45,
            "ownedBy": [],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/en/1/19/Greggs_IMG_6701.jpg",
            "siteUrl": "https://www.greggs.co.uk/",
            "updatedAt": "2024-02-06T20:32:30.554Z"
        },
        "mitchells-and-butlers": {
            "names": [
                "Mitchells & Butlers",
                "Mitchells & Butlers plc",
                "M&B",
                "MAB"
            ],
            "comment": "Mitchells & Butlers, a British pub operator and FTSE 250 company, has faced criticism for unethical practices including failing to pay £565,094.87 in minimum wage to 16,187 employees [1], and implementing a tip-sharing system that can result in waiting staff earning below minimum wage [2].",
            "sources": {
                "1": "https://www.thecaterer.com/news/mitchells-and-butlers-greggs-minimum-wage-list-2024",
                "2": "https://www.theguardian.com/business/2023/oct/18/steakhouse-chain-miller-carter-waiting-staff-tips-pay-chefs"
            },
            "tags": [
                "restaurant",
                "bar",
                "pub",
                "bad working conditions"
            ],
            "score": 45,
            "ownedBy": [],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/en/8/80/Mitchells_%26_Butlers_logo.svg",
            "siteUrl": "http://mbplc.com/",
            "updatedAt": "2024-04-07T15:46:15.962Z"
        },
        "toby-carvery": {
            "names": [
                "Toby Carvery",
                "Toby",
                "Toby Pub & Carvery"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "restaurant",
                "pub"
            ],
            "score": 45,
            "ownedBy": [
                "mitchells-and-butlers"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/f/f4/Denham%2C_The_Toby_Pub_and_Carvery_-_geograph.org.uk_-_803065.jpg",
            "siteUrl": "http://www.tobycarvery.co.uk/",
            "updatedAt": "2024-04-07T15:46:15.962Z"
        },
        "harvester": {
            "names": [
                "Harvester"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "restaurant",
                "pub"
            ],
            "score": 45,
            "ownedBy": [
                "mitchells-and-butlers"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/en/c/cf/Harvester_%28restaurant%29_logo.svg",
            "siteUrl": "http://www.harvester.co.uk/",
            "updatedAt": "2024-04-07T15:46:15.962Z"
        },
        "browns": {
            "names": [
                "Browns",
                "Browns Restaurants",
                "Browns Brasserie & Bar"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "restaurant",
                "bar"
            ],
            "score": 45,
            "ownedBy": [
                "mitchells-and-butlers"
            ],
            "logoUrl": "https://www.browns-restaurants.co.uk/content/dam/browns/images/browns-logo.png",
            "siteUrl": "https://www.browns-restaurants.co.uk/",
            "updatedAt": "2024-04-07T15:46:15.962Z"
        },
        "all-bar-one": {
            "names": [
                "All Bar One"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "restaurant",
                "bar"
            ],
            "score": 45,
            "ownedBy": [
                "mitchells-and-butlers"
            ],
            "logoUrl": "https://www.allbarone.co.uk/content/dam/all-bar-one/images/content/theme/2018/abo-test-logo.png",
            "siteUrl": "https://www.allbarone.co.uk/",
            "updatedAt": "2024-04-07T15:46:15.962Z"
        },
        "miller-and-carter": {
            "names": [
                "Miller & Carter"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "restaurant"
            ],
            "score": 45,
            "ownedBy": [
                "mitchells-and-butlers"
            ],
            "logoUrl": "https://www.millerandcarter.co.uk/content/dam/miller-and-carter/images/theme/sw/elements/mclogo-small.png",
            "siteUrl": "https://www.millerandcarter.co.uk/",
            "updatedAt": "2024-04-07T15:46:15.962Z"
        },
        "caffe-nero": {
            "names": [
                "Caffè Nero",
                "Caffe Nero",
                "Cafe Nero",
                "Caffè Nero Group Ltd.",
                "Caffè Nero Ltd"
            ],
            "comment": "Caffè Nero is a UK-based coffeehouse chain that has been accused of avoiding corporation tax through the use of tax-deductible interest payments and a complex corporate structure [1]. The company has also been found to have varying levels of faecal bacteria in its iced drinks, along with other major coffee chains [2].",
            "sources": {
                "1": "https://www.bbc.co.uk/news/business-27291283",
                "2": "https://www.bbc.co.uk/news/business-40426228"
            },
            "tags": [
                "restaurant",
                "coffee",
                "food",
                "beverages",
                "tax avoidance"
            ],
            "score": 46,
            "ownedBy": [],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/2/21/Caff%C3%A8_Nero_Logo.svg",
            "siteUrl": "https://www.caffenero.com/",
            "updatedAt": "2024-04-09T21:34:43.018Z"
        },
        "starbucks": {
            "names": [
                "Starbucks",
                "Starbucks Corporation",
                "SBUX"
            ],
            "comment": "Starbucks is an American coffee shop chain that has prolifically avoided tax [1][2], deprived Ethiopian farmers of their rightful pay [3] and enforces discriminatory pay discrepancies on its employees based on race [4]. Starbucks has engaged in anti-union practices [5][6] and has worked closely with the Anti-Defamation League [7].",
            "sources": {
                "1": "https://web.archive.org/web/20160210024015/http://uk.reuters.com/article/us-britain-starbucks-tax-idUKBRE89E0EX20121015",
                "2": "https://web.archive.org/web/20160104212731/http://uk.reuters.com/article/uk-starbucks-tax-europe-idUKBRE8A00DR20121101",
                "3": "https://web.archive.org/web/20191221001509/https://www.oxfamamerica.org/press/starbucks-opposes-ethiopia-coffee-trademark-plan/",
                "4": "https://web.archive.org/web/20220214212231/https://www.nytimes.com/2020/03/01/business/starbucks-discrimination-race.html",
                "5": "https://web.archive.org/web/20220215175957/https://www.theguardian.com/business/2021/nov/23/starbucks-aggressive-anti-union-effort-new-york-stores-organize",
                "6": "https://web.archive.org/web/20220213165713/https://www.washingtonpost.com/nation/2022/02/12/rhodes-scholar-barista-fight-unionize-starbucks/",
                "7": "https://web.archive.org/web/20190110003522/https://www.nytimes.com/2018/04/17/business/starbucks-arrests-racial-bias.html"
            },
            "tags": [
                "restaurant",
                "coffee",
                "food",
                "beverages",
                "tax avoidance",
                "bad working conditions",
                "anti union"
            ],
            "score": 35,
            "ownedBy": [],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/en/d/d3/Starbucks_Corporation_Logo_2011.svg",
            "siteUrl": "https://starbucks.com/",
            "updatedAt": "2024-04-09T21:34:43.018Z"
        },
        "zizzi": {
            "names": [
                "Zizzi",
                "Zizzi Restaurants Limited"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "restaurant",
                "italian food"
            ],
            "score": null,
            "ownedBy": [
                "towerbrook-capital-partners"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/en/4/46/Zizzi_logo.svg",
            "siteUrl": "http://zizzi.co.uk/",
            "updatedAt": "2024-04-16T08:27:19.280Z"
        },
        "costco": {
            "names": [
                "Costco",
                "Costco Wholesale Corporation",
                "COST"
            ],
            "comment": "Costco is an American wholesaler whose supply chain has included animal cruelty [1][2] and forced monkey labour [3], and which has overworked employees resulting in death [4]. Costco has sourced stock produced by slave labour [5], cut down Canadian forests to make toilet paper [6], and spilled 111 thousand litres of oil in Iceland [7].",
            "sources": {
                "1": "https://web.archive.org/web/20160302200432/http://qz.com/582427/after-months-of-pressure-costco-commits-to-a-cage-free-egg-supply/",
                "2": "https://web.archive.org/web/20170130104252/https://www.washingtonpost.com/news/animalia/wp/2016/10/20/her-head-has-been-torn-off-activists-film-alleges-abuse-at-costco-linked-cage-free-egg-farm/",
                "3": "https://web.archive.org/web/20210417155108/https://www.cnn.com/2020/10/31/business/costco-coconut-milk-monkey-labor-trnd/index.html",
                "4": "https://www.koreatimes.co.kr/www/tech/2024/03/419_359141.html",
                "5": "https://web.archive.org/web/20200728230134/https://www.cbsnews.com/news/shrimp-sold-at-walmart-costco-tied-to-slave-labor/",
                "6": "https://actions.sumofus.org/a/costco-protect-the-boreal-forests-and-use-recycled-pulp-in-your-toilet-paper?source=campaigns",
                "7": "https://www.icelandreview.com/news/costco-fined-isk-20-million-for-gross-negligence-over-oil-spill/"
            },
            "tags": [
                "retail",
                "supermarket"
            ],
            "score": 7,
            "ownedBy": [],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/5/59/Costco_Wholesale_logo_2010-10-26.svg",
            "siteUrl": "https://www.costco.com/",
            "updatedAt": "2024-05-11T22:44:30.064Z"
        },
        "wagamama": {
            "names": [
                "Wagamama"
            ],
            "comment": "Wagamama is a British restaurant chain that has underpaid staff and failed to pay the National Minimum Wage [1], and has been accused of maintaining a policy that discourages sick employees from taking time off [2]. The company has also faced criticism for requiring staff to cover the cost of uniforms, which was considered an infringement on workers' rights [1].",
            "sources": {
                "1": "https://www.bbc.com/news/business-43332875",
                "2": "https://pjhlaw.co.uk/2018/01/05/happened-christmas-part-1-wagamama-sick-leave/"
            },
            "tags": [
                "restaurant",
                "bad working conditions"
            ],
            "score": 40,
            "ownedBy": [
                "apollo-global-management"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/3/35/Wagamama_logo.svg",
            "siteUrl": "https://www.wagamama.com/",
            "updatedAt": "2024-04-16T15:20:06.727Z"
        },
        "yo!-sushi": {
            "names": [
                "YO! Sushi",
                "Yo! Sushi",
                "Yo Sushi"
            ],
            "comment": "YO! Sushi, a British sushi restaurant chain, has been identified as having the worst food safety record among major chains, with eight of its 23 restaurants failing to meet legal hygiene standards [1]. YO! Sushi refusing to seat a blind woman accompanied by a guide dog [2].",
            "sources": {
                "1": "https://www.thecaterer.com/news/restaurant/yo-sushi-defends-food-safety-procedures-after-independent-attacks-hygiene-record",
                "2": "https://www.mailplus.co.uk/edition/news/society/107928/yo-sushi-restaurant-refuses-to-seat-blind-woman-accompanied-by-guide-dog"
            },
            "tags": [
                "restaurant",
                "sushi",
                "endangerment",
                "discrimination"
            ],
            "score": 39,
            "ownedBy": [],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/en/a/a1/YO%21_Sushi_logo.svg",
            "siteUrl": "https://yosushi.com/",
            "updatedAt": "2024-04-15T15:40:27.062Z"
        },
        "shake-shack": {
            "names": [
                "Shake Shack",
                "SHAK"
            ],
            "comment": "Shake Shack is an American fast food chain that has agreed to a $6 million class action lawsuit settlement for failing to provide consistent and lawful schedules to New York City employees, violating the city's Fair Workweek Law [1]. The company has also been ordered to pay $20,000 to a former employee for gender discrimination, harassment, and retaliation based on gender identity and expression, and has been accused of denying managers overtime pay [2][3]. Additionally, Shake Shack's connection to a bakery whose owners support a controversial political figure has led to criticism and calls for a boycott of the chain [4].",
            "sources": {
                "1": "https://topclassactions.com/lawsuit-settlements/closed-settlements/shake-shack-employee-schedules-6m-class-action-lawsuit-settlement/",
                "2": "https://www.today.com/food/news/shake-shack-misgendering-discrimination-lawsuit-rcna70790",
                "3": "https://www.dnainfo.com/new-york/20160329/midtown/shake-shack-settles-lawsuit-over-manager-overtime-pay-company-says/",
                "4": "https://www.tastingtable.com/884727/the-shake-shack-potato-roll-controversy-explained/"
            },
            "tags": [
                "fast food",
                "burgers",
                "discrimination",
                "bad working conditions"
            ],
            "score": 40,
            "ownedBy": [],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/9/96/Shake_Shack_logo.svg",
            "siteUrl": "https://www.shakeshack.com/",
            "updatedAt": "2024-04-15T15:40:27.062Z"
        },
        "papa-john's": {
            "names": [
                "Papa John's",
                "Papa John's International, Inc."
            ],
            "comment": "Papa John's is an American pizza chain which has faced allegations of sexual harassment and racism by its founder and former CEO [1][2]. The company has been described as having a toxic atmosphere, with instances of sexism and racist language used by its executives [3].",
            "sources": {
                "1": "https://www.cnbc.com/2018/07/10/papa-johns-founder-john-schnatter-resigns-as-chairman-of-company-boar.html",
                "2": "https://www.forbes.com/sites/forbesdigitalcovers/2018/07/19/the-inside-story-of-papa-johns-toxic-culture/",
                "3": "https://www.forbes.com/sites/forbesdigitalcovers/2018/07/19/the-inside-story-of-papa-johns-toxic-culture/"
            },
            "tags": [
                "fast food",
                "pizza",
                "sexual harassment",
                "discrimination",
                "bad working conditions"
            ],
            "score": 40,
            "ownedBy": [],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/9/92/Papa_Johns_logo.svg",
            "siteUrl": "http://papajohns.com/",
            "updatedAt": "2024-04-15T15:40:27.062Z"
        },
        "wimpy": {
            "names": [
                "Wimpy"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "fast food",
                "burgers"
            ],
            "score": 50,
            "ownedBy": [
                "famous-brands"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/1/1e/Logo_of_Wimpy.svg",
            "siteUrl": "http://www.wimpy.uk.com/",
            "updatedAt": "2024-04-15T15:40:27.062Z"
        },
        "tortilla": {
            "names": [
                "Tortilla",
                "Tortilla Mexican Grill PLC",
                "MEX"
            ],
            "comment": "Tortilla is a UK restaurant chain that sources 100% renewable electricity and converts its used cooking oil to biodiesel [1].",
            "sources": {
                "1": "http://web.archive.org/web/20240415153554/https://tortillagroup.co.uk/wp-content/uploads/Release-Tortilla-ESG-impact-report.pdf"
            },
            "tags": [
                "restaurant",
                "food",
                "fast food"
            ],
            "score": 75,
            "ownedBy": [],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/e/ee/Tortilla_logo.svg",
            "siteUrl": "https://www.tortilla.co.uk/",
            "updatedAt": "2024-04-15T07:16:31.977Z"
        },
        "five-guys": {
            "names": [
                "Five Guys",
                "Five Guys Burgers and Fries",
                "Five Guys Enterprises, LLC",
                "5 Guys"
            ],
            "comment": "Five Guys, a American burger chain, has faced allegations of gender-based pay discrimination [1], labor violations including off-the-clock work and denied breaks [2], and discrimination against an employee with HIV [3]. The company has also been subject to sexual harassment lawsuits, with former employees claiming they were subjected to hostile work environments [4].",
            "sources": {
                "1": "https://news.bloomberglaw.com/daily-labor-report/five-guys-loses-round-to-two-women-in-nationwide-pay-bias-suit",
                "2": "https://topclassactions.com/lawsuit-settlements/lawsuit-news/five-guys-burgers-accused-of-california-labor-violations/",
                "3": "http://web.archive.org/web/20181101230628/https://www.ocala.com/news/20181101/fired-ocala-employee-sues-five-guys-for-discrimination",
                "4": "http://web.archive.org/web/20240414210254/https://www.wvgazettemail.com/news/legal_affairs/putnam-five-guys-employees-sue-managers-chain-over-sexual-harassment/article_5366f1b7-3d9b-584a-afc4-9247787ab51c.html"
            },
            "tags": [
                "restaurant",
                "fast food",
                "food",
                "bad working conditions",
                "discrimination",
                "sexual harassment"
            ],
            "score": 40,
            "ownedBy": [],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/8/80/Five_Guys_logo.svg",
            "siteUrl": "http://fiveguys.com/",
            "updatedAt": "2024-04-14T21:05:29.060Z"
        },
        "chipotle": {
            "names": [
                "Chipotle",
                "Chipotle Mexican Grill",
                "Chipotle Mexican Grill, Inc.",
                "CMG"
            ],
            "comment": "Chiptole is an American fast food company that has underpaid its employees [1] who often face unacceptable working conditions [2]. Chiptole's poor food standards have made thousands of people sick [3] and the company has failed to report on its sustainability practices [4].",
            "sources": {
                "1": "https://edition.cnn.com/2021/04/29/business/chipotle-nyc-lawsuit-labor-law/index.html",
                "2": "https://www.nytimes.com/2019/09/10/nyregion/chipotle-lawsuit-workers.html",
                "3": "https://www.ft.com/content/8fcc81a3-aed8-47e0-9898-ab34dd6ef73c",
                "4": "https://www.theguardian.com/sustainable-business/2015/oct/23/chipotles-corporate-social-responsibility-mcdonalds-gmo"
            },
            "tags": [
                "restaurant",
                "food",
                "fast food",
                "bad working conditions",
                "endangerment"
            ],
            "score": 40,
            "ownedBy": [],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/en/3/3b/Chipotle_Mexican_Grill_logo.svg",
            "siteUrl": "http://chipotle.com/",
            "updatedAt": "2024-04-13T08:34:01.493Z"
        },
        "adidas": {
            "names": [
                "Adidas",
                "Adidas AG",
                "ADS"
            ],
            "comment": "Adidas is a German sportswear manufacturer that has selected suppliers subjecting employees to sweatshop-like conditions [1] and whose head of HR resigned after inaction against racial discrimination within the company [2].",
            "sources": {
                "1": "https://www.oxfam.org.au/explore/workers-rights/adidas",
                "2": "https://www.reuters.com/article/us-adidas-race/adidas-human-resources-head-steps-down-after-race-row-idUSKBN241332?il=0"
            },
            "tags": [
                "clothing",
                "bad working conditions",
                "discrimination"
            ],
            "score": 42,
            "ownedBy": [],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/1/1b/Adidas_2022_logo.svg",
            "siteUrl": "https://www.adidas.com/",
            "updatedAt": "2024-01-29T21:48:22.741Z"
        },
        "uniqlo": {
            "names": [
                "Uniqlo"
            ],
            "comment": "Uniqlo is a Japanese clothing retailer that has repeatedly exhibited abuse of its workers globally [1][2][3][4], sourced materials stemming from forced labour [5] and produced adverts that trivialised the trauma of victims of sex slavery [6]. Uniqlo initially decided to keep operating in Russia but then reversed its decision [7].",
            "sources": {
                "1": "https://www.waronwant.org/sites/default/files/WoW_uniqlo%20report%202016.pdf",
                "2": "https://web.archive.org/web/20160827181345/http://www.waronwant.org/media/reality-behind-uniqlos-corporate-social-responsibility-promises",
                "3": "https://web.archive.org/web/20160920181433/http://qz.com/324726/your-uniqlo-skinny-jeans-may-have-a-dark-past/",
                "4": "https://web.archive.org/web/20190302234149/https://www.news.com.au/finance/business/retail/everyone-has-some-form-of-ptsd-former-uniqlo-employees-describe-toxic-bullying-culture/news-story/3e355f0f9c64234aa30e95b378735df8",
                "5": "https://web.archive.org/web/20210521104028/https://www.reuters.com/world/china/uniqlo-shirts-blocked-us-border-january-china-forced-labour-concern-2021-05-19/",
                "6": "https://web.archive.org/web/20210614232327/https://ajax.googleapis.com/ajax/libs/jquery/1.9.1/jquery.min.js",
                "7": "https://www.nytimes.com/2022/03/10/business/uniqlo-fast-retailing-russia.html"
            },
            "tags": [
                "clothing",
                "fast fashion",
                "retail",
                "bad working conditions",
                "forced labour"
            ],
            "score": 25,
            "ownedBy": [],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/7/7e/Uniqlo_logo_Japanese.svg",
            "siteUrl": "https://www.uniqlo.com/",
            "updatedAt": "2024-02-18T12:37:05.918Z"
        },
        "twitter": {
            "names": [
                "Twitter",
                "X",
                "Twitter.com",
                "x.com",
                "Twitter, Inc.",
                "X Corp."
            ],
            "comment": "X, formerly known as Twitter, is an American social media platform owned by Elon Musk, under whose ownership hate speech has flourished [1]. Twitter has been pivotal in democratising the spread of information and news [2], and for purposes like organising political actions [3], but its algorithm has been found to be politically biased [4]. ",
            "sources": {
                "1": "https://www.wired.com/story/twitter-really-is-worse-than-ever/",
                "2": "https://web.archive.org/web/20170206120212/http://denman-mills.net/web_documents/jips_mills.etal._2009.07.22_finalsubmission.pdf",
                "3": "https://doi.org/10.13140%2FRG.2.1.4239.9442",
                "4": "https://www.theguardian.com/technology/2021/oct/22/twitter-admits-bias-in-algorithm-for-rightwing-politicians-and-news-outlets"
            },
            "tags": [
                "social media",
                "news",
                "tech",
                "communications",
                "radicalisation"
            ],
            "score": 48,
            "ownedBy": [],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/c/ce/X_logo_2023.svg",
            "siteUrl": "https://twitter.com/",
            "updatedAt": "2024-02-18T12:11:46.993Z"
        },
        "converse": {
            "names": [
                "Converse"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "clothing"
            ],
            "score": 40,
            "ownedBy": [
                "nike"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/3/30/Converse_logo.svg",
            "siteUrl": "https://converse.com/",
            "updatedAt": "2024-01-29T21:48:22.741Z"
        },
        "jordans": {
            "names": [
                "Jordans",
                "Air Jordan",
                "Jordan Brand"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "clothing"
            ],
            "score": 40,
            "ownedBy": [
                "nike"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/en/3/37/Jumpman_logo.svg",
            "siteUrl": "https://www.nike.com/jordan",
            "updatedAt": "2024-01-29T21:48:22.741Z"
        },
        "lg": {
            "names": [
                "LG",
                "LG Corporation",
                "LG Group",
                "Lucky-Goldstar",
                "주식회사 엘지",
                "LGLD"
            ],
            "comment": "LG is a South Korean technology and industrial conglomerate that produces products including electronics, batteries and petrochemicals. In 2020 two fatal accidents at LG Chem facilities resulted in the deaths of thirteen people [1].",
            "sources": {
                "1": "https://techxplore.com/news/2020-05-lg-chairman-apologises-gas-leak.html"
            },
            "tags": [
                "tech",
                "chemicals",
                "electronics",
                "endangerment"
            ],
            "score": 20,
            "ownedBy": [],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/8/8d/LG_logo_%282014%29.svg",
            "siteUrl": "https://lgcorp.com/",
            "updatedAt": "2024-01-21T20:33:02.640Z"
        },
        "accenture": {
            "names": [
                "Accenture",
                "Accenture plc",
                "Accenture Limited",
                "Accenture Services Ltd.",
                "Accenture FZ-LLC",
                "Accenture Solutions S.A.",
                "Accenture Federal Services",
                "ACN"
            ],
            "comment": "Accenture faces criticism for working with Isabel dos Santos, accused of corruption, and is under employee backlash over a $297 million contract with Customs and Border Protection (CBP) that supports inhumane policies [1]. Accenture has been involved in allegations of corruption-linked businesses and faces internal protests similar to those at Amazon, Salesforce, Microsoft, and Google [2].",
            "sources": {
                "1": "https://qz.com/africa/1787289/accenture-took-millions-from-isabel-dos-santos-despite-corruption-claims",
                "2": "https://gizmodo.com/accenture-employees-demand-their-company-break-ties-wit-1830474961"
            },
            "tags": [
                "consulting",
                "tech",
                "cybersecurity"
            ],
            "score": 41,
            "ownedBy": [],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/c/cd/Accenture.svg",
            "siteUrl": "https://www.accenture.com/",
            "updatedAt": "2024-03-02T19:34:35.315Z"
        },
        "procter-and-gamble": {
            "names": [
                "Procter & Gamble",
                "P&G",
                "Procter and Gamble",
                "The Procter & Gamble Company",
                "P & G",
                "PG"
            ],
            "comment": "Procter and Gamble is an American consumer goods company that has profited from child labour in palm oil production [1] and has been named by Ukraine as an international sponsor of war [2].\nThe company's advertising has addressed issues including police brutality and has won an Emmy award [3][4].",
            "sources": {
                "1": "https://web.archive.org/web/20180423210624/https://www.amnesty.org/en/documents/asa21/5184/2016/en/",
                "2": "https://sanctions.nazk.gov.ua/en/boycott/14/",
                "3": "https://web.archive.org/web/20190216195636/https://www.cbsnews.com/amp/news/the-talk-ad-procter-and-gamble-commercial-racial-stereotypes-stirs-debate/",
                "4": "https://web.archive.org/web/20180913074041/https://www.shootonline.com/news/pgs-talk%C2%A0wins-primetime-commercial-emmy-award"
            },
            "tags": [
                "consumer goods",
                "child labour",
                "russia"
            ],
            "score": 35,
            "ownedBy": [],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/8/85/Procter_%26_Gamble_logo.svg",
            "siteUrl": "https://pg.com/",
            "updatedAt": "2024-01-31T19:00:57.998Z"
        },
        "spotify": {
            "names": [
                "Spotify",
                "Spotify Technology S.A.",
                "SPOT",
                "Spotify Ltd",
                "Spotify USA Inc.",
                "Spotify AB"
            ],
            "comment": "Spotify is a music streaming service that has been accused of unfairly compensating artists [1][2] and whose CEO has donated millions to military artificial intelligence research [3][4]. Although spotify has reduced piracy it has also reduced music sales [5] limiting benefit to artists.",
            "sources": {
                "1": "https://www.digitalmusicnews.com/2021/06/29/spotify-executive-entitled-pay-penny-per-stream/",
                "2": "https://www.huffingtonpost.com/2012/03/28/black-keys-sean-parker-spotify-asshole_n_1384882.html",
                "3": "https://techcrunch.com/2021/11/09/daniel-eks-fund-puts-e100m-into-defence-startup-helsing-ai-to-support-democracies/",
                "4": "https://www.billboard.com/pro/daniel-ek-defense-tech-investment-boycott-spotify-helsing/",
                "5": "https://www.engadget.com/2015-10-28-spotify-piracy-study.html?guccounter=1"
            },
            "tags": [
                "tech",
                "music"
            ],
            "score": 40,
            "ownedBy": [],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/2/26/Spotify_logo_with_text.svg",
            "siteUrl": "https://spotify.com/",
            "updatedAt": "2024-01-26T18:00:16.127Z"
        },
        "unilever": {
            "names": [
                "Unilever",
                "Unilever plc",
                "ULVR",
                "UNA",
                "UL"
            ],
            "comment": "Unilever is a British food and beverage and consumer products conglomerate that has been named by Ukraine as a sponsor of war [1], continued selling Ben & Jerry's in Israeli settlements depite the brands's intent to withdraw [2], hired security that assaulted peaceful protestors [3], aggressively pushed skin lightening products [4], engaged in price fixing [5], opposed legislation against single-use plastic [6], caused deforestation [7] and sourced palm oil from a supplier that used child labour [8].",
            "sources": {
                "1": "https://archive.is/v01oO",
                "2": "https://archive.is/dwpFn",
                "3": "https://web.archive.org/web/20211206070632/https://www.ethicalconsumer.org/food-drink/unilever-still-failing-respect-its-workers-rights",
                "4": "https://web.archive.org/web/20201103064806/https://www.buzzfeednews.com/article/meghara/unilever-sri-lankan-influencers-skin-whiteners",
                "5": "https://web.archive.org/web/20180509100638/http://www.bbc.co.uk/news/business-13064928",
                "6": "https://www.reuters.com/investigates/special-report/global-plastic-unilever/",
                "7": "https://web.archive.org/web/20140508042719/http://www.greenpeace.org.uk/media/press-releases/unilever-admits-toxic-dumping-will-clean-up-but-not-come-clean",
                "8": "https://web.archive.org/web/20180423210624/https://www.amnesty.org/en/documents/asa21/5184/2016/en/"
            },
            "tags": [
                "food",
                "beverages",
                "consumer goods",
                "cleaning",
                "russia",
                "plastic",
                "deforestation",
                "child labour",
                "anticompetitive",
                "unsustainable"
            ],
            "score": 5,
            "ownedBy": [],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/en/e/e4/Unilever.svg",
            "siteUrl": "https://www.unilever.com/",
            "updatedAt": "2024-01-25T07:25:34.031Z"
        },
        "knorr": {
            "names": [
                "Knorr"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "food"
            ],
            "score": 5,
            "ownedBy": [
                "unilever"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/d/dc/Knorr_Logo_2020.svg",
            "siteUrl": "https://www.knorr.com/",
            "updatedAt": "2024-01-25T22:09:42.603Z"
        },
        "vaseline": {
            "names": [
                "Vaseline"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "consumer goods"
            ],
            "score": 5,
            "ownedBy": [
                "unilever"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/thumb/6/6c/Vaseline_new_logo.png/640px-Vaseline_new_logo.png",
            "siteUrl": "https://vaseline.com/",
            "updatedAt": "2024-01-25T22:09:42.603Z"
        },
        "colman's": {
            "names": [
                "Colman's"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "food"
            ],
            "score": 5,
            "ownedBy": [
                "unilever"
            ],
            "logoUrl": "https://www.colmans.co.uk/sk-eu/content/dam/brands/colmans/united_kingdom/1468566-colmans-logo.png.rendition.466.242.png",
            "siteUrl": "https://www.colmans.co.uk/",
            "updatedAt": "2024-01-27T01:34:37.705Z"
        },
        "mondelez": {
            "names": [
                "Mondelez",
                "Mondelēz",
                "Mondelez International",
                "Mondelez International, Inc.",
                "Mondelēz International",
                "MDLZ"
            ],
            "comment": "Mondelez International, the maker of Cadbury, Oreo, and other popular snack brands, has been accused of destroying tens of thousands of hectares of orangutan habitat [1] and continuing to employ child labor on cocoa farms in its supply chain despite past pledges to eliminate such practices [2]. Despite promising to source sustainable palm oil, Mondelez has remained linked to suppliers engaged in deforestation and human rights abuses, and the company continued operating in Russia following its invasion of Ukraine, leading Ukraine to declare it an international sponsor of the war [3].",
            "sources": {
                "1": "https://www.independent.co.uk/environment/orangutans-palm-oil-habitat-rainforest-cadbury-mondelez-oreos-indonesia-greenpeace-a8630801.html",
                "2": "https://www.theguardian.com/law/2022/apr/03/cadbury-faces-fresh-accusations-of-child-labour-on-cocoa-farms-in-ghana",
                "3": "https://english.nv.ua/business/ukraine-declares-mondelez-international-makers-of-milka-and-oreo-sponsor-of-war-50327508.html"
            },
            "tags": [
                "food",
                "beverages",
                "unsustainable",
                "deforestation",
                "child labour",
                "russia"
            ],
            "score": 20,
            "ownedBy": [],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/3/3b/Mondelez_International.svg",
            "siteUrl": "http://mondelezinternational.com/",
            "updatedAt": "2024-03-14T21:59:31.553Z"
        },
        "cadbury": {
            "names": [
                "Cadbury"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "confectionery"
            ],
            "score": 20,
            "ownedBy": [
                "mondelez"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/a/a4/Cadbury_%282020%29.svg",
            "siteUrl": "https://cadbury.co.uk/",
            "updatedAt": "2024-01-27T01:34:37.705Z"
        },
        "daim": {
            "names": [
                "Daim"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "confectionery"
            ],
            "score": 20,
            "ownedBy": [
                "mondelez"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/en/c/cf/Daim-Wrapper-Small.jpg",
            "siteUrl": "https://www.mondelezinternational.com/our-brands/daim/",
            "updatedAt": "2024-01-27T01:34:37.705Z"
        },
        "halls": {
            "names": [
                "Halls"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "confectionery"
            ],
            "score": 20,
            "ownedBy": [
                "mondelez"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/en/f/f0/HallsCoughDrops.jpg",
            "siteUrl": "http://gethalls.com/",
            "updatedAt": "2024-01-27T01:34:37.705Z"
        },
        "milka": {
            "names": [
                "Milka"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "confectionery"
            ],
            "score": 20,
            "ownedBy": [
                "mondelez"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/0/09/Milka_purple_logo18.svg",
            "siteUrl": "https://www.milka.com/",
            "updatedAt": "2024-01-27T01:34:37.705Z"
        },
        "oreo": {
            "names": [
                "Oreo"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "confectionery"
            ],
            "score": 20,
            "ownedBy": [
                "mondelez"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/en/9/97/Oreo_Cookie_logo.png",
            "siteUrl": "https://www.oreo.com/",
            "updatedAt": "2024-01-27T01:34:37.705Z"
        },
        "philadelphia": {
            "names": [
                "Philadelphia"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "food"
            ],
            "score": 20,
            "ownedBy": [
                "mondelez",
                "kraft-heinz"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/6/6f/Philadelphia_cheese_logo.png",
            "siteUrl": "https://www.mondelezinternational.com/our-brands/philadelphia/",
            "updatedAt": "2024-01-27T01:34:37.705Z"
        },
        "ritz": {
            "names": [
                "Ritz"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "food"
            ],
            "score": 20,
            "ownedBy": [
                "mondelez"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/7/72/Ritz_crackers_logo.png",
            "siteUrl": "https://www.ritzcrackers.com/",
            "updatedAt": "2024-01-27T01:34:37.705Z"
        },
        "sour-patch-kids": {
            "names": [
                "Sour Patch Kids"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "confectionery"
            ],
            "score": 20,
            "ownedBy": [
                "mondelez"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/4/4a/Sour-Patch-Kids.jpg",
            "siteUrl": "http://sourpatchkids.com/",
            "updatedAt": "2024-01-27T01:34:37.705Z"
        },
        "toblerone": {
            "names": [
                "Toblerone"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "confectionery"
            ],
            "score": 20,
            "ownedBy": [
                "mondelez"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/e/e6/Toblerone_logo22.png",
            "siteUrl": "https://www.toblerone.co.uk/en",
            "updatedAt": "2024-01-27T01:34:37.705Z"
        },
        "primark": {
            "names": [
                "Primark",
                "Penneys",
                "Primark Stores Limited"
            ],
            "comment": "Primark, a British fast fashion giant, has been accused of underpaying garment workers in its supply chain, employing undocumented immigrants below minimum wage, and imprisoning workers for participating in protests against Myanmar's military coup [1][2][3]. The company has also faced criticism for promoting sexist messaging on children's clothing and removing ethical branding following investigations into labor violations [2][3].",
            "sources": {
                "1": "https://www.theguardian.com/global-development/2021/mar/13/primark-supplier-accused-of-locking-workers-in-factory-in-myanmar-protests",
                "2": "https://www.theguardian.com/business/2009/jan/11/primark-ethical-business-living",
                "3": "https://www.bbc.co.uk/news/business-60290232"
            },
            "tags": [
                "fast fashion",
                "clothing",
                "retail",
                "bad working conditions",
                "forced labour"
            ],
            "score": 25,
            "ownedBy": [],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/9/99/Primark_Stores_Logo.svg",
            "siteUrl": "http://primark.com/",
            "updatedAt": "2024-03-16T08:22:52.479Z"
        },
        "tk-maxx": {
            "names": [
                "TK Maxx",
                "TJ Maxx",
                "HomeGoods",
                "HomeSense",
                "TJX",
                "TJX Companies",
                "T.K. Maxx",
                "T.K.maxx",
                "Marshalls"
            ],
            "comment": "TK Maxx, a major retailer, has been found selling real fur from animals like rabbits, foxes and mink that was mislabeled as \"faux fur\", despite claiming to have a no-fur policy [1]. Along with other fashion brands like Boohoo, Amazon and Missguided, TK Maxx has also been called out by UK MPs for failing to take sufficient action on sustainability and ethical labor practices in its supply chain [2].",
            "sources": {
                "1": "https://news.sky.com/story/more-retailers-selling-real-fur-labelled-as-fake-sky-news-investigation-finds-11177979",
                "2": "https://www.bbc.co.uk/news/business-47057870"
            },
            "tags": [
                "clothing",
                "retail",
                "animal abuse",
                "unsustainable"
            ],
            "score": 45,
            "ownedBy": [],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/e/e9/TK_Maxx_Logo.svg",
            "siteUrl": "http://www.tkmaxx.com/",
            "updatedAt": "2024-03-16T08:36:24.708Z"
        },
        "kraft-heinz": {
            "names": [
                "Kraft Heinz",
                "Kraft",
                "KHC"
            ],
            "comment": "Kraft Heinz, one of the world's largest food companies, paid a $62 million fine for creating over $200 million in fake cost savings to mislead investors, violating securities laws [1]. The company has also been criticized by WWF for lagging behind on sustainable palm oil sourcing, a key driver of deforestation and habitat destruction [2].",
            "sources": {
                "1": "https://www.forbes.com/sites/graisondangor/2021/09/03/kraft-heinz-fined-62-million-for-phony-accounting/",
                "2": "https://www.independent.co.uk/climate-change/news/heinz-palm-oil-worst-companies-b1929351.html"
            },
            "tags": [
                "food",
                "unsustainable"
            ],
            "score": 40,
            "ownedBy": [],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/5/55/KraftHeinz.svg",
            "siteUrl": "https://kraftheinzcompany.com/",
            "updatedAt": "2024-03-16T09:11:26.184Z"
        },
        "heinz": {
            "names": [
                "Heinz",
                "HNZ"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "food"
            ],
            "score": 40,
            "ownedBy": [
                "kraft-heinz"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/3/35/H._J._Heinz_Company_-_Logo.svg",
            "siteUrl": "https://www.heinz.com/",
            "updatedAt": "2024-03-16T09:11:26.184Z"
        },
        "daddies": {
            "names": [
                "Daddies"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "food"
            ],
            "score": 40,
            "ownedBy": [
                "kraft-heinz"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/5/59/Daddies_brand_logo.png",
            "siteUrl": "https://kraftheinzcompany.com/",
            "updatedAt": "2024-03-16T09:11:26.184Z"
        },
        "grey-poupon": {
            "names": [
                "Grey Poupon"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "food"
            ],
            "score": 40,
            "ownedBy": [
                "kraft-heinz"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/en/e/ea/Grey_poupon_logo.png",
            "siteUrl": "https://greypouponmustard.co.uk/",
            "updatedAt": "2024-03-16T09:11:26.184Z"
        },
        "hp-sauce": {
            "names": [
                "HP Sauce"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "food"
            ],
            "score": 40,
            "ownedBy": [
                "kraft-heinz"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/en/8/87/HPsauce.svg",
            "siteUrl": "https://www.hpsauce.co.uk/",
            "updatedAt": "2024-03-16T09:11:26.184Z"
        },
        "kool-aid": {
            "names": [
                "Kool-Aid"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "beverages"
            ],
            "score": 40,
            "ownedBy": [
                "kraft-heinz"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/c/c0/Kool_aid_brand_logo.png",
            "siteUrl": "https://www.koolaid.com/",
            "updatedAt": "2024-03-16T09:11:26.184Z"
        },
        "jell-o": {
            "names": [
                "Jell-O"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "confectionery"
            ],
            "score": 40,
            "ownedBy": [
                "kraft-heinz"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/9/91/Jell-O_new_logo_%282023%29.svg",
            "siteUrl": "https://www.kraftheinz.com/jello",
            "updatedAt": "2024-03-16T09:11:26.184Z"
        },
        "lea-and-perrins": {
            "names": [
                "Lea & Perrins",
                "Worcestershire Sauce"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "food"
            ],
            "score": 40,
            "ownedBy": [
                "kraft-heinz"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/8/84/Leaandperrins_logo.png",
            "siteUrl": "https://www.kraftheinz.com/",
            "updatedAt": "2024-03-16T09:11:26.184Z"
        },
        "walmart": {
            "names": [
                "Walmart",
                "Massmart",
                "Flipkart",
                "Walmart Inc.",
                "Wal-Mart",
                "WMT"
            ],
            "comment": "Walmart, the world's largest retailer, has been accused of destroying Native American burial grounds [1], violating labor laws by disciplining protesting workers [2], illegally employing minors and denying breaks [3], and fueling the opioid crisis through irresponsible pharmaceutical practices [4].",
            "sources": {
                "1": "https://web.archive.org/web/20070929045815/http://www.anairtn.org/walmart/index.html",
                "2": "https://web.archive.org/web/20131126221724/http://www.latimes.com/business/money/la-fi-mo-nlrb-walmart-20131119,0,1499317.story#axzz2lfbzVSap",
                "3": "https://web.archive.org/web/20070807045544/http://www.corpwatch.org/article.php?id=9649",
                "4": "https://www.nytimes.com/2022/11/15/health/walmart-opioids-settlement.html"
            },
            "tags": [
                "supermarket",
                "retail",
                "bad working conditions",
                "child labour",
                "endangerment"
            ],
            "score": 20,
            "ownedBy": [],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/c/ca/Walmart_logo.svg",
            "siteUrl": "https://www.walmart.com/",
            "updatedAt": "2024-03-29T15:10:50.832Z"
        },
        "asda": {
            "names": [
                "ASDA"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "supermarket",
                "retail"
            ],
            "score": 20,
            "ownedBy": [
                "walmart"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/en/9/91/Asda_logo.svg",
            "siteUrl": "https://asda.com/",
            "updatedAt": "2024-03-29T15:10:50.832Z"
        },
        "aldi": {
            "names": [
                "ALDI",
                "Aldi"
            ],
            "comment": "Aldi, a German supermarket chain, has cut emissions substantially through renewable energy and efficiency measures [1], but has been accused of systemic wage theft by requiring employees to work unpaid before shifts [2][3]. The company was sued for misclassifying managers to avoid overtime pay [2], and potentially underpaid over 20,000 workers more than $150 million [3].",
            "sources": {
                "1": "https://web.archive.org/web/20190126101154/https://www.treehugger.com/corporate-responsibility/supermarket-cuts-emissions-53-offsets-rest.html",
                "2": "https://www.syracuse.com/business/2019/02/aldi-pays-98-million-in-syracuse-overtime-lawsuit.html",
                "3": "https://www.afr.com/work-and-careers/workplace/aldi-accused-of-150m-deliberate-wage-theft-20231027-p5efni"
            },
            "tags": [
                "supermarket",
                "retail",
                "bad working conditions"
            ],
            "score": 60,
            "ownedBy": [],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/4/4f/ALDI_SUD.png",
            "siteUrl": "http://aldi.com/",
            "updatedAt": "2024-03-29T16:58:43.366Z"
        },
        "lidl": {
            "names": [
                "Lidl",
                "LIDL",
                "Kaufland"
            ],
            "comment": "Lidl, a German discount supermarket chain, has been accused of extensively spying on employees, recording personal details like menstrual cycles, relationships and finances [1], as well as shutting down stores where employees unionise [2]. The company has also faced allegations of animal cruelty [3], using unethical killing methods on chickens, operating in inhumane conditions, and deceiving consumers about severing ties with an abusive supplier farm.",
            "sources": {
                "1": "https://www.theguardian.com/world/2008/mar/27/germany.supermarkets",
                "2": "https://www.mashed.com/94133/untold-truth-lidl/",
                "3": "https://www.lidlchickenscandal.com/"
            },
            "tags": [
                "supermarket",
                "retail",
                "antiprivacy",
                "bad working conditions",
                "animal abuse",
                "antiunion"
            ],
            "score": 30,
            "ownedBy": [
                "schwarz-gruppe"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/9/91/Lidl-Logo.svg",
            "siteUrl": "https://info.lidl/en/",
            "updatedAt": "2024-03-29T16:58:43.366Z"
        },
        "morrisons": {
            "names": [
                "Morrisons",
                "Wm Morrison Supermarkets Limited"
            ],
            "comment": "Morrisons is a British supermarket chain that awarded millions in executive bonuses despite pandemic-related profit losses by adjusting performance metrics [1], and has been criticized for animal cruelty in its chicken supply chain despite marketing higher welfare standards [2]. Morrisons was ordered to pay compensation against a mother who suffered workplace discrimination [3].",
            "sources": {
                "1": "https://www.theguardian.com/business/2021/jun/10/morrisons-shareholders-reject-executive-bonuses-amid-falling-profits",
                "2": "https://www.retailgazette.co.uk/blog/2021/06/morrisons-under-fire-for-ignoring-animal-suffering-in-supply-chain/",
                "3": "https://www.bbc.co.uk/news/business-63457797"
            },
            "tags": [
                "supermarket",
                "retail",
                "unfair pay",
                "animal abuse",
                "discrimination"
            ],
            "score": 38,
            "ownedBy": [],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/en/8/82/MorrisonsLogo.svg",
            "siteUrl": "https://morrisons.com/",
            "updatedAt": "2024-03-30T10:14:47.798Z"
        },
        "mccoll's": {
            "names": [
                "McColl's"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "supermarket",
                "retail"
            ],
            "score": 38,
            "ownedBy": [
                "morrisons"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/f/f4/McColl%27s_logo.svg",
            "siteUrl": "http://www.morrisons.com/mccolls/",
            "updatedAt": "2024-03-30T16:58:30.024Z"
        },
        "burger-king": {
            "names": [
                "Burger King",
                "Burger King Corporation",
                "BK",
                "BKC"
            ],
            "comment": "Burger King is an American fast food chain that has created sexist adverts [1] and is even less sustainable than McDonalds [2].",
            "sources": {
                "1": "https://www.huffingtonpost.co.uk/entry/burger-king-women-belong-in-the-kitchen_n_6046994dc5b6e6abac8214a3",
                "2": "https://d3.harvard.edu/platform-rctom/submission/burger-kings-sustainability-efforts-barely-trying/"
            },
            "tags": [
                "fast food",
                "restaurant",
                "discrimination",
                "unsustainable"
            ],
            "score": 15,
            "ownedBy": [
                "restaurant-brands-international"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/c/cc/Burger_King_2020.svg",
            "siteUrl": "http://bk.com/",
            "updatedAt": "2024-04-05T20:00:17.127Z"
        },
        "costa-coffee": {
            "names": [
                "Costa Coffee",
                "Costa",
                "Costa Limited"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "coffee",
                "beverages"
            ],
            "score": 10,
            "ownedBy": [
                "coca-cola"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/en/f/fa/Costa_Coffee_logo.svg",
            "siteUrl": "https://costa.co.uk/",
            "updatedAt": "2024-04-05T20:00:17.127Z"
        },
        "yum!-brands": {
            "names": [
                "Yum! Brands",
                "Yum Brands",
                "Yum! Brands, Inc.",
                "YUM"
            ],
            "comment": "Yum Brands is an American fast food company which owns KFC, Pizza Hut and Taco Bell. Its supply chains have involved mass animal cruelty [1][3] and is the biggest killer of chickens globally [2], it has fired employees for speaking out after being sexually abused [4] and illegally underpaid staff [5].",
            "sources": {
                "1": "https://www.theguardian.com/environment/2020/jul/30/kfc-admits-a-third-of-its-chickens-suffer-painful-inflammation",
                "2": "https://www.independent.co.uk/climate-change/news/kfc-is-cruel-to-chickens-says-animal-rights-group-5534360.html",
                "3": "https://www.worldanimalprotection.org.au/news/kfc-lagging-behind-competitors-chicken-welfare-fast-food-ranking",
                "4": "https://www.bbc.co.uk/news/uk-wales-67360703",
                "5": "https://news.sky.com/story/tesco-and-pizza-hut-named-and-shamed-over-minimum-wage-breaches-12176216"
            },
            "tags": [
                "fast food",
                "animal abuse",
                "sexual abuse",
                "bad working conditions"
            ],
            "score": 20,
            "ownedBy": [],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/5/59/Yum%21_Brands_logo.svg",
            "siteUrl": "https://yum.com/",
            "updatedAt": "2024-04-05T22:08:49.415Z"
        },
        "pizza-hut": {
            "names": [
                "Pizza Hut"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "fast food",
                "pizza"
            ],
            "score": 20,
            "ownedBy": [
                "yum!-brands"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/5/5d/Pizza_Hut_classic_logo.svg",
            "siteUrl": "http://pizzahut.com/",
            "updatedAt": "2024-04-05T20:00:17.127Z"
        },
        "kfc": {
            "names": [
                "KFC"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "fast food"
            ],
            "score": 20,
            "ownedBy": [
                "yum!-brands"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/en/5/57/KFC_logo-image.svg",
            "siteUrl": "https://kfc.com/",
            "updatedAt": "2024-04-05T20:00:17.127Z"
        },
        "taco-bell": {
            "names": [
                "Taco Bell"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "fast food"
            ],
            "score": 20,
            "ownedBy": [
                "yum!-brands"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/en/b/b3/Taco_Bell_2016.svg",
            "siteUrl": "https://tacobell.com/",
            "updatedAt": "2024-04-05T20:00:17.127Z"
        },
        "krispy-kreme": {
            "names": [
                "Krispy Kreme",
                "Krispy Kreme, Inc.",
                "Krispy Kreme Doughnuts, Inc.",
                "DNUT"
            ],
            "comment": "Krispy Kreme is an American doughnut company whose executives manipulated earnings reports and engaged in misleading practices for personal gain [1]. The company has also failed to comply with environmental regulations, avoiding costs for packaging waste recovery and recycle notes [2].",
            "sources": {
                "1": "https://marketrealist.com/p/krispy-kreme-accounting-scandal/",
                "2": "https://www.packagingnews.co.uk/news/doughnut-giant-fined-for-not-recycling-packaging-18-03-2011"
            },
            "tags": [
                "confectionery",
                "food"
            ],
            "score": 45,
            "ownedBy": [],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/8/8b/Logo.KrispyKreme.svg",
            "siteUrl": "http://krispykreme.com/",
            "updatedAt": "2024-04-07T14:42:36.411Z"
        },
        "nando's": {
            "names": [
                "Nando's",
                "Nandos"
            ],
            "comment": "Nandos is a South African restaurant chain whose supply chain has contained abhorrent animal cruelty [1][2] and whose advertising campaigns have taken advantage of imprisoned refugees on hunger strike [3].",
            "sources": {
                "1": "https://www.independent.co.uk/news/uk/home-news/lidl-nando-chicken-supplier-taunton-somerset-farm-deformed-birds-die-slowly-animal-rights-a7872226.html",
                "2": "https://www.dailymail.co.uk/news/article-7513231/Nandos-accused-using-chickens-farms-thousands-birds-kept-cramped-conditions.html",
                "3": "http://www.theage.com.au/articles/2002/07/28/1027818485483.html"
            },
            "tags": [
                "restaurant",
                "food",
                "animal abuse"
            ],
            "score": 35,
            "ownedBy": [],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/en/c/c5/Nandos_logo.svg",
            "siteUrl": "http://www.nandos.com/",
            "updatedAt": "2024-04-07T15:46:15.962Z"
        },
        "domino's": {
            "names": [
                "Domino's",
                "Domino's Pizza, Inc.",
                "DPZ"
            ],
            "comment": "Domino's is an American pizza brand that has been accused of exploiting workers [1].",
            "sources": {
                "1": "https://www.smh.com.au/interactive/2017/the-dominos-effect/"
            },
            "tags": [
                "fast food",
                "restaurant",
                "bad working conditions"
            ],
            "score": 45,
            "ownedBy": [],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/8/8b/DominosPizza.svg",
            "siteUrl": "https://dominos.com/",
            "updatedAt": "2024-04-07T14:42:36.411Z"
        },
        "pizzaexpress": {
            "names": [
                "PizzaExpress",
                "Pizza Express",
                "PizzaExpress (Restaurants) Limited",
                "Pizza Marzano"
            ],
            "comment": "PizzaExpress is a British restaurant chain that has taken money from customer tips to supplement wages, reducing the amount going to waiting staff [1][2]. The company also fired staff during the pandemic when enployees were most vulnerable, before rehiring on worse contracts [2].",
            "sources": {
                "1": "https://www.independent.co.uk/news/uk/home-news/revealed-how-the-restaurant-chains-pocket-your-tips-867634.html",
                "2": "https://www.socialistpartyni.org/analysis-news/britain/rights-wages-of-pizza-express-workers-sliced-and-burned/"
            },
            "tags": [
                "restaurant",
                "food",
                "bad working conditions"
            ],
            "score": 46,
            "ownedBy": [],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/4/46/Pizzaexpress.jpg",
            "siteUrl": "https://pizzaexpress.com/",
            "updatedAt": "2024-04-07T08:36:54.559Z"
        },
        "volkswagen": {
            "names": [
                "Volkswagen",
                "Volkswagen Group",
                "Volkswagen AG",
                "VW",
                "VOW"
            ],
            "comment": "Volkswagen is a German car manufacturer that illegally cheated in its vehicles' emissions tests, lying about their environmental performance to boost its profits [1][2].",
            "sources": {
                "1": "https://www.bbc.com/news/business-34324772",
                "2": "https://www.justice.gov/opa/pr/volkswagen-ag-agrees-plead-guilty-and-pay-43-billion-criminal-and-civil-penalties-six"
            },
            "tags": [
                "cars",
                "emissions cheating"
            ],
            "score": 35,
            "ownedBy": [],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/6/6d/Volkswagen_logo_2019.svg",
            "siteUrl": "https://www.volkswagen.com/",
            "updatedAt": "2024-04-16T17:45:46.620Z"
        },
        "audi": {
            "names": [
                "Audi",
                "Audi AG"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "cars"
            ],
            "score": 35,
            "ownedBy": [
                "volkswagen"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/9/92/Audi-Logo_2016.svg",
            "siteUrl": "https://www.audi.com/en.html",
            "updatedAt": "2024-04-16T17:45:46.620Z"
        },
        "bentley": {
            "names": [
                "Bentley",
                "Bentley Motors Limited",
                "Bentley Motors"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "cars"
            ],
            "score": 35,
            "ownedBy": [
                "volkswagen"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/en/e/e4/Bentley_logo_2.svg",
            "siteUrl": "http://bentleymotors.com/",
            "updatedAt": "2024-04-16T17:45:46.620Z"
        },
        "ducati": {
            "names": [
                "Ducati"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "motorbikes",
                "motorcycles"
            ],
            "score": 35,
            "ownedBy": [
                "volkswagen"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/3/36/Ducati_red_logo.svg",
            "siteUrl": "https://www.ducati.com/",
            "updatedAt": "2024-04-16T17:45:46.620Z"
        },
        "cupra": {
            "names": [
                "Cupra",
                "SEAT Cupra S.A.U."
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "cars"
            ],
            "score": 35,
            "ownedBy": [
                "volkswagen"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/7/70/Cupra.svg",
            "siteUrl": "http://www.cupraofficial.com/",
            "updatedAt": "2024-04-16T17:45:46.620Z"
        },
        "lamborghini": {
            "names": [
                "Lamborghini",
                "Automobili Lamborghini S.p.A."
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "cars"
            ],
            "score": 35,
            "ownedBy": [
                "volkswagen"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/en/d/df/Lamborghini_Logo.svg",
            "siteUrl": "http://lamborghini.com/en-en/",
            "updatedAt": "2024-04-16T17:45:46.620Z"
        },
        "porsche": {
            "names": [
                "Porsche",
                "Dr. Ing. h.c. F. Porsche AG",
                "Porsche Automobil Holding SE",
                "Porsche SE"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "cars"
            ],
            "score": 35,
            "ownedBy": [
                "volkswagen"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/en/8/8c/Porsche_logo.svg",
            "siteUrl": "https://www.porsche.com/",
            "updatedAt": "2024-04-16T17:45:46.620Z"
        },
        "seat": {
            "names": [
                "SEAT",
                "SEAT S.A."
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "cars"
            ],
            "score": 35,
            "ownedBy": [
                "volkswagen"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/7/7e/SEAT_S.A._logo.svg",
            "siteUrl": "http://www.seat.com/",
            "updatedAt": "2024-04-16T17:45:46.620Z"
        },
        "skoda": {
            "names": [
                "Škoda",
                "Skoda",
                "Škoda Auto",
                "Škoda Auto a.s."
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "cars"
            ],
            "score": 35,
            "ownedBy": [
                "volkswagen"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/0/09/%C5%A0koda_nieuw.png",
            "siteUrl": "http://skoda-auto.com/",
            "updatedAt": "2024-04-16T17:45:46.620Z"
        },
        "toyota": {
            "names": [
                "Toyota",
                "Toyota Motor Corporation",
                "トヨタ自動車株式会社",
                "Toyota Group",
                "トヨタグループ",
                "TYT",
                "TM"
            ],
            "comment": "Toyota is a Japanese automaker that has faced various ethical issues, including the death of an employee from overwork [1], a $180 million settlement for violating environmental standards [2], rigging vehicles to perform better in crash tests [3], and cheating in emissions testing [4].",
            "sources": {
                "1": "http://www.japantimes.co.jp/news/2007/12/01/national/nagoya-court-rules-toyota-employee-died-from-overwork/",
                "2": "https://web.archive.org/web/20210114205251/https://www.nytimes.com/2021/01/14/climate/toyota-emissions-fine.html",
                "3": "https://www.motor1.com/news/664748/toyota-admits-daihatsu-side-crash-tests-rigged/",
                "4": "https://apnews.com/article/japan-toyota-auto-daihatsu-scandal-bdfec73ce59ab336bc6ff1502ac3db46"
            },
            "tags": [
                "cars",
                "bad working conditions",
                "emissions cheating",
                "unsustainable"
            ],
            "score": 35,
            "ownedBy": [],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/e/e7/Toyota.svg",
            "siteUrl": "https://global.toyota/",
            "updatedAt": "2024-04-17T07:45:08.623Z"
        },
        "lexus": {
            "names": [
                "Lexus",
                "レクサス"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "cars"
            ],
            "score": 35,
            "ownedBy": [
                "toyota"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/en/d/d1/Lexus_division_emblem.svg",
            "siteUrl": "https://discoverlexus.com/lexus-in-your-region",
            "updatedAt": "2024-04-17T17:41:12.162Z"
        },
        "stellantis": {
            "names": [
                "Stellantis",
                "Stellantis N.V."
            ],
            "comment": "Stellantis is a multinational car conglomerate that cheated on emissions tests, resulting in a $300 million fine [1].",
            "sources": {
                "1": "https://edition.cnn.com/2022/06/03/business/stellantis-us-guilty-diesel-emissions/index.html"
            },
            "tags": [
                "cars",
                "emissions cheating"
            ],
            "score": 38,
            "ownedBy": [],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/d/dd/Stellantis.svg",
            "siteUrl": "https://www.stellantis.com/",
            "updatedAt": "2024-04-17T17:41:12.162Z"
        },
        "abarth": {
            "names": [
                "Abarth",
                "Abarth & C. S.p.A."
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "cars"
            ],
            "score": 38,
            "ownedBy": [
                "stellantis"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/en/c/cd/New_Fiat_Abarth_Logo.png",
            "siteUrl": "https://www.abarth.com/",
            "updatedAt": "2024-04-17T17:41:12.162Z"
        },
        "alfa-romeo": {
            "names": [
                "Alfa Romeo",
                "Alfa Romeo Automobiles S.p.A."
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "cars"
            ],
            "score": 38,
            "ownedBy": [
                "stellantis"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/en/2/2a/Alfa_Romeo_logo.png",
            "siteUrl": "https://www.alfaromeo.com/",
            "updatedAt": "2024-04-17T21:14:18.762Z"
        },
        "chrysler": {
            "names": [
                "Chrysler",
                "Chrysler Corporation"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "cars"
            ],
            "score": 38,
            "ownedBy": [
                "stellantis"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/d/dd/Stellantis.svg",
            "siteUrl": "https://media.stellantisnorthamerica.com/",
            "updatedAt": "2024-04-17T21:14:18.762Z"
        },
        "citroen": {
            "names": [
                "Citroën",
                "Citroen"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "cars"
            ],
            "score": 38,
            "ownedBy": [
                "stellantis"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/d/dd/Citroen_2022.svg",
            "siteUrl": "http://citroen.com/en",
            "updatedAt": "2024-04-17T21:14:18.762Z"
        },
        "dodge": {
            "names": [
                "Dodge"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "cars"
            ],
            "score": 38,
            "ownedBy": [
                "stellantis"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/b/bb/Dodge_black_logo.svg",
            "siteUrl": "https://www.dodge.com/",
            "updatedAt": "2024-04-17T21:14:18.762Z"
        },
        "ds-automobiles": {
            "names": [
                "DS Automobiles",
                "DS"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "cars"
            ],
            "score": 38,
            "ownedBy": [
                "stellantis"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/en/9/90/DS_Automobiles_logo.svg",
            "siteUrl": "https://www.dsautomobiles.com/en/home.html",
            "updatedAt": "2024-04-17T21:14:18.762Z"
        },
        "fiat": {
            "names": [
                "Fiat",
                "Fiat Automobiles S.p.A."
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "cars"
            ],
            "score": 38,
            "ownedBy": [
                "stellantis"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/b/b5/FIAT_logo_%282020%29.svg",
            "siteUrl": "https://www.fiat.com/",
            "updatedAt": "2024-04-17T21:14:18.762Z"
        },
        "jeep": {
            "names": [
                "Jeep"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "cars"
            ],
            "score": 38,
            "ownedBy": [
                "stellantis"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/9/92/Jeep_wordmark.svg",
            "siteUrl": "https://www.jeep.com/",
            "updatedAt": "2024-04-17T21:14:18.762Z"
        },
        "lancia": {
            "names": [
                "Lancia"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "cars"
            ],
            "score": 38,
            "ownedBy": [
                "stellantis"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/e/ed/Lancia_Logo_2023.svg",
            "siteUrl": "https://www.lancia.com/",
            "updatedAt": "2024-04-17T21:14:18.762Z"
        },
        "maserati": {
            "names": [
                "Maserati"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "cars"
            ],
            "score": 38,
            "ownedBy": [
                "stellantis"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/7/78/Maserati_logo_2.svg",
            "siteUrl": "http://www.maserati.com/",
            "updatedAt": "2024-04-17T21:14:18.762Z"
        },
        "peugeot": {
            "names": [
                "Peugeot"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "cars"
            ],
            "score": 38,
            "ownedBy": [
                "stellantis"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/f/f7/Peugeot_Logo.svg",
            "siteUrl": "https://www.peugeot.com/en/",
            "updatedAt": "2024-04-17T21:14:18.762Z"
        },
        "vauxhall": {
            "names": [
                "Vauxhall",
                "Vauxhall Motors",
                "Vauxhall Motors Limited"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "cars"
            ],
            "score": 38,
            "ownedBy": [
                "stellantis"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/en/1/18/Vauxhall_logo_2019.svg",
            "siteUrl": "https://vauxhall.co.uk/",
            "updatedAt": "2024-04-17T21:14:18.762Z"
        },
        "mercedes-benz": {
            "names": [
                "Mercedes-Benz",
                "Mercedes-Benz AG",
                "Mercedes-Benz Group",
                "Mercedes-Benz Group AG",
                "Daimler-Benz",
                "MBG"
            ],
            "comment": "Mercedez-Benz is a German car company that has been fined $1 billion for illegally cheating on vehicle emissions tests [1].",
            "sources": {
                "1": "https://www.theverge.com/2019/9/24/20881603/daimler-dieselgate-mercedes-benz-emissions-cheating-fine"
            },
            "tags": [
                "cars",
                "emissions cheating"
            ],
            "score": 40,
            "ownedBy": [],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/e/e1/Mercedes-Benz_Group_black.svg",
            "siteUrl": "http://group.mercedes-benz.com/",
            "updatedAt": "2024-04-17T21:14:18.762Z"
        },
        "honda": {
            "names": [
                "Honda",
                "Honda Motor Co., Ltd.",
                "本田技研工業株式会社",
                "HMC"
            ],
            "comment": "Honda is a Japanese automobile manufacturer whise executives stole millions through bribes and fraud [1], has sold cars with defective airbags [3], and has settled the largest Clean Air Act violation in history by paying $267 million for selling vehicles with disabled emission control systems [3].",
            "sources": {
                "1": "https://www.independent.co.uk/news/business/us-bribe-scandal-rocks-honda-former-car-company-executives-accused-of-dollars-10m-fraud-spanning-14-years-1429462.html",
                "2": "https://consent.yahoo.com/v2/collectConsent?sessionId=3_cc-session_a61f87ce-6924-4aca-ac4e-bf3234cbbe45",
                "3": "https://www.epa.gov/enforcement/american-honda-motor-company-clean-air-act-settlement"
            },
            "tags": [
                "cars",
                "endangerment",
                "emissions cheating"
            ],
            "score": 38,
            "ownedBy": [],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/7/76/Honda_logo.svg",
            "siteUrl": "https://global.honda/",
            "updatedAt": "2024-04-17T21:14:18.762Z"
        },
        "nissan": {
            "names": [
                "Nissan",
                "Nissan Motor Co. Ltd.",
                "日産自動車株式会社",
                "TYO",
                "TOPIX"
            ],
            "comment": "Nissan is a Japanese automaker embroiled in scandals, including a high-profile case of financial misconduct by its former chairman Carlos Ghosn, who was accused of hiding $79 million in earnings and fleeing Japan while under house arrest [1], as well as using cheat devices to manipulate emissions tests in South Korea and falsifying data in Japanese factories [2][3].",
            "sources": {
                "1": "https://auto.hindustantimes.com/auto/cars/nissan-scandal-tokyo-court-determines-carlos-ghosn-main-culprit-41646614640853.html",
                "2": "https://www.autocar.co.uk/car-news/business/nissan-found-guilty-using-diesel-emissions-cheat-device-south-korea",
                "3": "https://www.bbc.co.uk/news/business-44763905"
            },
            "tags": [
                "cars",
                "emissions cheating"
            ],
            "score": 37,
            "ownedBy": [],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/b/bb/Nissan_Motor_Corporation_2020_logo-local_file.svg",
            "siteUrl": "https://www.nissan-global.com/",
            "updatedAt": "2024-04-19T07:30:48.152Z"
        },
        "hyundai": {
            "names": [
                "Hyundai",
                "Hyundai Motor Company",
                "Hyundai Motors"
            ],
            "comment": "Hyundai is a South Korean car manufacturer that falsely claimed its vehicles were more fuel-efficient than they were [1], illegally employed child refugees in their US factories [2], and published an advert that depucted suicide [3]. Hyundai also sold vehicles that cheated on emission tests [4].",
            "sources": {
                "1": "https://web.archive.org/web/20121205051247/http://www.forbes.com/sites/jimgorzelany/2012/11/02/epa-slams-hyundai-and-kia-for-overestimating-mpg/",
                "2": "https://www.reuters.com/world/us/how-fake-id-repeatedly-enabled-hyundai-suppliers-employ-child-labor-alabama-2023-04-27/",
                "3": "https://web.archive.org/web/20160401175224/http://adage.com/article/news/hyundai-apologizes-u-k-ad-depicts-suicide-attempt/241119/",
                "4": "https://cleantechnica.com/2022/07/04/another-diesel-cheating-scandal-this-time-its-hyundai-kia/"
            },
            "tags": [
                "cars",
                "emissions cheating",
                "child labour"
            ],
            "score": 35,
            "ownedBy": [],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/7/78/Hyundai_Motor_Group_CI.svg",
            "siteUrl": "http://hyundaimotorgroup.com/",
            "updatedAt": "2024-04-19T07:30:48.152Z"
        },
        "kia": {
            "names": [
                "Kia",
                "Kia Corporation",
                "기아",
                "Kyungsung Precision Industry",
                "Kia Motors Corporation",
                "KRX"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "cars"
            ],
            "score": 35,
            "ownedBy": [
                "hyundai"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/b/b6/KIA_logo3.svg",
            "siteUrl": "https://kia.com/",
            "updatedAt": "2024-04-19T07:30:48.152Z"
        },
        "renault": {
            "names": [
                "Renault",
                "Groupe Renault",
                "Renault Group",
                "Renault S.A.",
                "RNO",
                "CAC"
            ],
            "comment": "Renault is a French automaker that has faced criticism for its decision to continue operating in Russia despite the country's invasion of Ukraine, with Ukrainian President Volodymyr Zelensky calling on the company to leave the Russian market [1]. Renault has also been charged with deception after its vehicles were found to be cheating in emissions tests [2].",
            "sources": {
                "1": "https://www.france24.com/en/europe/20220323-ukraine-s-zelensky-shames-french-companies-still-operating-in-russia-calls-on-them-to-leave",
                "2": "https://www.reuters.com/business/autos-transportation/frances-renault-charged-with-deception-over-dieselgate-probe-2021-06-08/"
            },
            "tags": [
                "cars",
                "russia",
                "emissions cheating"
            ],
            "score": 35,
            "ownedBy": [],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/0/09/2021_Renault_Group_logo.svg",
            "siteUrl": "https://www.renaultgroup.com/en/",
            "updatedAt": "2024-04-19T15:34:09.496Z"
        },
        "dacia": {
            "names": [
                "Dacia",
                "Automobile Dacia",
                "S.C. Automobile Dacia S.A."
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "cars"
            ],
            "score": 35,
            "ownedBy": [
                "renault"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/4/41/Dacia-Logo-2021.svg",
            "siteUrl": "http://dacia.ro/",
            "updatedAt": "2024-04-19T15:56:44.496Z"
        },
        "volvo": {
            "names": [
                "Volvo",
                "Volvo Cars",
                "VOLV"
            ],
            "comment": "Volvo is a Swedish automaker that has pledged to build greener cars in the future, but has been accused of installing \"cheat devices\" in its diesel vehicles to manipulate emissions tests, resulting in harmful NOx emissions exceeding legal limits [1]. The company's diesel models have been found to emit toxic fumes, affecting up to 320,000 vehicles on UK roads, and it is now facing a legal claim from affected owners, contradicting its reputation for trusted and reliable vehicles [1].",
            "sources": {
                "1": "https://www.leighday.co.uk/news/news/2021-news/volvo-faces-legal-claim-over-toxic-emissions-from-diesel-vehicles/"
            },
            "tags": [
                "cars",
                "emissions cheating"
            ],
            "score": 40,
            "ownedBy": [
                "geely"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/c/cd/Volvo-Spread-Word-Mark-Black.svg",
            "siteUrl": "https://www.volvogroup.com/",
            "updatedAt": "2024-04-19T15:56:44.496Z"
        },
        "polestar": {
            "names": [
                "Polestar",
                "PSNY"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "cars"
            ],
            "score": 40,
            "ownedBy": [
                "volvo"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/f/f0/Polestar_Logo.svg",
            "siteUrl": "https://www.polestar.com/uk/",
            "updatedAt": "2024-04-19T16:31:06.067Z"
        },
        "suzuki": {
            "names": [
                "Suzuki",
                "Suzuki Motor Corporation",
                "スズキ株式会社"
            ],
            "comment": "Suzuki is a Japanese car manufacturer that has been caught cheating on its vehicles' emissions tests [1] and inflating fuel economy numbers [2].",
            "sources": {
                "1": "https://www.reuters.com/business/autos-transportation/german-prosecutors-mount-raids-over-suzuki-cars-alleged-test-dodging-2022-04-27/",
                "2": "https://uk.finance.yahoo.com/news/suzuki-suspected-using-improper-fuel-033613366.html?guccounter=1"
            },
            "tags": [
                "cars",
                "emissions cheating"
            ],
            "score": 40,
            "ownedBy": [],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/1/12/Suzuki_logo_2.svg",
            "siteUrl": "http://www.globalsuzuki.com/",
            "updatedAt": "2024-04-19T16:31:06.067Z"
        },
        "mazda": {
            "names": [
                "Mazda",
                "Mazda Motor Corporation",
                "マツダ株式会社"
            ],
            "comment": "Mazda is a Japanese car company that has been implicated in faking emmissions tests [1].",
            "sources": {
                "1": "https://asia.nikkei.com/Business/Companies/Suzuki-and-Mazda-admit-faking-emissions-tests"
            },
            "tags": [
                "cars",
                "emissions cheating"
            ],
            "score": 30,
            "ownedBy": [],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/en/1/18/Mazda_logo_with_emblem.svg",
            "siteUrl": "https://www.mazda.com/",
            "updatedAt": "2024-04-19T16:31:06.067Z"
        },
        "jaguar-land-rover": {
            "names": [
                "Jaguar Land Rover",
                "JLR",
                "Jaguar",
                "Land Rover",
                "Range Rover"
            ],
            "comment": "Jaguar Land Rover is a British luxury car manufacturer that is accused of fitting emissions cheat devices to its diesel vehicles, deceiving owners and contributing to excessive NOx emissions and climate harm [1], and has recalled over 44,000 cars for producing excessive CO2 emissions, requiring software updates and repairs [2].",
            "sources": {
                "1": "https://www.leighday.co.uk/news/news/2021-news/jaguar-land-rover-latest-manufacturer-to-face-claims-for-emissions-cheat-device/",
                "2": "https://www.which.co.uk/news/article/jaguar-and-land-rover-cars-recalled-for-excessive-co2-emissions-aN2wQ5I139PU"
            },
            "tags": [
                "cars",
                "emissions cheating"
            ],
            "score": 40,
            "ownedBy": [
                "tata"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/6/6f/Jaguar_Land_Rover_logo_2023.svg",
            "siteUrl": "https://www.jaguarlandrover.com/",
            "updatedAt": "2024-04-19T22:32:22.251Z"
        },
        "mitsubishi": {
            "names": [
                "Mitsubishi",
                "Mitsubishi Motors",
                "Mitsubishi Motors Corporation",
                "三菱自動車工業株式会社"
            ],
            "comment": "Mitsubishi is a Japanese car company that has lied about its vehicles' fuel efficiency for 25 years [1].",
            "sources": {
                "1": "https://money.cnn.com/2016/04/26/news/companies/mitsubishi-cheating-fuel-tests-25-years/index.html"
            },
            "tags": [
                "cars"
            ],
            "score": 35,
            "ownedBy": [],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/5/5a/Mitsubishi_logo.svg",
            "siteUrl": "https://www.mitsubishi.com/en/",
            "updatedAt": "2024-04-19T22:32:22.251Z"
        },
        "general-motors": {
            "names": [
                "General Motors",
                "GM",
                "General Motors Company",
                "General Motors Corporation"
            ],
            "comment": "General Motors is an American car manufacturer that provided services to perpetuate the Apartheid in South Africa [1], has benefitted from Uyghur forced labour [2], and sold cars with faulty ignition switches which have led to 13 deaths [3].",
            "sources": {
                "1": "https://www.reuters.com/article/safrica-apartheid-gm-idCNL5E8E11YJ20120301",
                "2": "https://web.archive.org/web/20200824215335/https://www.aspi.org.au/report/uyghurs-sale",
                "3": "https://www.vox.com/2014/10/3/18073458/gm-car-recall"
            },
            "tags": [
                "cars",
                "forced labour",
                "endangerment"
            ],
            "score": 15,
            "ownedBy": [],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/en/e/ea/General_Motors_%28logo_with_wordmark%2C_horizontal%29.svg",
            "siteUrl": "https://gm.com/",
            "updatedAt": "2024-04-20T08:18:44.669Z"
        },
        "buick": {
            "names": [
                "Buick",
                "Buick Motor Company"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "cars"
            ],
            "score": 15,
            "ownedBy": [
                "general-motors"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/en/2/2f/Buick_logo_2023.svg",
            "siteUrl": "https://www.buick.com/",
            "updatedAt": "2024-04-20T08:18:44.669Z"
        },
        "cadillac": {
            "names": [
                "Cadillac",
                "Cadillac Motor Car Division"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "cars"
            ],
            "score": 15,
            "ownedBy": [
                "general-motors"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/4/43/Cadillac_simple_logo.svg",
            "siteUrl": "https://www.cadillac.com/",
            "updatedAt": "2024-04-20T08:18:44.669Z"
        },
        "chevrolet": {
            "names": [
                "Chevrolet",
                "Chevy",
                "Chevrolet Motor Company"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "cars"
            ],
            "score": 15,
            "ownedBy": [
                "general-motors"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/en/b/bd/Chevrolet_%28logo%29.svg",
            "siteUrl": "https://www.chevrolet.com/",
            "updatedAt": "2024-04-20T08:18:44.669Z"
        },
        "gmc": {
            "names": [
                "GMC",
                "General Motors Truck Company"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "cars"
            ],
            "score": 15,
            "ownedBy": [
                "general-motors"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/f/f9/GMC-Logo_2.svg",
            "siteUrl": "https://www.gmc.com/",
            "updatedAt": "2024-04-20T08:18:44.669Z"
        },
        "uber": {
            "names": [
                "Uber",
                "Uber Technologies, Inc."
            ],
            "comment": "Uber is an American tech company that refuses to provide standard employee protections to its drivers [1], misled drivers about potential earnings [4], underpaid its drivers [5], and has had a culture of workplace sexual harassment [2][3]. Uber has avoided taxes using offshore companies [6], has discriminated against disabled passengers [7], and has conducted invasive surveillance on its passengers' journey data [8].",
            "sources": {
                "1": "https://web.archive.org/web/20211031160651/https://www.theguardian.com/business/2021/aug/27/gig-workers-massachusetts-lawsuit-independent-contractor-status",
                "2": "https://web.archive.org/web/20170607072309/https://www.theguardian.com/technology/2017/jun/06/uber-fires-employees-sexual-harassment-investigation",
                "3": "https://web.archive.org/web/20180909191906/https://money.cnn.com/2018/08/21/technology/uber-settlement/index.html",
                "4": "https://www.reuters.com/article/us-uber-ftc/uber-to-pay-20-million-to-settle-u-s-claims-it-misled-drivers-idUSKBN15334C",
                "5": "https://web.archive.org/web/20190109121055/https://www.theguardian.com/technology/2017/may/23/uber-underpaid-drivers-new-york-city",
                "6": "https://web.archive.org/web/20180808002415/https://www.dw.com/en/paradise-papers-expose-tax-schemes-of-global-elite/a-41246087",
                "7": "https://www.cnn.com/2021/04/02/business/uber-blind-passenger-arbitration-trnd/index.html",
                "8": "https://web.archive.org/web/20120729043726/http://blog.uber.com/2012/03/26/uberdata-the-ride-of-glory/"
            },
            "tags": [
                "tech",
                "transport",
                "bad working conditions",
                "sexual harassment",
                "antiprivacy",
                "tax avoidance"
            ],
            "score": 30,
            "ownedBy": [],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/5/58/Uber_logo_2018.svg",
            "siteUrl": "https://uber.com/",
            "updatedAt": "2024-04-20T08:48:25.117Z"
        },
        "disney": {
            "names": [
                "Disney",
                "The Walt Disney Company",
                "Walt Disney Studio",
                "Walt Disney",
                "DSNY"
            ],
            "comment": "Disney is an American media conglomerate whose factories have inflicted inhumane working conditions on employees [1], has plagarised from independent creators [2], has filmed in the Xinjang region where human rights abuses are taking place [3], and has stolen the likeness of extras in its films to use for AI character synthesis [4]. Disney has covered up sexual harassment it enabled executives to commit [5] and underpaid female employees [6], and owned the studio Harvey Weinstein was working for when he sexually assaulted multiple women [7].",
            "sources": {
                "1": "https://issuu.com/iglhr/docs/1212_dream_international_toys_china_408b569c59015f",
                "2": "https://www.ew.com/article/2015/06/25/disney-frozen-lawsuit-the-snowman-settlement/",
                "3": "https://www.bbc.com/news/world-54064654",
                "4": "https://screenrant.com/prom-pact-movie-background-actors-digital-scans-clip/",
                "5": "https://www.hollywoodreporter.com/business/business-news/disney-employee-sues-alleged-cover-up-of-sexual-assault-1235779014/#!",
                "6": "https://www.theguardian.com/us-news/2023/jun/30/disney-female-employees-wage-disparity-lawsuit",
                "7": "https://www.huffingtonpost.co.uk/entry/disney-harvey-weinstein_n_59f8a894e4b00c6145e1e72b"
            },
            "tags": [
                "media",
                "films",
                "entertainment",
                "tv",
                "forced labour",
                "sexual harassment"
            ],
            "score": 20,
            "ownedBy": [],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/f/fe/Disney_Experiences_logo.svg",
            "siteUrl": "http://thewaltdisneycompany.com/",
            "updatedAt": "2024-04-20T08:48:25.117Z"
        },
        "next": {
            "names": [
                "Next",
                "Next plc",
                "NEXT",
                "J Hepworth & Son",
                "NXT"
            ],
            "comment": "Next is a British home & clothing retailer that makes use of forced Uyghur labour [1], failed to pay employees a living wage [2][3] and destroyed evidence in an equal pay claim case [4].",
            "sources": {
                "1": "https://www.shu.ac.uk/-/media/home/research/helena-kennedy-centre/projects/eu-apparel/eu-tailoring-responsibility-february-24.pdf",
                "2": "http://www.thetimes.co.uk/tto/opinion/columnists/article4226577.ece",
                "3": "https://www.bbc.co.uk/news/uk-england-leicestershire-27431336",
                "4": "https://www.theguardian.com/business/2020/oct/21/next-accused-of-destroying-documents-in-equal-pay-claim"
            },
            "tags": [
                "clothing",
                "retail"
            ],
            "score": 44,
            "ownedBy": [],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/en/c/c2/Next_plc_Logo.svg",
            "siteUrl": "https://www.next.co.uk/",
            "updatedAt": "2024-04-28T14:14:56.468Z"
        },
        "joules": {
            "names": [
                "Joules"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "clothing",
                "retail"
            ],
            "score": 44,
            "ownedBy": [
                "next"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/en/d/dc/Joules_UK_logo.jpg",
            "siteUrl": "https://www.joules.com/",
            "updatedAt": "2024-04-28T20:20:17.185Z"
        },
        "cath-kidston": {
            "names": [
                "Cath Kidston"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "clothing",
                "retail"
            ],
            "score": 44,
            "ownedBy": [
                "next"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/f/f5/Cath_Kidston_london_logo.png",
            "siteUrl": "http://www.cathkidston.co.uk/",
            "updatedAt": "2024-04-28T20:20:17.185Z"
        },
        "made.com": {
            "names": [
                "Made.com",
                "made.com",
                "made",
                "MADE"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "furniture",
                "retail"
            ],
            "score": 44,
            "ownedBy": [
                "next"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/en/8/8b/MADE.COM_Logo.jpg",
            "siteUrl": "https://www.made.com/",
            "updatedAt": "2024-04-28T20:20:17.185Z"
        },
        "lipsy": {
            "names": [
                "Lipsy"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "clothing",
                "retail"
            ],
            "score": 44,
            "ownedBy": [
                "next"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/a/a4/Lipsy_Master-logo-full.jpg",
            "siteUrl": "http://next.co.uk/lipsy",
            "updatedAt": "2024-04-28T20:20:17.185Z"
        },
        "mytos": {
            "names": [
                "Mytos"
            ],
            "comment": "Mytos is a British biotech startup which creates systems to automate human cell manufacture, with the potential to underpin breakthroughs in drug discovery and cell therapy [1].",
            "sources": {
                "1": "https://www.forbes.com/sites/alexknapp/2023/11/30/this-startup-just-raised-19-million-to-bring-an-industrial-revolution-to-biology/"
            },
            "tags": [
                "health",
                "tech"
            ],
            "score": 85,
            "ownedBy": [],
            "logoUrl": "https://assets-global.website-files.com/62042a797b21a1c243311ba5/62043273b7d6bb2c7fbe8886_Mytos%20logo.svg",
            "siteUrl": "https://www.mytos.bio/",
            "updatedAt": "2024-04-29T06:50:06.453Z"
        },
        "the-times": {
            "names": [
                "The Times",
                "The Daily Universal Register"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "news"
            ],
            "score": 25,
            "ownedBy": [
                "news-corp"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/0/02/The_Times_masthead.svg",
            "siteUrl": "https://thetimes.co.uk/",
            "updatedAt": "2024-05-29T16:01:37.989Z"
        },
        "the-sunday-times": {
            "names": [
                "The Sunday Times",
                "The New Observer"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "news"
            ],
            "score": 25,
            "ownedBy": [
                "news-corp"
            ],
            "logoUrl": "https://media.info/l/o/1/1575.1405339476.png",
            "siteUrl": "thesundaytimes.co.uk",
            "updatedAt": "2024-05-29T16:01:37.989Z"
        },
        "the-sun": {
            "names": [
                "The Sun"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "news"
            ],
            "score": 25,
            "ownedBy": [
                "news-corp"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/3/3b/The_Sun.svg",
            "siteUrl": "https://thesun.co.uk/",
            "updatedAt": "2024-05-29T16:01:37.989Z"
        },
        "dow-jones": {
            "names": [
                "Dow Jones",
                "Dow Jones & Company",
                "Dow Jones & Company, Inc."
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "news"
            ],
            "score": 25,
            "ownedBy": [
                "news-corp"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/f/f8/Dow_Jones_Logo.svg",
            "siteUrl": "http://dowjones.com/",
            "updatedAt": "2024-05-29T16:01:37.989Z"
        },
        "the-wall-street-journal": {
            "names": [
                "The Wall Street Journal"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "news"
            ],
            "score": 25,
            "ownedBy": [
                "news-corp"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/4/4a/WSJ_Logo.svg",
            "siteUrl": "http://wsj.com/",
            "updatedAt": "2024-05-29T16:01:37.989Z"
        },
        "harpercollins": {
            "names": [
                "HarperCollins"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "books"
            ],
            "score": 25,
            "ownedBy": [
                "news-corp"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/en/0/00/Harpercollins-logo.svg",
            "siteUrl": "https://harpercollins.com/",
            "updatedAt": "2024-05-29T16:01:37.989Z"
        },
        "news-of-the-world": {
            "names": [
                "News of the World"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "news"
            ],
            "score": 25,
            "ownedBy": [
                "news-corp"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/9/94/News_of_the_World_Logo_2009.svg",
            "siteUrl": "http://www.newsoftheworld.co.uk/",
            "updatedAt": "2024-05-29T16:01:37.989Z"
        },
        "news-corp": {
            "names": [
                "News Corp",
                "News Corporation",
                "NWSA",
                "NWS",
                "NWSLV"
            ],
            "comment": "News Corp is an American media conglomerate owned by Rupert Murdoch which \"lacks any ethical base\" [1], having engaged in police corruption and bribery [2][3], and hacked the phones of murder victims [4] and those killed in 9/11 [5]. ",
            "sources": {
                "1": "https://www.huffpost.com/entry/murdoch-media-empire-a-jo_b_896836",
                "2": "https://www.theguardian.com/media/2011/mar/11/news-of-the-world-police-corruption",
                "3": "https://www.bbc.co.uk/news/uk-16996275",
                "4": "https://www.theguardian.com/uk/2011/jul/04/milly-dowler-family-phone-hacking",
                "5": "https://www.mirror.co.uk/2011/07/11/phone-hacking-9-11-victims-may-have-had-mobiles-tapped-by-news-of-the-world-reporters-115875-23262694/"
            },
            "tags": [
                "news"
            ],
            "score": 25,
            "ownedBy": [
                "news-corp"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/5/55/News_Corp_logo_2013.svg",
            "siteUrl": "http://newscorp.com/",
            "updatedAt": "2024-05-29T16:01:37.989Z"
        },
        "levi's": {
            "names": [
                "Levi's",
                "Levi Strauss",
                "Levi Strauss & Co.",
                "Levi"
            ],
            "comment": "Levi's is an American clothing brand that has used AI to generate images modelling their products [1] and has usied toxic chemicals in their clothes [2] linked to water pollution in Mexico. Levis was also suspended from the Ethical Trading Initiative for refusing to pay a living wage [3][4]",
            "sources": {
                "1": "https://www.independent.co.uk/life-style/fashion/levis-ai-models-diversity-backlash-b2310280.html",
                "2": "https://www.greenpeace.org/international/publication/6889/toxic-threads-the-big-fashion-stitch-up/",
                "3": "https://www.business-humanrights.org/en/latest-news/levi-strauss-drops-from-first-to-fifth-place-in-etag-report-card-after-being-suspended-from-eti-for-refusing-to-commit-to-living-wage/",
                "4": "https://en.archive.maquilasolidarity.org/en/node/369"
            },
            "tags": [
                "clothing"
            ],
            "score": 40,
            "ownedBy": [],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/0/09/Levis-logo-quer.svg",
            "siteUrl": "https://www.levistrauss.com/",
            "updatedAt": "2024-06-06T18:53:03.266Z"
        },
        "highland-spring": {
            "names": [
                "Highland Spring"
            ],
            "comment": "Highland Spring is the UK's leading natural source water brand which has sold water containing toxic phthalates [2] and microplastics [3], alongside a poor environmental footprint [3]. Highland Spring was the first brand to launch a 100% recycled bottle [1].",
            "sources": {
                "1": "https://www.bbc.co.uk/news/uk-scotland-scotland-business-44404453",
                "2": "https://www.standard.co.uk/hp/front/dangerous-chemical-found-in-bottled-water-6934399.html",
                "3": "https://www.telegraph.co.uk/food-and-drink/drinks/the-great-bottled-water-scandal/"
            },
            "tags": [
                "beverages"
            ],
            "score": 30,
            "ownedBy": [],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/en/f/f6/Highland_Spring_logo.jpg",
            "siteUrl": "https://www.highlandspring.com/",
            "updatedAt": "2024-06-09T08:06:36.089Z"
        },
        "tp-link": {
            "names": [
                "TP Link",
                "TP-Link"
            ],
            "comment": "TP-Link is a Singaporean electronics manufacturer which has not posted a sustainability report on their website since 2022 [1].",
            "sources": {
                "1": "https://web.archive.org/web/20240715143848/https://www.tp-link.com/uk/sustainability/"
            },
            "tags": [
                "electronics"
            ],
            "score": 48,
            "ownedBy": [],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/d/d0/TPLINK_Logo_2.svg",
            "siteUrl": "https://www.tp-link.com/en/",
            "updatedAt": "2024-07-21T08:06:36.089Z"
        },
        "anker": {
            "names": [
                "Anker",
                "Anker Innovations",
                "安克",
                "Anker Innovations Co., Ltd",
                "Oceanwing"
            ],
            "comment": "Anker is a Chinese electronics company that has claimed not to store user data while sending images to its cloud servers [1].",
            "sources": {
                "1": "https://www.howtogeek.com/138235/why-review-geek-cant-recommend-wyze-or-eufy-cameras-anymore/"
            },
            "tags": [
                "electronics",
                "china",
                "antiprivacy"
            ],
            "score": 38,
            "ownedBy": [],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/9/9c/Anker_logo.svg",
            "siteUrl": "https://anker.com/",
            "updatedAt": "2024-07-16T18:27:48.537Z"
        },
        "eufy": {
            "names": [
                "eufy"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "electronics",
                "china",
                "antiprivacy"
            ],
            "score": 38,
            "ownedBy": [
                "anker"
            ],
            "logoUrl": "https://tse1.mm.bing.net/th?id=OIP.-FydNiaKlphq7je0BVEUYQHaHa&pid=Api",
            "siteUrl": "https://www.eufy.com/",
            "updatedAt": "2024-07-16T18:27:48.537Z"
        },
        "soundcore": {
            "names": [
                "Soundcore"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "electronics",
                "china",
                "antiprivacy"
            ],
            "score": 38,
            "ownedBy": [
                "anker"
            ],
            "logoUrl": "https://cdn.shopify.com/s/files/1/0501/7678/6607/files/logo.png?v=1681995409",
            "siteUrl": "https://uk.soundcore.com/",
            "updatedAt": "2024-07-16T18:27:48.537Z"
        },
        "black-sheep-coffee": {
            "names": [
                "Black Sheep Coffee"
            ],
            "comment": "Black Sheep Coffee is a British coffee shop chain that claims to stand up to corporate giants and support homeless people [1], but sources close to the company's executives have described this messaging as performative [original research].",
            "sources": {
                "1": "https://archive.is/WVEk5"
            },
            "tags": [
                "beverages",
                "food"
            ],
            "score": 44,
            "ownedBy": [],
            "logoUrl": "https://cdn.shopify.com/s/files/1/0013/8530/0032/files/creaml.svg",
            "siteUrl": "https://leavetheherdbehind.com/",
            "updatedAt": "2024-07-22T06:11:30.920Z"
        },
        "ebay": {
            "names": [
                "eBay",
                "eBay, Inc.",
                "ebay",
                "EBAY"
            ],
            "comment": "eBay is an American ecommerce company which has avoided tax [1] and been ranked as the worst ecommerce site for carbon emissions [2], while eBay employees launched a harassment and stalking campaign against a journalist [3]. eBay sales saved 87 million items from landfill in 2021 [4].",
            "sources": {
                "1": "https://web.archive.org/web/20201127180330/https://www.bbc.co.uk/news/business-20022365",
                "2": "https://web.archive.org/web/20220626015517/https://www.chargedretail.co.uk/2022/03/22/ebay-amazon-ecommerce-carbon/",
                "3": "https://web.archive.org/web/20201027154507/https://www.bostonglobe.com/2020/06/15/metro/six-former-ebay-employees-charged-federal-cyberstalking-case-targeting-natick-couple/",
                "4": "https://web.archive.org/web/20221121235643/https://www.independent.co.uk/climate-change/sustainable-living/ebay-second-hand-fashion-landfill-sustainable-b1913771.html"
            },
            "tags": [
                "ecommerce",
                "retail"
            ],
            "score": 60,
            "ownedBy": [],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/1/1b/EBay_logo.svg",
            "siteUrl": "https://www.ebay.com/",
            "updatedAt": "2024-07-27T08:02:18.176Z"
        },
        "moonpig": {
            "names": [
                "Moonpig",
                "MOON"
            ],
            "comment": "Moonpig is a British gift retailer that has donated hundreds of thousands of pounds to charity [1] and creating opportunities for underrepresented groups.  Moonpig has leaked customer information [2] and created 'insensitive' greetings cards [3].",
            "sources": {
                "1": "https://web.archive.org/web/20220811121056/https://www.moonpig.group/responsibility/our-communities/",
                "2": "https://realbusiness.co.uk/moonpig-com-api-chaos-shows-there-is-no-excuse-for-organisations-not-to-fix-problems",
                "3": "https://www.walesonline.co.uk/lifestyle/tv/moonpig-remove-divisive-nella-card-28211586"
            },
            "tags": [
                "retail"
            ],
            "score": 50,
            "ownedBy": [],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/en/a/aa/Moonpig_Logo.svg",
            "siteUrl": "http://moonpig.com/",
            "updatedAt": "2024-07-27T08:19:15.220Z"
        },
        "supreme": {
            "names": [
                "Supreme"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "clothing"
            ],
            "score": 44,
            "ownedBy": [
                "essilorluxottica"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/2/28/Supreme_Logo.svg",
            "siteUrl": "http://supreme.com/",
            "updatedAt": "2024-10-03T06:49:27.232Z"
        },
        "essilorluxottica": {
            "names": [
                "EssilorLuxottica",
                "EssilorLuxottica SA",
                "Luxottica",
                "Essilor",
                "EL"
            ],
            "comment": "EssilorLuxottica is an Italian-French eyewear conglomerate that has engaged in anticompetetive [1] and anti-union practices [2].",
            "sources": {
                "1": "https://www.pymnts.com/cpi-posts/eyewear-manufacturer-essilorluxottica-accused-of-artificially-inflating-prices/",
                "2": "https://www.reuters.com/business/ray-ban-maker-luxottica-accused-anti-union-behaviour-us-georgia-plant-2021-07-15/"
            },
            "tags": [
                "clothing",
                "anticompetetive",
                "antiunion"
            ],
            "score": 44,
            "ownedBy": [],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/e/ed/Logo_EssilorLuxottica.svg",
            "siteUrl": "http://essilorluxottica.com/",
            "updatedAt": "2024-10-03T06:49:27.232Z"
        },
        "sunglass-hut": {
            "names": [
                "Sunglass Hut"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "clothing"
            ],
            "score": 44,
            "ownedBy": [
                "essilorluxottica"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/a/a4/Sunglasshutnew.jpg",
            "siteUrl": "http://sunglasshut.com/",
            "updatedAt": "2024-10-03T06:49:27.232Z"
        },
        "varilux": {
            "names": [
                "Varilux"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "clothing"
            ],
            "score": 44,
            "ownedBy": [
                "essilorluxottica"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/en/7/7e/VariluxFrenchLogo.jpg",
            "siteUrl": "http://www.varilux.com/",
            "updatedAt": "2024-10-03T06:49:27.232Z"
        },
        "ray-ban": {
            "names": [
                "Ray-Ban",
                "RayBan"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "clothing"
            ],
            "score": 44,
            "ownedBy": [
                "essilorluxottica"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/d/d1/Ray-Ban_logo.svg",
            "siteUrl": "http://ray-ban.com/",
            "updatedAt": "2024-10-03T06:49:27.232Z"
        },
        "oakley": {
            "names": [
                "Oakley",
                "Oakley, Inc."
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "clothing"
            ],
            "score": 44,
            "ownedBy": [
                "essilorluxottica"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/8/88/Oakley_logo.svg",
            "siteUrl": "http://www.oakley.com/",
            "updatedAt": "2024-10-03T06:49:27.232Z"
        },
        "under-armour": {
            "names": [
                "Under Armour",
                "Under Armour, Inc.",
                "UA",
                "UAA"
            ],
            "comment": "Under Armour is an American clothing and sportswear company thats has created advertising campaigns featuring trophy hunters [1], supported Donald Trump [2] and created clothing that could lead to burn injuries [3].",
            "sources": {
                "1": "http://www.businessinsider.com/under-armour-drops-sarah-bowmar-after-bear-hunt-video-2016-8",
                "2": "http://mashable.com/2017/02/07/under-armour-ceo-praises-trump/",
                "3": "https://web.archive.org/web/20130512185359/https://www.fs.fed.us/t-d/pubs/pdfpubs/pdf08512348/pdf08512348dpi72.pdf"
            },
            "tags": [
                "clothing"
            ],
            "score": 42,
            "ownedBy": [],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/4/44/Under_armour_logo.svg",
            "siteUrl": "http://underarmour.com/",
            "updatedAt": "2024-10-03T06:49:27.232Z"
        },
        "urban-outfitters": {
            "names": [
                "Urban Outfitters",
                "Urban Outfitters, Inc.",
                "URBN",
                "Free People"
            ],
            "comment": "Urban Outfitters is an American clothing company that has sold board games and clothing described as \"racist\" [1][2], fired workers who attempted to unionise [3], harbored racist attitudes towards staff [4], stolen designs from independent artists [5], discontinued items supporting same-sex marriage [6], and racially profiled customers [7].",
            "sources": {
                "1": "https://www.thedp.com/article/2003/10/controversy_continues_over_racist_board_game",
                "2": "https://web.archive.org/web/20041114092914/https://www.adl.org/PresRele/DiRaB_41/4438_41.htm",
                "3": "https://www.aftonbladet.se/nyheter/a/oRMrPV/sparkas-efter-krav-pa-kollektivavtal",
                "4": "https://www.phillymag.com/news/2019/04/12/urban-outfitters-lawsuit/",
                "5": "https://www.tumblr.com/imakeshinythings/5855716317/not-cool-urban-outfitters-not-cool",
                "6": "https://www.thecut.com/2008/12/urban_outfitters_yanks_same-se.html",
                "7": "https://edition.cnn.com/2020/06/26/business/urban-outfitters-racial-profiling-trnd/index.html"
            },
            "tags": [
                "clothing",
                "fastfashion"
            ],
            "score": 35,
            "ownedBy": [],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/a/a7/Urban_Outfitters_logo.svg",
            "siteUrl": "http://urbn.com/",
            "updatedAt": "2024-10-05T09:10:52.335Z"
        },
        "anthropologie": {
            "names": [
                "Anthropologie"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "clothing"
            ],
            "score": 35,
            "ownedBy": [
                "urban-outfitters"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/3/37/Anthropologie_Logo.svg",
            "siteUrl": "http://anthropologie.com/",
            "updatedAt": "2024-10-03T06:49:27.232Z"
        },
        "millions": {
            "names": [
                "Millions"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "confectionary"
            ],
            "score": 50,
            "ownedBy": [
                "golden-casket"
            ],
            "logoUrl": "https://goldencasket.co.uk/wp-content/uploads/2024/06/1_k3pGXtMillions-Brand-Logo.png",
            "siteUrl": "https://goldencasket.co.uk/millions/",
            "updatedAt": "2024-10-06T12:30:23.573Z"
        },
        "bose": {
            "names": [
                "Bose",
                "Bose Corporation"
            ],
            "comment": "Bose is an American electronics and audio company that has spied on users [1][2]. Amar Bose donated non-voting shares to MIT, which is now the majority owner.",
            "sources": {
                "1": "https://www.csoonline.com/article/561275/bose-accused-of-spying-on-users-illegal-wiretapping-via-bose-connect-app.html",
                "2": "https://www.foxbusiness.com/features/bose-headphones-spy-on-listeners-lawsuit"
            },
            "tags": [
                "electronics",
                "antiprivacy"
            ],
            "score": 47,
            "ownedBy": [
                "mit"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/0/0c/Bose_logo.svg",
            "siteUrl": "http://www.bose.com/",
            "updatedAt": "2024-10-05T21:27:26.200Z"
        },
        "guess": {
            "names": [
                "Guess",
                "GES",
                "Guess Inc.",
                "GUESS",
                "Guess?"
            ],
            "comment": "Guess is an American clothing company with connections to forced Uyghur Labour [1] and sweatshop manufacturing conditions [2], while Guess's cofounder engaged in sexual misconduct [3].",
            "sources": {
                "1": "https://www.shu.ac.uk/-/media/home/research/helena-kennedy-centre/projects/eu-apparel/eu-tailoring-responsibility-february-24.pdf",
                "2": "https://southendpress.org/what-companies-use-sweatshops/",
                "3": "https://fortune.com/2023/09/29/guess-settlement-marciano-sexual-misconduct-lawsuit/"
            },
            "tags": [
                "clothing"
            ],
            "score": 40,
            "ownedBy": [],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/8/89/Guess_logo.svg",
            "siteUrl": "http://www.guess.com/",
            "updatedAt": "2024-10-05T10:39:46.568Z"
        },
        "nintendo": {
            "names": [
                "Nintendo",
                "Nintendo Co., Ltd.",
                "Nintendo Co.",
                "Nintendō kabushiki gaisha",
                "任天堂株式会社",
                "Nintendo Koppai"
            ],
            "comment": "Nintendonis a Japanese video games company which has seen sexual misconduct and sexism against female employees [1][2], engaged in price fixing [3][4].",
            "sources": {
                "1": "https://www.engadget.com/nintendo-investigates-sexual-misconduct-claims-194740757.html?guccounter=1",
                "2": "https://arstechnica.com/gaming/2022/08/sweeping-report-alleges-inequity-sexual-harassment-at-nintendos-american-hq/",
                "3": "https://www.gamesindustry.biz/nintendos-price-fixing-fine-reduced-by-gbp27m",
                "4": "http://news.bbc.co.uk/2/hi/business/2375967.stm"
            },
            "tags": [
                "videogames",
                "anticomptetive",
                "sexualharassment"
            ],
            "score": 43,
            "ownedBy": [],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/0/0d/Nintendo.svg",
            "siteUrl": "http://nintendo.com/",
            "updatedAt": "2024-10-05T09:10:52.335Z"
        },
        "mattel": {
            "names": [
                "Mattel",
                "Mattel, Inc.",
                "MAT"
            ],
            "comment": "Mattel is an American toy corporation that sold 19 millon toys containing dangerous levels of lead [1], subjected factory workers to inhumane condtions [2][3], and sold baby rockers that killed almost 100 babies despite warnings from experts to discontinue the product [4].",
            "sources": {
                "1": "https://www.nytimes.com/2007/08/15/business/worldbusiness/15imports.html",
                "2": "https://chinalaborwatch.org/deterioration-of-mattels-labor-conditions-an-investigation-of-four-factories/",
                "3": "https://www.google.com/url?sa=t&source=web&cd=&ved=2ahUKEwiEl5317quJAxXJU0EAHZYJHywQFnoECBgQBQ&url=https%3A%2F%2Fchinalaborwatch.org%2Fmattels-unceasing-abuse-of-chinese-workers-an-investigation-of-six-mattel-supplier-factories%2F&usg=AOvVaw0I6P_Dtel3pAqjsZccu7VL&opi=89978449",
                "4": "https://abcnews.go.com/US/fisher-price-warnings-rock-play-sleepers-led-dozens/story?id=78128719"
            },
            "tags": [
                "toys"
            ],
            "score": 10,
            "ownedBy": [],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/7/7b/Mattel_%282019%29.svg",
            "siteUrl": "http://mattel.com/",
            "updatedAt": "2024-10-26T10:34:12.014Z"
        },
        "barbie": {
            "names": [
                "Barbie"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "toys"
            ],
            "score": 10,
            "ownedBy": [
                "mattel"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/0/0b/Barbie_Logo.svg",
            "siteUrl": "https://shop.mattel.com/pages/barbie",
            "updatedAt": "2024-10-26T10:34:12.014Z"
        },
        "hot-wheels": {
            "names": [
                "Hot Wheels"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "toys"
            ],
            "score": 10,
            "ownedBy": [
                "mattel"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/en/0/08/Hot_Wheels_logo-en.svg",
            "siteUrl": "https://hotwheels.com/",
            "updatedAt": "2024-10-26T10:34:12.014Z"
        },
        "fisher-price": {
            "names": [
                "Fisher-Price"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "toys"
            ],
            "score": 10,
            "ownedBy": [
                "mattel"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/7/7e/Fisher-Price_2019.svg",
            "siteUrl": "http://fisher-price.com/",
            "updatedAt": "2024-10-26T10:34:12.014Z"
        },
        "kellogg's": {
            "names": [
                "Kellogg's",
                "Kellanova",
                "The Kellogg Company",
                "K"
            ],
            "comment": "Kellogg's is an American food company that has made false claims about the health impacts of its foods [1][2], sourced palm oil produced by child labour and forced labour [3][4][5], and fired over 1000 workers who went on strike [6].",
            "sources": {
                "1": "https://web.archive.org/web/20100605074858/http://ftc.gov/opa/2010/06/kellogg.shtm",
                "2": "https://web.archive.org/web/20100605083122/http://www.nytimes.com/2010/06/04/business/04ftc.html",
                "3": "https://web.archive.org/web/20180423210624/https://www.amnesty.org/en/documents/asa21/5184/2016/en/",
                "4": "https://web.archive.org/web/20161203061058/http://www.ibtimes.co.uk/amnesty-report-slams-popular-brands-profiting-labour-abuses-wilmar-1594102",
                "5": "https://web.archive.org/web/20161202053632/https://www.theguardian.com/law/2016/nov/30/kelloggs-unilever-nestle-child-labour-palm-oil-wilmar-amnesty",
                "6": "https://web.archive.org/web/20211209051128/https://www.businessinsider.com/kellogg-replacing-1400-striking-workers-reject-deal-wage-increase-2021-12"
            },
            "tags": [
                "food"
            ],
            "score": 17,
            "ownedBy": [],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/0/0a/Kellogg%27s-Logo.svg",
            "siteUrl": "http://kellanova.com/",
            "updatedAt": "2024-12-26T15:02:40.507Z"
        },
        "pringles": {
            "names": [
                "Pringles",
                "Pringle's Newfangled Potato Chips"
            ],
            "comment": "",
            "sources": {},
            "tags": [
                "food"
            ],
            "score": 17,
            "ownedBy": [
                "kellogg's"
            ],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/en/f/f6/Pringles_2021.svg",
            "siteUrl": "https://www.pringles.com/us/home.html",
            "updatedAt": "2024-12-26T15:13:46.817Z"
        },
        "heineken": {
            "names": [
                "Heineken",
                "Heineken N.V.",
                "HEIA"
            ],
            "comment": "Heineken is a Dutch beverage company that broke its promise to withdraw from Russia [1], engaged in price fixing [2], and engaged in high-level corruption, suppression of local brewers, and collaborations with authoritarian regimes in Africa [3]. Heineken has also interfered with alcohol policies in the US and EU [4].",
            "sources": {
                "1": "https://nltimes.nl/2023/02/21/heineken-still-investing-russia-despite-promises-stop",
                "2": "http://business.guardian.co.uk/story/0,,2059542,00.html",
                "3": "https://www.premiumtimesng.com/news/top-news/756714-at-book-reading-dutch-journalist-recounts-expose-on-heinekens-practices-in-africa.html?tztc=1",
                "4": "https://movendi.ngo/the-issues/the-problem/big-alcohol-exposed/heineken-company-profile/"
            },
            "tags": [
                "alcohol",
                "anticompetetive"
            ],
            "score": 15,
            "ownedBy": [],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/2/24/Heineken_logo.svg",
            "siteUrl": "http://www.heinekeninternational.com/",
            "updatedAt": "2025-01-05T09:56:01.496Z"
        },
        "bbc": {
            "names": [
                "BBC",
                "British Broadcasting Corporation",
                "BBC News"
            ],
            "comment": "The BBC is a British public broadcaster funded by a government tax [1], which has been accused of left-wing [2] and right-wing [3] bias. The BBC represents one of the largest news gathering operations in the world [4] and offers free educational resources to teachers and children [5][6], but particular criticism has been directed to its treatment of LGBT+ communities [7][8], the Isreal-Palestine conflict [9], and racism [10].",
            "sources": {
                "1": "https://web.archive.org/web/20071117105208/http://www.telegraph.co.uk/arts/main.jhtml?xml=%2Farts%2F2006%2F09%2F22%2Fftlicence22.xml",
                "2": "https://web.archive.org/web/20170807023223/https://www.theguardian.com/media/2012/oct/10/bbc-review-liberal-bias",
                "3": "https://web.archive.org/web/20181209123856/https://www.theguardian.com/commentisfree/2018/apr/11/bbc-andrew-neil-media-politics",
                "4": "https://www.bbc.co.uk/news/19888761",
                "5": "https://archive.is/IbwWv",
                "6": "https://www.bbc.co.uk/bitesize/articles/zmjdhbk",
                "7": "https://www.pinknews.co.uk/2020/06/22/bbc-news-institutional-transphobia-kamal-ahmed-crispin-blunt-trans-media-watch/",
                "8": "https://mermaidsuk.org.uk/news/lgbtq-complaint-to-the-bbc/",
                "9": "https://web.archive.org/web/20231124032059/https://www.aljazeera.com/news/2023/11/23/as-israel-pounds-gaza-bbc-journalists-accuse-broadcaster-of-bias",
                "10": "https://ghostarchive.org/archive/20220112/https://www.telegraph.co.uk/news/2020/07/31/bbc-receives-complaints-white-reporter-uses-n-word-air/"
            },
            "tags": [
                "news",
                "media",
                "tv",
                "radio"
            ],
            "score": 75,
            "ownedBy": [],
            "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/4/41/BBC_Logo_2021.svg",
            "siteUrl": "https://bbc.com/",
            "updatedAt": "2024-01-01T10:47:27.404Z"
        }
    }
}
